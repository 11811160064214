/* tslint:disable */
/* eslint-disable */
/**
 * BCFLEX API
 * BCFLEX Public API Documentation
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AffiliateDataResponse
 */
export interface AffiliateDataResponse {
    /**
     * 
     * @type {string}
     * @memberof AffiliateDataResponse
     */
    'refId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AffiliateDataResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGetDepositsWitdrawals
 */
export interface ApiGetDepositsWitdrawals {
    /**
     * 
     * @type {string}
     * @memberof ApiGetDepositsWitdrawals
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGetDepositsWitdrawals
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGetDepositsWitdrawals
     */
    'blockExURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGetDepositsWitdrawals
     */
    'txId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGetDepositsWitdrawals
     */
    'dateTime'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGetDepositsWitdrawals
     */
    'pending'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiGetOrders
 */
export interface ApiGetOrders {
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiGetOrders
     */
    'pairName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'created'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'dateCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'amountLeft'?: number;
    /**
     * 
     * @type {TradeType}
     * @memberof ApiGetOrders
     */
    'tradeType'?: TradeType;
    /**
     * 
     * @type {OrderTypes}
     * @memberof ApiGetOrders
     */
    'orderType'?: OrderTypes;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGetOrders
     */
    'isMargin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'liquidationPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'initialPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'leverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'initialAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'stopLossPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApiGetOrders
     */
    'takeProfitPrice'?: number | null;
}


/**
 * 
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    'key'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiKey
     */
    'isTradeAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiKey
     */
    'isMarginTradeAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiKey
     */
    'isWithdrawAllowed'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiMarket
 */
export interface ApiMarket {
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    'symbol'?: string | null;
    /**
     * 
     * @type {Array<ApiPair>}
     * @memberof ApiMarket
     */
    'pairs'?: Array<ApiPair> | null;
}
/**
 * 
 * @export
 * @interface ApiOpenOffer
 */
export interface ApiOpenOffer {
    /**
     * 
     * @type {string}
     * @memberof ApiOpenOffer
     */
    'id'?: string | null;
    /**
     * 
     * @type {TradeDirection}
     * @memberof ApiOpenOffer
     */
    'direction'?: TradeDirection;
    /**
     * 
     * @type {OfferStatus}
     * @memberof ApiOpenOffer
     */
    'status'?: OfferStatus;
    /**
     * 
     * @type {string}
     * @memberof ApiOpenOffer
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiOpenOffer
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiOpenOffer
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiOpenOffer
     */
    'max'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiOpenOffer
     */
    'fiatCurrencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiOpenOffer
     */
    'cryptoCurrencyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiOpenOffer
     */
    'username'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiOpenOffer
     */
    'isTrust'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiOpenOffer
     */
    'tradeCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiOpenOffer
     */
    'trustMessage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiOpenOffer
     */
    'medianReplyTimeMinutes'?: number;
}


/**
 * 
 * @export
 * @interface ApiP2PInfo
 */
export interface ApiP2PInfo {
    /**
     * 
     * @type {string}
     * @memberof ApiP2PInfo
     */
    'username'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiP2PInfo
     */
    'tradeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiP2PInfo
     */
    'completeTradeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiP2PInfo
     */
    'rejectedTradeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiP2PInfo
     */
    'medianReplyTime'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiP2PInfo
     */
    'isTrust'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiP2PInfo
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiP2PInfo
     */
    'telegramUsername'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiP2PInfo
     */
    'telegramVerificationCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiPair
 */
export interface ApiPair {
    /**
     * 
     * @type {string}
     * @memberof ApiPair
     */
    'pair'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiPair
     */
    'symbol'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiPair
     */
    'leverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPair
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface ApiPaymentPage
 */
export interface ApiPaymentPage {
    /**
     * 
     * @type {CryptoCurrency}
     * @memberof ApiPaymentPage
     */
    'currency'?: CryptoCurrency;
    /**
     * 
     * @type {MerchantTransaction}
     * @memberof ApiPaymentPage
     */
    'transaction'?: MerchantTransaction;
    /**
     * 
     * @type {DepositAddress}
     * @memberof ApiPaymentPage
     */
    'depositAddress'?: DepositAddress;
    /**
     * 
     * @type {string}
     * @memberof ApiPaymentPage
     */
    'faultUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiPaymentPage
     */
    'successUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiPaymentPage
     */
    'merchantName'?: string | null;
    /**
     * 
     * @type {BigInteger}
     * @memberof ApiPaymentPage
     */
    'amountBigInt'?: BigInteger;
    /**
     * 
     * @type {number}
     * @memberof ApiPaymentPage
     */
    'amountUsd'?: number;
}
/**
 * 
 * @export
 * @interface ApiTickers
 */
export interface ApiTickers {
    /**
     * 
     * @type {string}
     * @memberof ApiTickers
     */
    'pair'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiTickers
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiTickers
     */
    'volume'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiTickers
     */
    'pairVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiTickers
     */
    'high'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiTickers
     */
    'low'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiTickers
     */
    'change'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiTickers
     */
    'usdPrice'?: number;
}
/**
 * 
 * @export
 * @interface ApiTransactionStatusResponse
 */
export interface ApiTransactionStatusResponse {
    /**
     * 
     * @type {MerchantTransaction}
     * @memberof ApiTransactionStatusResponse
     */
    'transaction'?: MerchantTransaction;
}
/**
 * 
 * @export
 * @interface ApplicationUser
 */
export interface ApplicationUser {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'normalizedUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'normalizedEmail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'passwordHash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'securityStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'concurrencyStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    'phoneNumberConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    'twoFactorEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'lockoutEnd'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    'lockoutEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    'accessFailedCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'pinCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'lastIPAccess'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    'lastDateAccess'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    'isChatDisabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    'registrationDateTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    'verificationLevel'?: number | null;
    /**
     * 
     * @type {TwoStepVerificationTypes}
     * @memberof ApplicationUser
     */
    'twoStepVerificationType'?: TwoStepVerificationTypes;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'twoStepBackupCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'kycSefileBlobGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'kycPassportBlobGuid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    'isClubAccount'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    'withdrawLockedTill'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'refId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    'telegramChatId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'telegramUsername'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'telegramVerificationCodeFirst'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'telegramVerificationCodeSecond'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    'isTelegramVerified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'frontSideImageGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUser
     */
    'backSideImageGuid'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUser
     */
    'isKYCPassed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUser
     */
    'sessionLifeTime'?: number;
}


/**
 * 
 * @export
 * @interface AskForPermissionRequestModel
 */
export interface AskForPermissionRequestModel {
    /**
     * 
     * @type {DevicePermissionRequestType}
     * @memberof AskForPermissionRequestModel
     */
    'type'?: DevicePermissionRequestType;
    /**
     * 
     * @type {string}
     * @memberof AskForPermissionRequestModel
     */
    'bitflexDeviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AskForPermissionRequestModel
     */
    'terminalSignalRConnectionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AskForPermissionRequestModel
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AskForPermissionRequestModel
     */
    'ipAddress'?: string | null;
    /**
     * 
     * @type {ClientInfo}
     * @memberof AskForPermissionRequestModel
     */
    'clientInfo'?: ClientInfo;
    /**
     * 
     * @type {number}
     * @memberof AskForPermissionRequestModel
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof AskForPermissionRequestModel
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof AskForPermissionRequestModel
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AskForPermissionRequestModel
     */
    'deviceDescription'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AskPermissionSignInError = {
    UserIdDecryptionFalure: 'UserIdDecryptionFalure',
    NoSuchUserId: 'NoSuchUserId',
    NoDeviceForTwoStep: 'NoDeviceForTwoStep'
} as const;

export type AskPermissionSignInError = typeof AskPermissionSignInError[keyof typeof AskPermissionSignInError];


/**
 * 
 * @export
 * @interface AskPermissionSignInResponse
 */
export interface AskPermissionSignInResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AskPermissionSignInResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {AskPermissionSignInError}
     * @memberof AskPermissionSignInResponse
     */
    'error'?: AskPermissionSignInError;
}


/**
 * 
 * @export
 * @interface AssetsResponseModel
 */
export interface AssetsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof AssetsResponseModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetsResponseModel
     */
    'symbol'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AssetsResponseModel
     */
    'cmcid'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AssetsResponseModel
     */
    'canWithdraw'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AssetsResponseModel
     */
    'canDeposit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AssetsResponseModel
     */
    'minWithdraw'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetsResponseModel
     */
    'maxWithdraw'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetsResponseModel
     */
    'withdrawFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetsResponseModel
     */
    'takerFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssetsResponseModel
     */
    'makerFee'?: number;
}
/**
 * 
 * @export
 * @interface Balance
 */
export interface Balance {
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    'currencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Balance
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Balance
     */
    'inserted'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Balance
     */
    'lastUpdated'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BalanceAction = {
    Deposit: 'Deposit',
    Withdraw: 'Withdraw'
} as const;

export type BalanceAction = typeof BalanceAction[keyof typeof BalanceAction];


/**
 * 
 * @export
 * @interface BalanceActionModel
 */
export interface BalanceActionModel {
    /**
     * 
     * @type {BalanceAction}
     * @memberof BalanceActionModel
     */
    'action'?: BalanceAction;
    /**
     * 
     * @type {number}
     * @memberof BalanceActionModel
     */
    'currencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BalanceActionModel
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof BalanceActionModel
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface BalanceErrorCode
 */
export interface BalanceErrorCode {
    /**
     * 
     * @type {boolean}
     * @memberof BalanceErrorCode
     */
    'success'?: boolean;
    /**
     * 
     * @type {Code}
     * @memberof BalanceErrorCode
     */
    'errorCode'?: Code;
    /**
     * 
     * @type {string}
     * @memberof BalanceErrorCode
     */
    'errorMessage'?: string | null;
}


/**
 * 
 * @export
 * @interface BigInteger
 */
export interface BigInteger {
    /**
     * 
     * @type {boolean}
     * @memberof BigInteger
     */
    'isPowerOfTwo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BigInteger
     */
    'isZero'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BigInteger
     */
    'isOne'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BigInteger
     */
    'isEven'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BigInteger
     */
    'sign'?: number;
}
/**
 * 
 * @export
 * @interface BitflexApiResponseBase
 */
export interface BitflexApiResponseBase {
    /**
     * 
     * @type {boolean}
     * @memberof BitflexApiResponseBase
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface CashbackSignUpRequest
 */
export interface CashbackSignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof CashbackSignUpRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CashbackSignUpRequest
     */
    'merchantTransactionId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface Check2StepVerificationStatusResponse
 */
export interface Check2StepVerificationStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof Check2StepVerificationStatusResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Check2StepVerificationStatusResponse
     */
    'isThisDeviceLegit'?: boolean;
    /**
     * 
     * @type {TwoStepVerificationTypes}
     * @memberof Check2StepVerificationStatusResponse
     */
    'verificationType'?: TwoStepVerificationTypes;
}


/**
 * 
 * @export
 * @interface ClientInfo
 */
export interface ClientInfo {
    /**
     * 
     * @type {string}
     * @memberof ClientInfo
     */
    'string'?: string | null;
    /**
     * 
     * @type {OS}
     * @memberof ClientInfo
     */
    'os'?: OS;
    /**
     * 
     * @type {Device}
     * @memberof ClientInfo
     */
    'device'?: Device;
    /**
     * 
     * @type {UserAgent}
     * @memberof ClientInfo
     */
    'userAgent'?: UserAgent;
    /**
     * 
     * @type {UserAgent}
     * @memberof ClientInfo
     */
    'ua'?: UserAgent;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Code = {
    WrongCurrency: 'WrongCurrency',
    CurrencyNotFound: 'CurrencyNotFound',
    Unauthenticated: 'Unauthenticated',
    GenerationError: 'GenerationError',
    CreateError: 'CreateError',
    Locked: 'Locked',
    TwoFactorRequired: 'TwoFactorRequired'
} as const;

export type Code = typeof Code[keyof typeof Code];


/**
 * 
 * @export
 * @interface CoinTokenAddRequest
 */
export interface CoinTokenAddRequest {
    /**
     * 
     * @type {string}
     * @memberof CoinTokenAddRequest
     */
    'cmcUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoinTokenAddRequest
     */
    'contractAddress'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof CoinTokenAddRequest
     */
    'currencyType'?: Type;
    /**
     * 
     * @type {string}
     * @memberof CoinTokenAddRequest
     */
    'explolerUrl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CoinTokenAddRequest
     */
    'withdrawFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoinTokenAddRequest
     */
    'withdrawFeePercent'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoinTokenAddRequest
     */
    'confirmationCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CoinTokenAddRequest
     */
    'decimals'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CoinTokenAddRequest
     */
    'rpcIP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoinTokenAddRequest
     */
    'rpcPort'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoinTokenAddRequest
     */
    'rpcUsername'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CoinTokenAddRequest
     */
    'rpcPassword'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CreateListingError = {
    ReCaptchav3Failed: 'ReCaptchav3Failed'
} as const;

export type CreateListingError = typeof CreateListingError[keyof typeof CreateListingError];


/**
 * 
 * @export
 * @interface CreateListingRequestResponse
 */
export interface CreateListingRequestResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CreateListingRequestResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {CreateListingError}
     * @memberof CreateListingRequestResponse
     */
    'error'?: CreateListingError;
}


/**
 * 
 * @export
 * @interface CreateMerchantRequest
 */
export interface CreateMerchantRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantRequest
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantRequest
     */
    'successUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantRequest
     */
    'faultUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateRequestPayload
 */
export interface CreateRequestPayload {
    /**
     * 
     * @type {GuardSignInRequestModel}
     * @memberof CreateRequestPayload
     */
    'signInRequestModel'?: GuardSignInRequestModel;
    /**
     * 
     * @type {GuardWithdrawRequestModel}
     * @memberof CreateRequestPayload
     */
    'withdrawRequestModel'?: GuardWithdrawRequestModel;
    /**
     * 
     * @type {ChangePasswordRequest}
     * @memberof CreateRequestPayload
     */
    'changePasswordRequestModel'?: ChangePasswordRequest;
}
/**
 * 
 * @export
 * @interface CreateSupportTicketModel
 */
export interface CreateSupportTicketModel {
    /**
     * 
     * @type {string}
     * @memberof CreateSupportTicketModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSupportTicketModel
     */
    'telegram'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSupportTicketModel
     */
    'shortText': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupportTicketModel
     */
    'longText': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CreateTransactionFailCode = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type CreateTransactionFailCode = typeof CreateTransactionFailCode[keyof typeof CreateTransactionFailCode];


/**
 * 
 * @export
 * @interface CreateTransactionRequest
 */
export interface CreateTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'merchantId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'currencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'networkCurrencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionRequest
     */
    'orderId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateTransactionResult
 */
export interface CreateTransactionResult {
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionResult
     */
    'paymentUrl'?: string | null;
    /**
     * 
     * @type {CreateTransactionFailCode}
     * @memberof CreateTransactionResult
     */
    'failCode'?: CreateTransactionFailCode;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionResult
     */
    'failMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTransactionResult
     */
    'address'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateTransactionResult
     */
    'amount'?: number;
}


/**
 * 
 * @export
 * @interface CreateWithdrawRequest
 */
export interface CreateWithdrawRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawRequest
     */
    'merchantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawRequest
     */
    'destinationAddress'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateWithdrawRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateWithdrawRequest
     */
    'currencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateWithdrawRequest
     */
    'networkCurrencyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawRequest
     */
    'checksum'?: string | null;
}
/**
 * 
 * @export
 * @interface CryptoCurrency
 */
export interface CryptoCurrency {
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CryptoCurrency
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CryptoCurrency
     */
    'shortName'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof CryptoCurrency
     */
    'currencyType'?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof CryptoCurrency
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CryptoCurrency
     */
    'depositDisabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CryptoCurrency
     */
    'withdrawDisabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'coinMarketCapId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'coinMarketCapUsdPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'coinMarketCapLastUpdate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'coinMarketCapMaxSupply'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'coinMarketCapTotalSupply'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'coinMarketCapCurculatingSupply'?: number;
    /**
     * 
     * @type {string}
     * @memberof CryptoCurrency
     */
    'coinMarketCapDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CryptoCurrency
     */
    'coinMarketCapIssueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CryptoCurrency
     */
    'coinMarketCapExplolerUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CryptoCurrency
     */
    'image'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CryptoCurrency
     */
    'isQuoteCurrency'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CryptoCurrency
     */
    'blockExplolerTxIdUrl'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'withdrawCurrencyFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'confirmationCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'minimumTradeAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'minWithdraw'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'maxWithdraw'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'withdrawableAdminFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CryptoCurrency
     */
    'minimumDeposit'?: number;
}


/**
 * 
 * @export
 * @interface CurrencyResponse
 */
export interface CurrencyResponse {
    /**
     * 
     * @type {number}
     * @memberof CurrencyResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrencyResponse
     */
    'symbol'?: string | null;
}
/**
 * 
 * @export
 * @interface CurrencyStatus
 */
export interface CurrencyStatus {
    /**
     * 
     * @type {string}
     * @memberof CurrencyStatus
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrencyStatus
     */
    'image'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyStatus
     */
    'withdrawDisabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrencyStatus
     */
    'depositDisabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CurrencyStatus
     */
    'lastBlock'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyStatus
     */
    'lastLocalBlock'?: number;
    /**
     * 
     * @type {number}
     * @memberof CurrencyStatus
     */
    'hotWalletBalance'?: number;
}
/**
 * 
 * @export
 * @interface DepositAddress
 */
export interface DepositAddress {
    /**
     * 
     * @type {string}
     * @memberof DepositAddress
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DepositAddress
     */
    'depositTag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DepositAddress
     */
    'networkCurrencyId'?: number;
}
/**
 * 
 * @export
 * @interface DepositAddressNetworkCurrencyResponse
 */
export interface DepositAddressNetworkCurrencyResponse {
    /**
     * 
     * @type {string}
     * @memberof DepositAddressNetworkCurrencyResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DepositAddressNetworkCurrencyResponse
     */
    'imageBase64'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DepositAddressNetworkCurrencyResponse
     */
    'networkCurrencyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DepositAddressNetworkCurrencyResponse
     */
    'adreess'?: string | null;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    'isSpider'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'brand'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'family'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'model'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DevicePermissionRequestType = {
    EnableTwoFactor: 'EnableTwoFactor',
    CancelEnableTwoFactor: 'CancelEnableTwoFactor',
    DisableTwoFactor: 'DisableTwoFactor',
    ConfirmLogin: 'ConfirmLogin',
    ConfirmWithdraw: 'ConfirmWithdraw',
    CheckIsOnline: 'CheckIsOnline'
} as const;

export type DevicePermissionRequestType = typeof DevicePermissionRequestType[keyof typeof DevicePermissionRequestType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DeviceType = {
    IOs: 'iOS',
    Android: 'Android',
    Chrome: 'Chrome',
    Safari: 'Safari'
} as const;

export type DeviceType = typeof DeviceType[keyof typeof DeviceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const EmailConfirmationErrorCode = {
    Success: 'Success',
    UserOrCodeEmpty: 'UserOrCodeEmpty',
    UserNotFound: 'UserNotFound',
    WrongCode: 'WrongCode',
    AlreadyConfirmed: 'AlreadyConfirmed'
} as const;

export type EmailConfirmationErrorCode = typeof EmailConfirmationErrorCode[keyof typeof EmailConfirmationErrorCode];


/**
 * 
 * @export
 * @interface EmailConfirmationResult
 */
export interface EmailConfirmationResult {
    /**
     * 
     * @type {boolean}
     * @memberof EmailConfirmationResult
     */
    'result'?: boolean;
    /**
     * 
     * @type {EmailConfirmationErrorCode}
     * @memberof EmailConfirmationResult
     */
    'errorCode'?: EmailConfirmationErrorCode;
    /**
     * 
     * @type {string}
     * @memberof EmailConfirmationResult
     */
    'errorText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmailConfirmationResult
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmailConfirmationResult
     */
    'isPasswordSetupRequired'?: boolean;
}


/**
 * 
 * @export
 * @interface EnableDisable2Fa
 */
export interface EnableDisable2Fa {
    /**
     * 
     * @type {string}
     * @memberof EnableDisable2Fa
     */
    'clientCode': string;
    /**
     * 
     * @type {string}
     * @memberof EnableDisable2Fa
     */
    'pincode': string;
    /**
     * 
     * @type {string}
     * @memberof EnableDisable2Fa
     */
    'backupCode': string;
}
/**
 * 
 * @export
 * @interface ErrorModel
 */
export interface ErrorModel {
    /**
     * 
     * @type {boolean}
     * @memberof ErrorModel
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ErrorModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof ErrorModel
     */
    'data'?: any | null;
}
/**
 * 
 * @export
 * @interface FeeStructure
 */
export interface FeeStructure {
    /**
     * 
     * @type {number}
     * @memberof FeeStructure
     */
    'makerFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeStructure
     */
    'takerFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeStructure
     */
    'btcTurnover'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeStructure
     */
    'flexTokenBalance'?: number;
}
/**
 * 
 * @export
 * @interface FinishTier1VerificationModel
 */
export interface FinishTier1VerificationModel {
    /**
     * 
     * @type {string}
     * @memberof FinishTier1VerificationModel
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof FinishTier1VerificationModel
     */
    'fullName': string;
}
/**
 * 
 * @export
 * @interface GenerateAddressResult
 */
export interface GenerateAddressResult {
    /**
     * 
     * @type {boolean}
     * @memberof GenerateAddressResult
     */
    'result'?: boolean;
    /**
     * 
     * @type {GetAddressResponse}
     * @memberof GenerateAddressResult
     */
    'depositParam'?: GetAddressResponse;
    /**
     * 
     * @type {BalanceErrorCode}
     * @memberof GenerateAddressResult
     */
    'errorCode'?: BalanceErrorCode;
}
/**
 * 
 * @export
 * @interface GenerateApiKeyRequest
 */
export interface GenerateApiKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateApiKeyRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateApiKeyRequest
     */
    'isTradeAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateApiKeyRequest
     */
    'isMarginTradeAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateApiKeyRequest
     */
    'isWithdrawAllowed'?: boolean;
}
/**
 * 
 * @export
 * @interface GetAccessTokensResponse
 */
export interface GetAccessTokensResponse {
    /**
     * 
     * @type {string}
     * @memberof GetAccessTokensResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAccessTokensResponse
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAccessTokensResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccessTokensResponse
     */
    'isThisDevice'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAccessTokensResponse
     */
    'terminateReason'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccessTokensResponse
     */
    'isOnline'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAccessTokensResponse
     */
    'lastIP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccessTokensResponse
     */
    'lastActive'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetAccessTokensResponse
     */
    'isGuardDevice'?: boolean;
}
/**
 * 
 * @export
 * @interface GetAddressResponse
 */
export interface GetAddressResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAddressResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    'address'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAddressResponse
     */
    'isAddressGenerated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    'fullname'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAddressResponse
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAddressResponse
     */
    'availableAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAddressResponse
     */
    'confirmationCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetAddressResponse
     */
    'destinationTag'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof GetAddressResponse
     */
    'currencyType'?: Type;
    /**
     * 
     * @type {boolean}
     * @memberof GetAddressResponse
     */
    'isFiat'?: boolean;
    /**
     * 
     * @type {BalanceErrorCode}
     * @memberof GetAddressResponse
     */
    'errorCode'?: BalanceErrorCode;
    /**
     * 
     * @type {number}
     * @memberof GetAddressResponse
     */
    'minimumDeposit'?: number;
    /**
     * 
     * @type {Array<DepositAddressNetworkCurrencyResponse>}
     * @memberof GetAddressResponse
     */
    'networkCurrencies'?: Array<DepositAddressNetworkCurrencyResponse> | null;
}


/**
 * 
 * @export
 * @interface GetApiMarketsCurrenciesResponse
 */
export interface GetApiMarketsCurrenciesResponse {
    /**
     * 
     * @type {number}
     * @memberof GetApiMarketsCurrenciesResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetApiMarketsCurrenciesResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetApiMarketsCurrenciesResponse
     */
    'symbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetApiMarketsCurrenciesResponse
     */
    'imageBase64'?: string | null;
    /**
     * 
     * @type {Type}
     * @memberof GetApiMarketsCurrenciesResponse
     */
    'type'?: Type;
    /**
     * 
     * @type {string}
     * @memberof GetApiMarketsCurrenciesResponse
     */
    'blockExplorerUrl'?: string | null;
}


/**
 * 
 * @export
 * @interface GetBalanceHoldAndLimits
 */
export interface GetBalanceHoldAndLimits {
    /**
     * 
     * @type {number}
     * @memberof GetBalanceHoldAndLimits
     */
    'usdHoldings'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBalanceHoldAndLimits
     */
    'btcHoldings'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBalanceHoldAndLimits
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBalanceHoldAndLimits
     */
    'usedLimit'?: number;
}
/**
 * 
 * @export
 * @interface GetBalanceRequestModel
 */
export interface GetBalanceRequestModel {
    /**
     * 
     * @type {string}
     * @memberof GetBalanceRequestModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetBalanceRequestModel
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBalanceRequestModel
     */
    'onOrders'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetBalanceRequestModel
     */
    'available'?: number;
    /**
     * 
     * @type {BalanceErrorCode}
     * @memberof GetBalanceRequestModel
     */
    'errorCode'?: BalanceErrorCode;
}
/**
 * 
 * @export
 * @interface GetBalancesListResponse
 */
export interface GetBalancesListResponse {
    /**
     * 
     * @type {Array<GetBalanceRequestModel>}
     * @memberof GetBalancesListResponse
     */
    'balances'?: Array<GetBalanceRequestModel> | null;
    /**
     * 
     * @type {GetBalanceHoldAndLimits}
     * @memberof GetBalancesListResponse
     */
    'stats'?: GetBalanceHoldAndLimits;
}
/**
 * 
 * @export
 * @interface GetGuardRequestDataResponse
 */
export interface GetGuardRequestDataResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetGuardRequestDataResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {GetGuardRequestDataResponseErrorType}
     * @memberof GetGuardRequestDataResponse
     */
    'errorType'?: GetGuardRequestDataResponseErrorType;
    /**
     * 
     * @type {GuardRequest}
     * @memberof GetGuardRequestDataResponse
     */
    'guardRequest'?: GuardRequest;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const GetGuardRequestDataResponseErrorType = {
    DeviceNotAllowed: 'DeviceNotAllowed',
    Expired: 'Expired',
    AlreadyUsed: 'AlreadyUsed',
    NotFound: 'NotFound'
} as const;

export type GetGuardRequestDataResponseErrorType = typeof GetGuardRequestDataResponseErrorType[keyof typeof GetGuardRequestDataResponseErrorType];


/**
 * 
 * @export
 * @interface GetMarketsResponse
 */
export interface GetMarketsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetMarketsResponse
     */
    'result'?: boolean;
    /**
     * 
     * @type {StatusCodeEnum}
     * @memberof GetMarketsResponse
     */
    'code'?: StatusCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetMarketsResponse
     */
    'fingerprint'?: string | null;
    /**
     * 
     * @type {Array<ApiMarket>}
     * @memberof GetMarketsResponse
     */
    'markets'?: Array<ApiMarket> | null;
}


/**
 * 
 * @export
 * @interface GetOrdersResponse
 */
export interface GetOrdersResponse {
    /**
     * 
     * @type {Array<ApiGetOrders>}
     * @memberof GetOrdersResponse
     */
    'openOrders'?: Array<ApiGetOrders> | null;
    /**
     * 
     * @type {Array<ApiGetOrders>}
     * @memberof GetOrdersResponse
     */
    'closedOrders'?: Array<ApiGetOrders> | null;
}
/**
 * 
 * @export
 * @interface GetTickeAsyncResponse
 */
export interface GetTickeAsyncResponse {
    /**
     * 
     * @type {ServerLocation}
     * @memberof GetTickeAsyncResponse
     */
    'location'?: ServerLocation;
    /**
     * 
     * @type {number}
     * @memberof GetTickeAsyncResponse
     */
    'timestamp'?: number;
}


/**
 * 
 * @export
 * @interface GetTickersResponse
 */
export interface GetTickersResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetTickersResponse
     */
    'result'?: boolean;
    /**
     * 
     * @type {StatusCodeEnum}
     * @memberof GetTickersResponse
     */
    'code'?: StatusCodeEnum;
    /**
     * 
     * @type {Array<ApiTickers>}
     * @memberof GetTickersResponse
     */
    'tickers'?: Array<ApiTickers> | null;
}


/**
 * 
 * @export
 * @interface GetTradeHistoryResponse
 */
export interface GetTradeHistoryResponse {
    /**
     * 
     * @type {number}
     * @memberof GetTradeHistoryResponse
     */
    'dateTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTradeHistoryResponse
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTradeHistoryResponse
     */
    'amount'?: number;
    /**
     * 
     * @type {TradeType}
     * @memberof GetTradeHistoryResponse
     */
    'type'?: TradeType;
}


/**
 * 
 * @export
 * @interface GetVerificationLevelResponse
 */
export interface GetVerificationLevelResponse {
    /**
     * 
     * @type {number}
     * @memberof GetVerificationLevelResponse
     */
    'verificationLevel'?: number;
    /**
     * 
     * @type {{ [key: string]: number | null; }}
     * @memberof GetVerificationLevelResponse
     */
    'withdrawLimits'?: { [key: string]: number | null; } | null;
}
/**
 * 
 * @export
 * @interface GetWithdrawResponse
 */
export interface GetWithdrawResponse {
    /**
     * 
     * @type {string}
     * @memberof GetWithdrawResponse
     */
    'longName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetWithdrawResponse
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWithdrawResponse
     */
    'availableAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWithdrawResponse
     */
    'maximumWithdrawAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWithdrawResponse
     */
    'withdrawFeePercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetWithdrawResponse
     */
    'withdrawFee'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetWithdrawResponse
     */
    'requireDestinationTag'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetWithdrawResponse
     */
    'destinationTagName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetWithdrawResponse
     */
    'withdrawDisabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWithdrawResponse
     */
    'usedAddresses'?: Array<string> | null;
    /**
     * 
     * @type {BalanceErrorCode}
     * @memberof GetWithdrawResponse
     */
    'errorCode'?: BalanceErrorCode;
    /**
     * 
     * @type {Array<DepositAddressNetworkCurrencyResponse>}
     * @memberof GetWithdrawResponse
     */
    'networkCurrencies'?: Array<DepositAddressNetworkCurrencyResponse> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GuardActionType = {
    SignIn: 'SignIn',
    Withdraw: 'Withdraw',
    ChangePassword: 'ChangePassword',
    Disable: 'Disable'
} as const;

export type GuardActionType = typeof GuardActionType[keyof typeof GuardActionType];


/**
 * 
 * @export
 * @interface GuardRequest
 */
export interface GuardRequest {
    /**
     * 
     * @type {string}
     * @memberof GuardRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {GuardActionType}
     * @memberof GuardRequest
     */
    'actionType'?: GuardActionType;
    /**
     * 
     * @type {string}
     * @memberof GuardRequest
     */
    'clientInfoJSON'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GuardRequest
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof GuardRequest
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof GuardRequest
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GuardRequest
     */
    'ipAddress'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GuardRequest
     */
    'dateTimeCreated'?: number;
    /**
     * 
     * @type {GuardRequestStatus}
     * @memberof GuardRequest
     */
    'status'?: GuardRequestStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const GuardRequestStatus = {
    Created: 'Created',
    Requested: 'Requested',
    Accepted: 'Accepted',
    Declined: 'Declined'
} as const;

export type GuardRequestStatus = typeof GuardRequestStatus[keyof typeof GuardRequestStatus];


/**
 * 
 * @export
 * @interface GuardSignInRequestModel
 */
export interface GuardSignInRequestModel {
    /**
     * 
     * @type {string}
     * @memberof GuardSignInRequestModel
     */
    'terminalConnectionId': string;
    /**
     * 
     * @type {string}
     * @memberof GuardSignInRequestModel
     */
    'encryptedUserId': string;
}
/**
 * 
 * @export
 * @interface GuardSubmitResult
 */
export interface GuardSubmitResult {
    /**
     * 
     * @type {boolean}
     * @memberof GuardSubmitResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {GuardSubmitResultStatus}
     * @memberof GuardSubmitResult
     */
    'result'?: GuardSubmitResultStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const GuardSubmitResultStatus = {
    NotFound: 'NotFound',
    DeviceNotAllowed: 'DeviceNotAllowed',
    Success: 'Success'
} as const;

export type GuardSubmitResultStatus = typeof GuardSubmitResultStatus[keyof typeof GuardSubmitResultStatus];


/**
 * 
 * @export
 * @interface GuardWithdrawRequestModel
 */
export interface GuardWithdrawRequestModel {
    /**
     * 
     * @type {number}
     * @memberof GuardWithdrawRequestModel
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof GuardWithdrawRequestModel
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof GuardWithdrawRequestModel
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof GuardWithdrawRequestModel
     */
    'destinationTag'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityError
 */
export interface IdentityError {
    /**
     * 
     * @type {string}
     * @memberof IdentityError
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityError
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityResult
 */
export interface IdentityResult {
    /**
     * 
     * @type {boolean}
     * @memberof IdentityResult
     */
    'succeeded'?: boolean;
    /**
     * 
     * @type {Array<IdentityError>}
     * @memberof IdentityResult
     */
    'errors'?: Array<IdentityError> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const KYCRequestState = {
    Applied: 'Applied',
    Verified: 'Verified',
    Rejected: 'Rejected'
} as const;

export type KYCRequestState = typeof KYCRequestState[keyof typeof KYCRequestState];


/**
 * 
 * @export
 * @interface ListingRequest
 */
export interface ListingRequest {
    /**
     * 
     * @type {string}
     * @memberof ListingRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingRequest
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingRequest
     */
    'ticker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingRequest
     */
    'websiteUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingRequest
     */
    'bitcoinTalkAnnThreadUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface Merchant
 */
export interface Merchant {
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'createdAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Merchant
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'callbackUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'successUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    'faultUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface MerchantBalance
 */
export interface MerchantBalance {
    /**
     * 
     * @type {number}
     * @memberof MerchantBalance
     */
    'currencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MerchantBalance
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MerchantBalance
     */
    'inserted'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MerchantBalance
     */
    'lastUpdated'?: string | null;
}
/**
 * 
 * @export
 * @interface MerchantInformation
 */
export interface MerchantInformation {
    /**
     * 
     * @type {Merchant}
     * @memberof MerchantInformation
     */
    'merchant'?: Merchant;
    /**
     * 
     * @type {Array<WithdrawRequest>}
     * @memberof MerchantInformation
     */
    'withdarals'?: Array<WithdrawRequest> | null;
    /**
     * 
     * @type {Array<MerchantTransaction>}
     * @memberof MerchantInformation
     */
    'deposits'?: Array<MerchantTransaction> | null;
    /**
     * 
     * @type {Array<MerchantBalance>}
     * @memberof MerchantInformation
     */
    'balance'?: Array<MerchantBalance> | null;
}
/**
 * 
 * @export
 * @interface MerchantTransaction
 */
export interface MerchantTransaction {
    /**
     * 
     * @type {string}
     * @memberof MerchantTransaction
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MerchantTransaction
     */
    'currencyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MerchantTransaction
     */
    'orderId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MerchantTransaction
     */
    'amount'?: number;
    /**
     * 
     * @type {TransactionState}
     * @memberof MerchantTransaction
     */
    'state'?: TransactionState;
    /**
     * 
     * @type {string}
     * @memberof MerchantTransaction
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantTransaction
     */
    'timeoutAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantTransaction
     */
    'depositAddressId'?: string | null;
}


/**
 * 
 * @export
 * @interface MerchantWithdrawStatusResponse
 */
export interface MerchantWithdrawStatusResponse {
    /**
     * 
     * @type {WithdrawRequest}
     * @memberof MerchantWithdrawStatusResponse
     */
    'withdrawRequest'?: WithdrawRequest;
    /**
     * 
     * @type {Transaction}
     * @memberof MerchantWithdrawStatusResponse
     */
    'transaction'?: Transaction;
}
/**
 * 
 * @export
 * @interface OS
 */
export interface OS {
    /**
     * 
     * @type {string}
     * @memberof OS
     */
    'family'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OS
     */
    'major'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OS
     */
    'minor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OS
     */
    'patch'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OS
     */
    'patchMinor'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OfferStatus = {
    Active: 'Active',
    Inactive: 'Inactive',
    Deleted: 'Deleted'
} as const;

export type OfferStatus = typeof OfferStatus[keyof typeof OfferStatus];


/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'id'?: number;
    /**
     * 
     * @type {TradeType}
     * @memberof Order
     */
    'tradeType'?: TradeType;
    /**
     * 
     * @type {OrderTypes}
     * @memberof Order
     */
    'orderType'?: OrderTypes;
    /**
     * 
     * @type {OrderState}
     * @memberof Order
     */
    'orderState'?: OrderState;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'tradePairname'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'amountLeft'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'stopLossPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'takeProfitPrice'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    'isMargin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'liquidationPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'initialPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'leverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'initialAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'closePositionPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'dateCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'dateClosed'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'dateOnMarket'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    'closed'?: boolean;
}


/**
 * 
 * @export
 * @interface OrderCancelResult
 */
export interface OrderCancelResult {
    /**
     * 
     * @type {boolean}
     * @memberof OrderCancelResult
     */
    'result'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderCancelResult
     */
    'reason'?: string | null;
}
/**
 * 
 * @export
 * @interface OrderCreateResult
 */
export interface OrderCreateResult {
    /**
     * 
     * @type {boolean}
     * @memberof OrderCreateResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderCreateResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {Order}
     * @memberof OrderCreateResult
     */
    'order'?: Order;
    /**
     * 
     * @type {Array<Balance>}
     * @memberof OrderCreateResult
     */
    'balance'?: Array<Balance> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateResult
     */
    'execution'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderCreateResult
     */
    'requestTime'?: number;
    /**
     * 
     * @type {Array<TradesGetViewModel>}
     * @memberof OrderCreateResult
     */
    'trades'?: Array<TradesGetViewModel> | null;
    /**
     * 
     * @type {Array<BalanceActionModel>}
     * @memberof OrderCreateResult
     */
    'balanceActions'?: Array<BalanceActionModel> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderState = {
    WaitingForActivation: 'WaitingForActivation',
    OnMarket: 'OnMarket',
    PartiallyFilled: 'PartiallyFilled',
    Cancelled: 'Cancelled',
    Rejected: 'Rejected',
    CloseFilled: 'Close_Filled',
    CancelledByUser: 'Cancelled_ByUser',
    CloseMarginCall: 'Close_MarginCall'
} as const;

export type OrderState = typeof OrderState[keyof typeof OrderState];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderTypes = {
    Market: 'Market',
    Limit: 'Limit'
} as const;

export type OrderTypes = typeof OrderTypes[keyof typeof OrderTypes];


/**
 * 
 * @export
 * @interface OrderViewModel
 */
export interface OrderViewModel {
    /**
     * 
     * @type {number}
     * @memberof OrderViewModel
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderViewModel
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface OrderbookResponseModel
 */
export interface OrderbookResponseModel {
    /**
     * 
     * @type {number}
     * @memberof OrderbookResponseModel
     */
    'timestamp'?: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof OrderbookResponseModel
     */
    'bids'?: Array<any> | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof OrderbookResponseModel
     */
    'asks'?: Array<any> | null;
}
/**
 * 
 * @export
 * @interface OrdersGetViewModel
 */
export interface OrdersGetViewModel {
    /**
     * 
     * @type {Array<OrderViewModel>}
     * @memberof OrdersGetViewModel
     */
    'sell'?: Array<OrderViewModel> | null;
    /**
     * 
     * @type {Array<OrderViewModel>}
     * @memberof OrdersGetViewModel
     */
    'buy'?: Array<OrderViewModel> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ParameterType = {
    PhoneNumber: 'PhoneNumber',
    SessionLifetime: 'SessionLifetime'
} as const;

export type ParameterType = typeof ParameterType[keyof typeof ParameterType];


/**
 * 
 * @export
 * @interface PinCodeRequestModel
 */
export interface PinCodeRequestModel {
    /**
     * 
     * @type {string}
     * @memberof PinCodeRequestModel
     */
    'oldPinCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PinCodeRequestModel
     */
    'newPinCode'?: string | null;
}
/**
 * 
 * @export
 * @interface PostConfirmRequest
 */
export interface PostConfirmRequest {
    /**
     * 
     * @type {string}
     * @memberof PostConfirmRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof PostConfirmRequest
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof PostConfirmRequest
     */
    'isInApp'?: boolean;
}
/**
 * 
 * @export
 * @interface PostForgotPasswordRequest
 */
export interface PostForgotPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof PostForgotPasswordRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface PostMobileConfirmationRequestModel
 */
export interface PostMobileConfirmationRequestModel {
    /**
     * 
     * @type {RequestType}
     * @memberof PostMobileConfirmationRequestModel
     */
    'requestType'?: RequestType;
    /**
     * 
     * @type {string}
     * @memberof PostMobileConfirmationRequestModel
     */
    'bitflexDeviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostMobileConfirmationRequestModel
     */
    'encryptedRequestId'?: string | null;
}


/**
 * 
 * @export
 * @interface PostOrdersRequest
 */
export interface PostOrdersRequest {
    /**
     * 
     * @type {number}
     * @memberof PostOrdersRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostOrdersRequest
     */
    'price'?: number;
    /**
     * 
     * @type {TradeType}
     * @memberof PostOrdersRequest
     */
    'tradeType'?: TradeType;
    /**
     * 
     * @type {number}
     * @memberof PostOrdersRequest
     */
    'pairId'?: number;
    /**
     * 
     * @type {OrderTypes}
     * @memberof PostOrdersRequest
     */
    'orderType'?: OrderTypes;
    /**
     * 
     * @type {boolean}
     * @memberof PostOrdersRequest
     */
    'isMargin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostOrdersRequest
     */
    'leverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostOrdersRequest
     */
    'initialAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostOrdersRequest
     */
    'initialPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostOrdersRequest
     */
    'stopLossPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostOrdersRequest
     */
    'takeProfitPrice'?: number;
}


/**
 * 
 * @export
 * @interface PostResetPasswordRequest
 */
export interface PostResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof PostResetPasswordRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof PostResetPasswordRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PostResetPasswordRequest
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PostSharkRegisterResult
 */
export interface PostSharkRegisterResult {
    /**
     * 
     * @type {SharkRegisterResult}
     * @memberof PostSharkRegisterResult
     */
    'result'?: SharkRegisterResult;
    /**
     * 
     * @type {ErrorModel}
     * @memberof PostSharkRegisterResult
     */
    'error'?: ErrorModel;
}


/**
 * 
 * @export
 * @interface PostSignInRequest
 */
export interface PostSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof PostSignInRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PostSignInRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PostSignInRequest
     */
    'reCaptchav3Token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostSignInRequest
     */
    'bitflexDeviceId': string;
    /**
     * 
     * @type {string}
     * @memberof PostSignInRequest
     */
    'googleTfaCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostSignInRequest
     */
    'rememberDevice'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostSignInRequest
     */
    'rememberedDeviceToken'?: string | null;
}
/**
 * 
 * @export
 * @interface PostSignInResponse
 */
export interface PostSignInResponse {
    /**
     * 
     * @type {SignInResponseResult}
     * @memberof PostSignInResponse
     */
    'result'?: SignInResponseResult;
    /**
     * 
     * @type {string}
     * @memberof PostSignInResponse
     */
    'authToken'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostSignInResponse
     */
    'expiryTimestamp'?: number;
    /**
     * 
     * @type {TwoStepVerificationTypes}
     * @memberof PostSignInResponse
     */
    'twoFactorType'?: TwoStepVerificationTypes;
    /**
     * 
     * @type {string}
     * @memberof PostSignInResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostSignInResponse
     */
    'rememberDeviceToken'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostSignInResponse
     */
    'requrePinSetting'?: boolean;
}


/**
 * 
 * @export
 * @interface PostSignUpRequest
 */
export interface PostSignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof PostSignUpRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof PostSignUpRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PostSignUpRequest
     */
    'pinCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostSignUpRequest
     */
    'isInApp'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostSignUpRequest
     */
    'refId'?: string | null;
}
/**
 * 
 * @export
 * @interface PostSignUpResponse
 */
export interface PostSignUpResponse {
    /**
     * 
     * @type {SignUpResponseResult}
     * @memberof PostSignUpResponse
     */
    'result'?: SignUpResponseResult;
    /**
     * 
     * @type {ErrorModel}
     * @memberof PostSignUpResponse
     */
    'error'?: ErrorModel;
}


/**
 * 
 * @export
 * @interface PostVefirySms
 */
export interface PostVefirySms {
    /**
     * 
     * @type {string}
     * @memberof PostVefirySms
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PostVefirySms
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface PostWithdrawGuardResponse
 */
export interface PostWithdrawGuardResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PostWithdrawGuardResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {PostWithdrawGuardResponseErrorCode}
     * @memberof PostWithdrawGuardResponse
     */
    'errorCode'?: PostWithdrawGuardResponseErrorCode;
    /**
     * 
     * @type {string}
     * @memberof PostWithdrawGuardResponse
     */
    'withdrawId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PostWithdrawGuardResponseErrorCode = {
    WrongAmount: 'WrongAmount',
    VerificationTypeError: 'VerificationTypeError',
    WrongCurrency: 'WrongCurrency',
    InsufficentBalance: 'InsufficentBalance',
    WrongGuardId: 'WrongGuardId'
} as const;

export type PostWithdrawGuardResponseErrorCode = typeof PostWithdrawGuardResponseErrorCode[keyof typeof PostWithdrawGuardResponseErrorCode];


/**
 * 
 * @export
 * @interface PostWithdrawRequest
 */
export interface PostWithdrawRequest {
    /**
     * 
     * @type {number}
     * @memberof PostWithdrawRequest
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof PostWithdrawRequest
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof PostWithdrawRequest
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof PostWithdrawRequest
     */
    'destinationTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostWithdrawRequest
     */
    'googleAuthenticatorCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostWithdrawRequest
     */
    'networkCurrencyId'?: number;
}
/**
 * 
 * @export
 * @interface PostWithdrawRequestResult
 */
export interface PostWithdrawRequestResult {
    /**
     * 
     * @type {boolean}
     * @memberof PostWithdrawRequestResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {WithdrawErrorCode}
     * @memberof PostWithdrawRequestResult
     */
    'withdrawErrorCode'?: WithdrawErrorCode;
    /**
     * 
     * @type {string}
     * @memberof PostWithdrawRequestResult
     */
    'withdrawId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PrecheckLogoutResult = {
    CanLogout: 'CanLogout',
    CanLogoutOtherDeviceAvailable: 'CanLogout_OtherDeviceAvailable',
    Cannot: 'Cannot'
} as const;

export type PrecheckLogoutResult = typeof PrecheckLogoutResult[keyof typeof PrecheckLogoutResult];


/**
 * 
 * @export
 * @interface PriceAlert
 */
export interface PriceAlert {
    /**
     * 
     * @type {string}
     * @memberof PriceAlert
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PriceAlert
     */
    'userId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PriceAlert
     */
    'pairId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceAlert
     */
    'price'?: number;
    /**
     * 
     * @type {PriceAlertCompareType}
     * @memberof PriceAlert
     */
    'comparer'?: PriceAlertCompareType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PriceAlertCompareType = {
    LessOrEqual: 'LessOrEqual',
    MoreOrEqual: 'MoreOrEqual'
} as const;

export type PriceAlertCompareType = typeof PriceAlertCompareType[keyof typeof PriceAlertCompareType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PriceAlertCreateStatus = {
    Created: 'Created',
    FailAlreadyExists: 'Fail_AlreadyExists',
    SystemError: 'SystemError',
    MaxReached: 'MaxReached'
} as const;

export type PriceAlertCreateStatus = typeof PriceAlertCreateStatus[keyof typeof PriceAlertCreateStatus];


/**
 * 
 * @export
 * @interface ProcessFaceImageRequest
 */
export interface ProcessFaceImageRequest {
    /**
     * 
     * @type {string}
     * @memberof ProcessFaceImageRequest
     */
    'containerUUID'?: string | null;
}
/**
 * 
 * @export
 * @interface Rate
 */
export interface Rate {
    /**
     * 
     * @type {string}
     * @memberof Rate
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Rate
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface RequestSettingsTokenResponseModel
 */
export interface RequestSettingsTokenResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof RequestSettingsTokenResponseModel
     */
    'success'?: boolean;
    /**
     * 
     * @type {SettingsTokenRequestErrorType}
     * @memberof RequestSettingsTokenResponseModel
     */
    'errorType'?: SettingsTokenRequestErrorType;
    /**
     * 
     * @type {string}
     * @memberof RequestSettingsTokenResponseModel
     */
    'token'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RequestSettingsTokenResponseModel
     */
    'expiration'?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestSettingsTokenResponseModel
     */
    'supportPIN'?: number;
    /**
     * 
     * @type {TwoStepVerificationTypes}
     * @memberof RequestSettingsTokenResponseModel
     */
    'verificationTypes'?: TwoStepVerificationTypes;
    /**
     * 
     * @type {number}
     * @memberof RequestSettingsTokenResponseModel
     */
    'sessionLifeTimeMinutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestSettingsTokenResponseModel
     */
    'verificationLevel'?: number;
    /**
     * 
     * @type {Tuple3}
     * @memberof RequestSettingsTokenResponseModel
     */
    'signInNotification'?: Tuple3;
    /**
     * 
     * @type {Tuple3}
     * @memberof RequestSettingsTokenResponseModel
     */
    'withdrawNotification'?: Tuple3;
    /**
     * 
     * @type {Tuple3}
     * @memberof RequestSettingsTokenResponseModel
     */
    'depositNotification'?: Tuple3;
    /**
     * 
     * @type {Tuple3}
     * @memberof RequestSettingsTokenResponseModel
     */
    'listingNotification'?: Tuple3;
    /**
     * 
     * @type {Tuple3}
     * @memberof RequestSettingsTokenResponseModel
     */
    'delistingNotification'?: Tuple3;
}


/**
 * 
 * @export
 * @interface RequestSettingsTokenResponseRequestModel
 */
export interface RequestSettingsTokenResponseRequestModel {
    /**
     * 
     * @type {string}
     * @memberof RequestSettingsTokenResponseRequestModel
     */
    'pinCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RequestSettingsTokenResponseRequestModel
     */
    'biometryIdentified'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RequestType = {
    EnableTwoFactor: 'EnableTwoFactor',
    CancelEnableTwoFactor: 'CancelEnableTwoFactor',
    DisableTwoFactor: 'DisableTwoFactor',
    ConfirmLogin: 'ConfirmLogin',
    ConfirmWithdraw: 'ConfirmWithdraw',
    CheckIsOnline: 'CheckIsOnline',
    PasswordChange: 'PasswordChange'
} as const;

export type RequestType = typeof RequestType[keyof typeof RequestType];


/**
 * 
 * @export
 * @interface RestorePinPostRequest
 */
export interface RestorePinPostRequest {
    /**
     * 
     * @type {string}
     * @memberof RestorePinPostRequest
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ReturnFeeStructure
 */
export interface ReturnFeeStructure {
    /**
     * 
     * @type {{ [key: string]: FeeStructure; }}
     * @memberof ReturnFeeStructure
     */
    'baseFeeLevels': { [key: string]: FeeStructure; };
    /**
     * 
     * @type {UserFeeLevel}
     * @memberof ReturnFeeStructure
     */
    'userFeeLevel'?: UserFeeLevel;
}
/**
 * 
 * @export
 * @interface RevokeTokenByIdRequestModel
 */
export interface RevokeTokenByIdRequestModel {
    /**
     * 
     * @type {string}
     * @memberof RevokeTokenByIdRequestModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RevokeTokenByIdRequestModel
     */
    'reasonText'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ServerLocation = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type ServerLocation = typeof ServerLocation[keyof typeof ServerLocation];


/**
 * 
 * @export
 * @interface SetPriceAlertRequestModel
 */
export interface SetPriceAlertRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SetPriceAlertRequestModel
     */
    'pairName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SetPriceAlertRequestModel
     */
    'price'?: number;
    /**
     * 
     * @type {PriceAlertCompareType}
     * @memberof SetPriceAlertRequestModel
     */
    'comparer'?: PriceAlertCompareType;
}


/**
 * 
 * @export
 * @interface SetPriceAlertResponseModel
 */
export interface SetPriceAlertResponseModel {
    /**
     * 
     * @type {PriceAlertCreateStatus}
     * @memberof SetPriceAlertResponseModel
     */
    'status'?: PriceAlertCreateStatus;
    /**
     * 
     * @type {Array<PriceAlert>}
     * @memberof SetPriceAlertResponseModel
     */
    'currentAlerts'?: Array<PriceAlert> | null;
}


/**
 * 
 * @export
 * @interface SetPushTokenRequest
 */
export interface SetPushTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof SetPushTokenRequest
     */
    'pushToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetPushTokenRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {DeviceType}
     * @memberof SetPushTokenRequest
     */
    'device'?: DeviceType;
    /**
     * 
     * @type {string}
     * @memberof SetPushTokenRequest
     */
    'publicKey'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SettingsTokenRequestErrorType = {
    WrongPinCode: 'WrongPinCode',
    DeviceNotAllowed: 'DeviceNotAllowed',
    PreviousNotExpired: 'PreviousNotExpired'
} as const;

export type SettingsTokenRequestErrorType = typeof SettingsTokenRequestErrorType[keyof typeof SettingsTokenRequestErrorType];


/**
 * 
 * @export
 * @interface SetupCode
 */
export interface SetupCode {
    /**
     * 
     * @type {string}
     * @memberof SetupCode
     */
    'account'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupCode
     */
    'accountSecretKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupCode
     */
    'manualEntryKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupCode
     */
    'qrCodeSetupImageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetupCode
     */
    'otpCleanUrl'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SharkRegisterResult = {
    BadInput: 'BadInput',
    FailCreation: 'FailCreation',
    SuccessEmailSent: 'SuccessEmailSent',
    SharkWasNotPreregistered: 'SharkWasNotPreregistered',
    SharkWithoutRegistrationOk: 'SharkWithoutRegistration_OK'
} as const;

export type SharkRegisterResult = typeof SharkRegisterResult[keyof typeof SharkRegisterResult];


/**
 * 
 * @export
 * @interface SharkUser
 */
export interface SharkUser {
    /**
     * 
     * @type {string}
     * @memberof SharkUser
     */
    'telegramId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SharkUser
     */
    'totalTaps'?: number;
    /**
     * 
     * @type {number}
     * @memberof SharkUser
     */
    'remainingEnergy'?: number;
    /**
     * 
     * @type {number}
     * @memberof SharkUser
     */
    'sharkTokenBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof SharkUser
     */
    'yonessBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof SharkUser
     */
    'usdtBalance'?: number;
    /**
     * 
     * @type {string}
     * @memberof SharkUser
     */
    'registrationDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharkUser
     */
    'lastAccessDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof SharkUser
     */
    'lastBonusClaimDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SharkUser
     */
    'referralCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SharkUser
     */
    'referrer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SharkUser
     */
    'bcflexUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SharkUser
     */
    'verificationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SharkUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SharkUser
     */
    'isEmailConfirmed'?: boolean;
}
/**
 * 
 * @export
 * @interface SignInPermissionModel
 */
export interface SignInPermissionModel {
    /**
     * 
     * @type {DevicePermissionRequestType}
     * @memberof SignInPermissionModel
     */
    'type'?: DevicePermissionRequestType;
    /**
     * 
     * @type {string}
     * @memberof SignInPermissionModel
     */
    'bitflexDeviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignInPermissionModel
     */
    'terminalSignalRConnectionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignInPermissionModel
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignInPermissionModel
     */
    'ipAddress'?: string | null;
    /**
     * 
     * @type {ClientInfo}
     * @memberof SignInPermissionModel
     */
    'clientInfo'?: ClientInfo;
    /**
     * 
     * @type {number}
     * @memberof SignInPermissionModel
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof SignInPermissionModel
     */
    'longitude'?: number;
    /**
     * 
     * @type {string}
     * @memberof SignInPermissionModel
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignInPermissionModel
     */
    'deviceDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignInPermissionModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignInPermissionModel
     */
    'validTill'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SignInResponseResult = {
    WrongCredentials: 'WrongCredentials',
    WrongPassword: 'WrongPassword',
    EmailNotConfirmed: 'EmailNotConfirmed',
    RequireTwoFactor: 'RequireTwoFactor',
    Success: 'Success',
    ReCaptchav3Failed: 'ReCaptchav3Failed',
    BitflexDeviceIdIsNotPresent: 'BitflexDeviceIdIsNotPresent',
    GoogleTfaWrong: 'GoogleTfaWrong',
    RememberDeviceTokenFail: 'RememberDeviceTokenFail'
} as const;

export type SignInResponseResult = typeof SignInResponseResult[keyof typeof SignInResponseResult];


/**
 * 
 * @export
 * @enum {string}
 */

export const SignUpResponseResult = {
    BadInput: 'BadInput',
    FailCreation: 'FailCreation',
    SuccessEmailSent: 'SuccessEmailSent'
} as const;

export type SignUpResponseResult = typeof SignUpResponseResult[keyof typeof SignUpResponseResult];


/**
 * 
 * @export
 * @enum {string}
 */

export const SmsVefiryResult = {
    Success: 'Success',
    Fail: 'Fail',
    SystemError: 'SystemError'
} as const;

export type SmsVefiryResult = typeof SmsVefiryResult[keyof typeof SmsVefiryResult];


/**
 * 
 * @export
 * @enum {string}
 */

export const StatusCodeEnum = {
    Success: 'Success',
    Error: 'Error',
    UpToDate: 'UpToDate'
} as const;

export type StatusCodeEnum = typeof StatusCodeEnum[keyof typeof StatusCodeEnum];


/**
 * 
 * @export
 * @interface SummaryResponseModel
 */
export interface SummaryResponseModel {
    /**
     * 
     * @type {string}
     * @memberof SummaryResponseModel
     */
    'trading_pairs'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SummaryResponseModel
     */
    'last_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryResponseModel
     */
    'lowest_ask'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryResponseModel
     */
    'highest_bid'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryResponseModel
     */
    'base_volume'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryResponseModel
     */
    'quote_volume'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryResponseModel
     */
    'price_change_percent_24h'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryResponseModel
     */
    'highest_price_24h'?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryResponseModel
     */
    'lowest_price_24h'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TapReslutCode = {
    Ok: 'OK',
    OutOfEnergy: 'OutOfEnergy',
    Error: 'Error',
    WrongOrLockedUser: 'WrongOrLockedUser'
} as const;

export type TapReslutCode = typeof TapReslutCode[keyof typeof TapReslutCode];


/**
 * 
 * @export
 * @interface TapResult
 */
export interface TapResult {
    /**
     * 
     * @type {SharkUser}
     * @memberof TapResult
     */
    'sharkUser'?: SharkUser;
    /**
     * 
     * @type {TapReslutCode}
     * @memberof TapResult
     */
    'resultCode'?: TapReslutCode;
}


/**
 * 
 * @export
 * @interface TickerResponseModel
 */
export interface TickerResponseModel {
    /**
     * 
     * @type {number}
     * @memberof TickerResponseModel
     */
    'baseCurrencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TickerResponseModel
     */
    'quoteCurrencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TickerResponseModel
     */
    'lastPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof TickerResponseModel
     */
    'quoteCurrencyVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof TickerResponseModel
     */
    'baseCurrencyVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof TickerResponseModel
     */
    'isFrozen'?: number;
}
/**
 * 
 * @export
 * @interface TradeData
 */
export interface TradeData {
    /**
     * 
     * @type {Array<Order>}
     * @memberof TradeData
     */
    'orders'?: Array<Order> | null;
    /**
     * 
     * @type {Array<Transaction>}
     * @memberof TradeData
     */
    'transactions'?: Array<Transaction> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TradeDirection = {
    Buy: 'Buy',
    Sell: 'Sell'
} as const;

export type TradeDirection = typeof TradeDirection[keyof typeof TradeDirection];


/**
 * 
 * @export
 * @enum {string}
 */

export const TradeType = {
    Buy: 'Buy',
    Sell: 'Sell'
} as const;

export type TradeType = typeof TradeType[keyof typeof TradeType];


/**
 * 
 * @export
 * @interface TradesGetViewModel
 */
export interface TradesGetViewModel {
    /**
     * 
     * @type {number}
     * @memberof TradesGetViewModel
     */
    'dateTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof TradesGetViewModel
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof TradesGetViewModel
     */
    'amount'?: number;
    /**
     * 
     * @type {TradeType}
     * @memberof TradesGetViewModel
     */
    'type'?: TradeType;
}


/**
 * 
 * @export
 * @interface TradesResponseModel
 */
export interface TradesResponseModel {
    /**
     * 
     * @type {number}
     * @memberof TradesResponseModel
     */
    'tradeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TradesResponseModel
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof TradesResponseModel
     */
    'baseVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof TradesResponseModel
     */
    'quoteVolume'?: number;
    /**
     * 
     * @type {number}
     * @memberof TradesResponseModel
     */
    'timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof TradesResponseModel
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'merchantId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'merchantTransactionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'txId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Transaction
     */
    'date'?: number;
    /**
     * 
     * @type {TransactionDirection}
     * @memberof Transaction
     */
    'direction'?: TransactionDirection;
    /**
     * 
     * @type {boolean}
     * @memberof Transaction
     */
    'done'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    'from'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TransactionDirection = {
    In: 'In',
    Out: 'Out'
} as const;

export type TransactionDirection = typeof TransactionDirection[keyof typeof TransactionDirection];


/**
 * 
 * @export
 * @enum {string}
 */

export const TransactionState = {
    Created: 'CREATED',
    Cancelled: 'CANCELLED',
    Timeout: 'TIMEOUT',
    Payed: 'PAYED',
    ProjectOkAwait: 'PROJECT_OK_AWAIT',
    ProjectNotConfirmed: 'PROJECT_NOT_CONFIRMED',
    PendingConfirmations: 'PENDING_CONFIRMATIONS',
    BadAmount: 'BAD_AMOUNT'
} as const;

export type TransactionState = typeof TransactionState[keyof typeof TransactionState];


/**
 * 
 * @export
 * @interface Tuple3
 */
export interface Tuple3 {
    /**
     * 
     * @type {boolean}
     * @memberof Tuple3
     */
    'item1'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tuple3
     */
    'item2'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Tuple3
     */
    'item3'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TwoStepVerificationTypes = {
    Bitflex: 'BITFLEX',
    Google: 'Google'
} as const;

export type TwoStepVerificationTypes = typeof TwoStepVerificationTypes[keyof typeof TwoStepVerificationTypes];


/**
 * 
 * @export
 * @enum {string}
 */

export const Type = {
    BtcBased: 'BtcBased',
    EthBased: 'EthBased',
    Fiat: 'Fiat',
    Ripple: 'Ripple',
    Eos: 'Eos',
    Erc20: 'ERC20',
    Tron: 'Tron',
    Trc20: 'TRC20',
    Token: 'Token'
} as const;

export type Type = typeof Type[keyof typeof Type];


/**
 * 
 * @export
 * @interface UpdatePublicKeyRequest
 */
export interface UpdatePublicKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePublicKeyRequest
     */
    'bitflexDeviceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePublicKeyRequest
     */
    'publicKeyPEM'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePublicKeyRequest
     */
    'publicKeyPKCS8'?: string | null;
    /**
     * 
     * @type {DeviceType}
     * @memberof UpdatePublicKeyRequest
     */
    'deviceType'?: DeviceType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserAdminCommand = {
    BlockUser: 'BlockUser',
    UnblockUser: 'UnblockUser',
    VerifyEmail: 'VerifyEmail',
    TwoFactorDisable: 'TwoFactorDisable',
    ResetPassword: 'ResetPassword'
} as const;

export type UserAdminCommand = typeof UserAdminCommand[keyof typeof UserAdminCommand];


/**
 * 
 * @export
 * @interface UserAgent
 */
export interface UserAgent {
    /**
     * 
     * @type {string}
     * @memberof UserAgent
     */
    'family'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAgent
     */
    'major'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAgent
     */
    'minor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAgent
     */
    'patch'?: string | null;
}
/**
 * 
 * @export
 * @interface UserFeeLevel
 */
export interface UserFeeLevel {
    /**
     * 
     * @type {number}
     * @memberof UserFeeLevel
     */
    'btcTurnover'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserFeeLevel
     */
    'feeLevel'?: number;
}
/**
 * 
 * @export
 * @interface ValidatePinPostRequest
 */
export interface ValidatePinPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidatePinPostRequest
     */
    'pinCode': string;
}
/**
 * 
 * @export
 * @interface VerificationResource
 */
export interface VerificationResource {
    /**
     * 
     * @type {string}
     * @memberof VerificationResource
     */
    'sid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationResource
     */
    'serviceSid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationResource
     */
    'accountSid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationResource
     */
    'to'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof VerificationResource
     */
    'channel'?: object;
    /**
     * 
     * @type {string}
     * @memberof VerificationResource
     */
    'status'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VerificationResource
     */
    'valid'?: boolean | null;
    /**
     * 
     * @type {any}
     * @memberof VerificationResource
     */
    'lookup'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationResource
     */
    'amount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationResource
     */
    'payee'?: string | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof VerificationResource
     */
    'sendCodeAttempts'?: Array<any> | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationResource
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationResource
     */
    'dateUpdated'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof VerificationResource
     */
    'sna'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof VerificationResource
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface VerifiedContactsResponse
 */
export interface VerifiedContactsResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifiedContactsResponse
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifiedContactsResponse
     */
    'telegramUsername'?: string | null;
}
/**
 * 
 * @export
 * @interface VerifyFaceData
 */
export interface VerifyFaceData {
    /**
     * 
     * @type {string}
     * @memberof VerifyFaceData
     */
    'faceId1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyFaceData
     */
    'faceId2'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const VerifyRegisterResult = {
    BadCode: 'BadCode',
    Success: 'Success',
    Error: 'Error'
} as const;

export type VerifyRegisterResult = typeof VerifyRegisterResult[keyof typeof VerifyRegisterResult];


/**
 * 
 * @export
 * @interface VerifyResult
 */
export interface VerifyResult {
    /**
     * 
     * @type {boolean}
     * @memberof VerifyResult
     */
    'isIdentical'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VerifyResult
     */
    'confidence'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WithdrawErrorCode = {
    InvalidCurrency: 'InvalidCurrency',
    InvalidAmount: 'InvalidAmount',
    InvalidAddress: 'InvalidAddress',
    TwoStepRequired: 'TwoStepRequired',
    TwoStepFailed: 'TwoStepFailed'
} as const;

export type WithdrawErrorCode = typeof WithdrawErrorCode[keyof typeof WithdrawErrorCode];


/**
 * 
 * @export
 * @interface WithdrawRequest
 */
export interface WithdrawRequest {
    /**
     * 
     * @type {string}
     * @memberof WithdrawRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WithdrawRequest
     */
    'currencyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawRequest
     */
    'networkCurrencyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawRequest
     */
    'merchantId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WithdrawRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawRequest
     */
    'dateCreated'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawRequest
     */
    'paid'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WithdrawRequest
     */
    'datePaid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WithdrawRequest
     */
    'txId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawRequest
     */
    'isGuardRequired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WithdrawRequest
     */
    'isGuardConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WithdrawRequest
     */
    'destinationAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WithdrawRequest
     */
    'destinationTag'?: string | null;
}
/**
 * 
 * @export
 * @interface WithdrawRequestResult
 */
export interface WithdrawRequestResult {
    /**
     * 
     * @type {WithdrawRequest}
     * @memberof WithdrawRequestResult
     */
    'withdrawRequest'?: WithdrawRequest;
    /**
     * 
     * @type {string}
     * @memberof WithdrawRequestResult
     */
    'errorMessage'?: string | null;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CoinTokenAddRequest} [coinTokenAddRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminAddcointokenPost: async (coinTokenAddRequest?: CoinTokenAddRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Admin/addcointoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coinTokenAddRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminGetuserdataPost: async (email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Admin/getuserdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminGetusertradedataPost: async (email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Admin/getusertradedata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminSearchuserGet: async (searchString?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Admin/searchuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {UserAdminCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminUsercommandPost: async (email?: string, command?: UserAdminCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Admin/usercommand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (command !== undefined) {
                localVarQueryParameter['command'] = command;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {CoinTokenAddRequest} [coinTokenAddRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAdminAddcointokenPost: async (version: string, coinTokenAddRequest?: CoinTokenAddRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAdminAddcointokenPost', 'version', version)
            const localVarPath = `/api/v{version}/Admin/addcointoken`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coinTokenAddRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAdminGetuserdataPost: async (version: string, email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAdminGetuserdataPost', 'version', version)
            const localVarPath = `/api/v{version}/Admin/getuserdata`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAdminGetusertradedataPost: async (version: string, email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAdminGetusertradedataPost', 'version', version)
            const localVarPath = `/api/v{version}/Admin/getusertradedata`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAdminSearchuserGet: async (version: string, searchString?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAdminSearchuserGet', 'version', version)
            const localVarPath = `/api/v{version}/Admin/searchuser`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [email] 
         * @param {UserAdminCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAdminUsercommandPost: async (version: string, email?: string, command?: UserAdminCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionAdminUsercommandPost', 'version', version)
            const localVarPath = `/api/v{version}/Admin/usercommand`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (command !== undefined) {
                localVarQueryParameter['command'] = command;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CoinTokenAddRequest} [coinTokenAddRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminAddcointokenPost(coinTokenAddRequest?: CoinTokenAddRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CryptoCurrency>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminAddcointokenPost(coinTokenAddRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.apiAdminAddcointokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminGetuserdataPost(email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminGetuserdataPost(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.apiAdminGetuserdataPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminGetusertradedataPost(email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminGetusertradedataPost(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.apiAdminGetusertradedataPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminSearchuserGet(searchString?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminSearchuserGet(searchString, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.apiAdminSearchuserGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {UserAdminCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAdminUsercommandPost(email?: string, command?: UserAdminCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAdminUsercommandPost(email, command, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.apiAdminUsercommandPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {CoinTokenAddRequest} [coinTokenAddRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAdminAddcointokenPost(version: string, coinTokenAddRequest?: CoinTokenAddRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CryptoCurrency>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAdminAddcointokenPost(version, coinTokenAddRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.apiVversionAdminAddcointokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAdminGetuserdataPost(version: string, email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAdminGetuserdataPost(version, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.apiVversionAdminGetuserdataPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAdminGetusertradedataPost(version: string, email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAdminGetusertradedataPost(version, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.apiVversionAdminGetusertradedataPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAdminSearchuserGet(version: string, searchString?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAdminSearchuserGet(version, searchString, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.apiVversionAdminSearchuserGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [email] 
         * @param {UserAdminCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionAdminUsercommandPost(version: string, email?: string, command?: UserAdminCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionAdminUsercommandPost(version, email, command, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminApi.apiVversionAdminUsercommandPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {CoinTokenAddRequest} [coinTokenAddRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminAddcointokenPost(coinTokenAddRequest?: CoinTokenAddRequest, options?: any): AxiosPromise<CryptoCurrency> {
            return localVarFp.apiAdminAddcointokenPost(coinTokenAddRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminGetuserdataPost(email?: string, options?: any): AxiosPromise<ApplicationUser> {
            return localVarFp.apiAdminGetuserdataPost(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminGetusertradedataPost(email?: string, options?: any): AxiosPromise<TradeData> {
            return localVarFp.apiAdminGetusertradedataPost(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminSearchuserGet(searchString?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiAdminSearchuserGet(searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {UserAdminCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAdminUsercommandPost(email?: string, command?: UserAdminCommand, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiAdminUsercommandPost(email, command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {CoinTokenAddRequest} [coinTokenAddRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAdminAddcointokenPost(version: string, coinTokenAddRequest?: CoinTokenAddRequest, options?: any): AxiosPromise<CryptoCurrency> {
            return localVarFp.apiVversionAdminAddcointokenPost(version, coinTokenAddRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAdminGetuserdataPost(version: string, email?: string, options?: any): AxiosPromise<ApplicationUser> {
            return localVarFp.apiVversionAdminGetuserdataPost(version, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAdminGetusertradedataPost(version: string, email?: string, options?: any): AxiosPromise<TradeData> {
            return localVarFp.apiVversionAdminGetusertradedataPost(version, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAdminSearchuserGet(version: string, searchString?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiVversionAdminSearchuserGet(version, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [email] 
         * @param {UserAdminCommand} [command] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionAdminUsercommandPost(version: string, email?: string, command?: UserAdminCommand, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionAdminUsercommandPost(version, email, command, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {CoinTokenAddRequest} [coinTokenAddRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminAddcointokenPost(coinTokenAddRequest?: CoinTokenAddRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminAddcointokenPost(coinTokenAddRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminGetuserdataPost(email?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminGetuserdataPost(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminGetusertradedataPost(email?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminGetusertradedataPost(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminSearchuserGet(searchString?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminSearchuserGet(searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {UserAdminCommand} [command] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiAdminUsercommandPost(email?: string, command?: UserAdminCommand, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiAdminUsercommandPost(email, command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {CoinTokenAddRequest} [coinTokenAddRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiVversionAdminAddcointokenPost(version: string, coinTokenAddRequest?: CoinTokenAddRequest, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiVversionAdminAddcointokenPost(version, coinTokenAddRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiVversionAdminGetuserdataPost(version: string, email?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiVversionAdminGetuserdataPost(version, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiVversionAdminGetusertradedataPost(version: string, email?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiVversionAdminGetusertradedataPost(version, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiVversionAdminSearchuserGet(version: string, searchString?: string, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiVversionAdminSearchuserGet(version, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [email] 
     * @param {UserAdminCommand} [command] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public apiVversionAdminUsercommandPost(version: string, email?: string, command?: UserAdminCommand, options?: RawAxiosRequestConfig) {
        return AdminApiFp(this.configuration).apiVversionAdminUsercommandPost(version, email, command, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationDisableGoogle2stepPost: async (enableDisable2Fa?: EnableDisable2Fa, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/DisableGoogle2step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableDisable2Fa, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationEnableGoogle2stepPost: async (enableDisable2Fa?: EnableDisable2Fa, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/EnableGoogle2step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableDisable2Fa, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationPackagePost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/package`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationProcessFaceImagePost: async (processFaceImageRequest?: ProcessFaceImageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/ProcessFaceImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processFaceImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationProcessPassportImagePost: async (processFaceImageRequest?: ProcessFaceImageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/ProcessPassportImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processFaceImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePublicKeyRequest} [updatePublicKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationPublicKeyPut: async (updatePublicKeyRequest?: UpdatePublicKeyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/publicKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePublicKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationRatesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RestorePinPostRequest} [restorePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationRestorePinPost: async (restorePinPostRequest?: RestorePinPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/RestorePin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restorePinPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PinCodeRequestModel} [pinCodeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSetpinPut: async (pinCodeRequestModel?: PinCodeRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/setpin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pinCodeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPushTokenRequest} [setPushTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSetpushtokenPost: async (setPushTokenRequest?: SetPushTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/setpushtoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPushTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidatePinPostRequest} [validatePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationValidatePinPost: async (validatePinPostRequest?: ValidatePinPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/validatePin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validatePinPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyFaceData} [verifyFaceData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationVerifyfacesPost: async (verifyFaceData?: VerifyFaceData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Application/verifyfaces`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyFaceData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationDisableGoogle2stepPost: async (version: string, enableDisable2Fa?: EnableDisable2Fa, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationDisableGoogle2stepPost', 'version', version)
            const localVarPath = `/api/v{version}/Application/DisableGoogle2step`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableDisable2Fa, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationEnableGoogle2stepPost: async (version: string, enableDisable2Fa?: EnableDisable2Fa, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationEnableGoogle2stepPost', 'version', version)
            const localVarPath = `/api/v{version}/Application/EnableGoogle2step`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableDisable2Fa, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationPackagePost: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationPackagePost', 'version', version)
            const localVarPath = `/api/v{version}/Application/package`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationProcessFaceImagePost: async (version: string, processFaceImageRequest?: ProcessFaceImageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationProcessFaceImagePost', 'version', version)
            const localVarPath = `/api/v{version}/Application/ProcessFaceImage`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processFaceImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationProcessPassportImagePost: async (version: string, processFaceImageRequest?: ProcessFaceImageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationProcessPassportImagePost', 'version', version)
            const localVarPath = `/api/v{version}/Application/ProcessPassportImage`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(processFaceImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {UpdatePublicKeyRequest} [updatePublicKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationPublicKeyPut: async (version: string, updatePublicKeyRequest?: UpdatePublicKeyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationPublicKeyPut', 'version', version)
            const localVarPath = `/api/v{version}/Application/publicKey`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePublicKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationRatesGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationRatesGet', 'version', version)
            const localVarPath = `/api/v{version}/Application/rates`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {RestorePinPostRequest} [restorePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationRestorePinPost: async (version: string, restorePinPostRequest?: RestorePinPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationRestorePinPost', 'version', version)
            const localVarPath = `/api/v{version}/Application/RestorePin`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restorePinPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PinCodeRequestModel} [pinCodeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationSetpinPut: async (version: string, pinCodeRequestModel?: PinCodeRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationSetpinPut', 'version', version)
            const localVarPath = `/api/v{version}/Application/setpin`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pinCodeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {SetPushTokenRequest} [setPushTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationSetpushtokenPost: async (version: string, setPushTokenRequest?: SetPushTokenRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationSetpushtokenPost', 'version', version)
            const localVarPath = `/api/v{version}/Application/setpushtoken`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPushTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {ValidatePinPostRequest} [validatePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationValidatePinPost: async (version: string, validatePinPostRequest?: ValidatePinPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationValidatePinPost', 'version', version)
            const localVarPath = `/api/v{version}/Application/validatePin`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validatePinPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {VerifyFaceData} [verifyFaceData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationVerifyfacesPost: async (version: string, verifyFaceData?: VerifyFaceData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionApplicationVerifyfacesPost', 'version', version)
            const localVarPath = `/api/v{version}/Application/verifyfaces`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyFaceData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationDisableGoogle2stepPost(enableDisable2Fa?: EnableDisable2Fa, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationDisableGoogle2stepPost(enableDisable2Fa, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationDisableGoogle2stepPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationEnableGoogle2stepPost(enableDisable2Fa?: EnableDisable2Fa, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationEnableGoogle2stepPost(enableDisable2Fa, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationEnableGoogle2stepPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationPackagePost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationPackagePost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationPackagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationProcessFaceImagePost(processFaceImageRequest?: ProcessFaceImageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationProcessFaceImagePost(processFaceImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationProcessFaceImagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationProcessPassportImagePost(processFaceImageRequest?: ProcessFaceImageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationProcessPassportImagePost(processFaceImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationProcessPassportImagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdatePublicKeyRequest} [updatePublicKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationPublicKeyPut(updatePublicKeyRequest?: UpdatePublicKeyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationPublicKeyPut(updatePublicKeyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationPublicKeyPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationRatesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationRatesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationRatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RestorePinPostRequest} [restorePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationRestorePinPost(restorePinPostRequest?: RestorePinPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationRestorePinPost(restorePinPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationRestorePinPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PinCodeRequestModel} [pinCodeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationSetpinPut(pinCodeRequestModel?: PinCodeRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationSetpinPut(pinCodeRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationSetpinPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SetPushTokenRequest} [setPushTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationSetpushtokenPost(setPushTokenRequest?: SetPushTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationSetpushtokenPost(setPushTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationSetpushtokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ValidatePinPostRequest} [validatePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationValidatePinPost(validatePinPostRequest?: ValidatePinPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationValidatePinPost(validatePinPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationValidatePinPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifyFaceData} [verifyFaceData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationVerifyfacesPost(verifyFaceData?: VerifyFaceData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationVerifyfacesPost(verifyFaceData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiApplicationVerifyfacesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationDisableGoogle2stepPost(version: string, enableDisable2Fa?: EnableDisable2Fa, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationDisableGoogle2stepPost(version, enableDisable2Fa, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationDisableGoogle2stepPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationEnableGoogle2stepPost(version: string, enableDisable2Fa?: EnableDisable2Fa, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationEnableGoogle2stepPost(version, enableDisable2Fa, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationEnableGoogle2stepPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationPackagePost(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationPackagePost(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationPackagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationProcessFaceImagePost(version: string, processFaceImageRequest?: ProcessFaceImageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationProcessFaceImagePost(version, processFaceImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationProcessFaceImagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationProcessPassportImagePost(version: string, processFaceImageRequest?: ProcessFaceImageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationProcessPassportImagePost(version, processFaceImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationProcessPassportImagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {UpdatePublicKeyRequest} [updatePublicKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationPublicKeyPut(version: string, updatePublicKeyRequest?: UpdatePublicKeyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationPublicKeyPut(version, updatePublicKeyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationPublicKeyPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationRatesGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationRatesGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationRatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {RestorePinPostRequest} [restorePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationRestorePinPost(version: string, restorePinPostRequest?: RestorePinPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationRestorePinPost(version, restorePinPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationRestorePinPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PinCodeRequestModel} [pinCodeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationSetpinPut(version: string, pinCodeRequestModel?: PinCodeRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationSetpinPut(version, pinCodeRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationSetpinPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {SetPushTokenRequest} [setPushTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationSetpushtokenPost(version: string, setPushTokenRequest?: SetPushTokenRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationSetpushtokenPost(version, setPushTokenRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationSetpushtokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {ValidatePinPostRequest} [validatePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationValidatePinPost(version: string, validatePinPostRequest?: ValidatePinPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationValidatePinPost(version, validatePinPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationValidatePinPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {VerifyFaceData} [verifyFaceData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionApplicationVerifyfacesPost(version: string, verifyFaceData?: VerifyFaceData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionApplicationVerifyfacesPost(version, verifyFaceData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ApplicationApi.apiVversionApplicationVerifyfacesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationApiFp(configuration)
    return {
        /**
         * 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationDisableGoogle2stepPost(enableDisable2Fa?: EnableDisable2Fa, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationDisableGoogle2stepPost(enableDisable2Fa, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationEnableGoogle2stepPost(enableDisable2Fa?: EnableDisable2Fa, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationEnableGoogle2stepPost(enableDisable2Fa, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationPackagePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationPackagePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationProcessFaceImagePost(processFaceImageRequest?: ProcessFaceImageRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiApplicationProcessFaceImagePost(processFaceImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationProcessPassportImagePost(processFaceImageRequest?: ProcessFaceImageRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiApplicationProcessPassportImagePost(processFaceImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePublicKeyRequest} [updatePublicKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationPublicKeyPut(updatePublicKeyRequest?: UpdatePublicKeyRequest, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiApplicationPublicKeyPut(updatePublicKeyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationRatesGet(options?: any): AxiosPromise<Array<Rate>> {
            return localVarFp.apiApplicationRatesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RestorePinPostRequest} [restorePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationRestorePinPost(restorePinPostRequest?: RestorePinPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationRestorePinPost(restorePinPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PinCodeRequestModel} [pinCodeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSetpinPut(pinCodeRequestModel?: PinCodeRequestModel, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiApplicationSetpinPut(pinCodeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetPushTokenRequest} [setPushTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationSetpushtokenPost(setPushTokenRequest?: SetPushTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationSetpushtokenPost(setPushTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidatePinPostRequest} [validatePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationValidatePinPost(validatePinPostRequest?: ValidatePinPostRequest, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiApplicationValidatePinPost(validatePinPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyFaceData} [verifyFaceData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationVerifyfacesPost(verifyFaceData?: VerifyFaceData, options?: any): AxiosPromise<VerifyResult> {
            return localVarFp.apiApplicationVerifyfacesPost(verifyFaceData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationDisableGoogle2stepPost(version: string, enableDisable2Fa?: EnableDisable2Fa, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionApplicationDisableGoogle2stepPost(version, enableDisable2Fa, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {EnableDisable2Fa} [enableDisable2Fa] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationEnableGoogle2stepPost(version: string, enableDisable2Fa?: EnableDisable2Fa, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionApplicationEnableGoogle2stepPost(version, enableDisable2Fa, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationPackagePost(version: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionApplicationPackagePost(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationProcessFaceImagePost(version: string, processFaceImageRequest?: ProcessFaceImageRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiVversionApplicationProcessFaceImagePost(version, processFaceImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationProcessPassportImagePost(version: string, processFaceImageRequest?: ProcessFaceImageRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiVversionApplicationProcessPassportImagePost(version, processFaceImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {UpdatePublicKeyRequest} [updatePublicKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationPublicKeyPut(version: string, updatePublicKeyRequest?: UpdatePublicKeyRequest, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionApplicationPublicKeyPut(version, updatePublicKeyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationRatesGet(version: string, options?: any): AxiosPromise<Array<Rate>> {
            return localVarFp.apiVversionApplicationRatesGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {RestorePinPostRequest} [restorePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationRestorePinPost(version: string, restorePinPostRequest?: RestorePinPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionApplicationRestorePinPost(version, restorePinPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PinCodeRequestModel} [pinCodeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationSetpinPut(version: string, pinCodeRequestModel?: PinCodeRequestModel, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionApplicationSetpinPut(version, pinCodeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {SetPushTokenRequest} [setPushTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationSetpushtokenPost(version: string, setPushTokenRequest?: SetPushTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionApplicationSetpushtokenPost(version, setPushTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {ValidatePinPostRequest} [validatePinPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationValidatePinPost(version: string, validatePinPostRequest?: ValidatePinPostRequest, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionApplicationValidatePinPost(version, validatePinPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {VerifyFaceData} [verifyFaceData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionApplicationVerifyfacesPost(version: string, verifyFaceData?: VerifyFaceData, options?: any): AxiosPromise<VerifyResult> {
            return localVarFp.apiVversionApplicationVerifyfacesPost(version, verifyFaceData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @param {EnableDisable2Fa} [enableDisable2Fa] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationDisableGoogle2stepPost(enableDisable2Fa?: EnableDisable2Fa, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationDisableGoogle2stepPost(enableDisable2Fa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EnableDisable2Fa} [enableDisable2Fa] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationEnableGoogle2stepPost(enableDisable2Fa?: EnableDisable2Fa, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationEnableGoogle2stepPost(enableDisable2Fa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationPackagePost(options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationPackagePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationProcessFaceImagePost(processFaceImageRequest?: ProcessFaceImageRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationProcessFaceImagePost(processFaceImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationProcessPassportImagePost(processFaceImageRequest?: ProcessFaceImageRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationProcessPassportImagePost(processFaceImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePublicKeyRequest} [updatePublicKeyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationPublicKeyPut(updatePublicKeyRequest?: UpdatePublicKeyRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationPublicKeyPut(updatePublicKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationRatesGet(options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationRatesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RestorePinPostRequest} [restorePinPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationRestorePinPost(restorePinPostRequest?: RestorePinPostRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationRestorePinPost(restorePinPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PinCodeRequestModel} [pinCodeRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationSetpinPut(pinCodeRequestModel?: PinCodeRequestModel, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationSetpinPut(pinCodeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetPushTokenRequest} [setPushTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationSetpushtokenPost(setPushTokenRequest?: SetPushTokenRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationSetpushtokenPost(setPushTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidatePinPostRequest} [validatePinPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationValidatePinPost(validatePinPostRequest?: ValidatePinPostRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationValidatePinPost(validatePinPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyFaceData} [verifyFaceData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiApplicationVerifyfacesPost(verifyFaceData?: VerifyFaceData, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiApplicationVerifyfacesPost(verifyFaceData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {EnableDisable2Fa} [enableDisable2Fa] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationDisableGoogle2stepPost(version: string, enableDisable2Fa?: EnableDisable2Fa, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationDisableGoogle2stepPost(version, enableDisable2Fa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {EnableDisable2Fa} [enableDisable2Fa] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationEnableGoogle2stepPost(version: string, enableDisable2Fa?: EnableDisable2Fa, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationEnableGoogle2stepPost(version, enableDisable2Fa, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationPackagePost(version: string, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationPackagePost(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationProcessFaceImagePost(version: string, processFaceImageRequest?: ProcessFaceImageRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationProcessFaceImagePost(version, processFaceImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {ProcessFaceImageRequest} [processFaceImageRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationProcessPassportImagePost(version: string, processFaceImageRequest?: ProcessFaceImageRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationProcessPassportImagePost(version, processFaceImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {UpdatePublicKeyRequest} [updatePublicKeyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationPublicKeyPut(version: string, updatePublicKeyRequest?: UpdatePublicKeyRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationPublicKeyPut(version, updatePublicKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationRatesGet(version: string, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationRatesGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {RestorePinPostRequest} [restorePinPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationRestorePinPost(version: string, restorePinPostRequest?: RestorePinPostRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationRestorePinPost(version, restorePinPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PinCodeRequestModel} [pinCodeRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationSetpinPut(version: string, pinCodeRequestModel?: PinCodeRequestModel, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationSetpinPut(version, pinCodeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {SetPushTokenRequest} [setPushTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationSetpushtokenPost(version: string, setPushTokenRequest?: SetPushTokenRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationSetpushtokenPost(version, setPushTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {ValidatePinPostRequest} [validatePinPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationValidatePinPost(version: string, validatePinPostRequest?: ValidatePinPostRequest, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationValidatePinPost(version, validatePinPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {VerifyFaceData} [verifyFaceData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public apiVversionApplicationVerifyfacesPost(version: string, verifyFaceData?: VerifyFaceData, options?: RawAxiosRequestConfig) {
        return ApplicationApiFp(this.configuration).apiVversionApplicationVerifyfacesPost(version, verifyFaceData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BalanceApi - axios parameter creator
 * @export
 */
export const BalanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceCurrencyGet: async (currency: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('apiBalanceCurrencyGet', 'currency', currency)
            const localVarPath = `/api/Balance/{currency}`
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceDepositCurrencyAddressGet: async (currency: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('apiBalanceDepositCurrencyAddressGet', 'currency', currency)
            const localVarPath = `/api/Balance/deposit/{currency}/address`
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currency 
         * @param {number} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceDepositCurrencyAddressPost: async (currency: string, networkId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('apiBalanceDepositCurrencyAddressPost', 'currency', currency)
            const localVarPath = `/api/Balance/deposit/{currency}/address`
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (networkId !== undefined) {
                localVarQueryParameter['networkId'] = networkId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalancePrewithdrawPost: async (postWithdrawRequest?: PostWithdrawRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Balance/prewithdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postWithdrawRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceWithdrawCurrencyPageGet: async (currency: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('apiBalanceWithdrawCurrencyPageGet', 'currency', currency)
            const localVarPath = `/api/Balance/withdraw/{currency}/page`
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceWithdrawPost: async (postWithdrawRequest?: PostWithdrawRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Balance/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postWithdrawRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [guardRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceWithdrawguardPost: async (guardRequestId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Balance/withdrawguard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (guardRequestId !== undefined) {
                localVarQueryParameter['guardRequestId'] = guardRequestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceWithdrawstatusPut: async (result?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Balance/withdrawstatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (result !== undefined) {
                localVarQueryParameter['result'] = result;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceCurrencyGet: async (currency: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('apiVversionBalanceCurrencyGet', 'currency', currency)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBalanceCurrencyGet', 'version', version)
            const localVarPath = `/api/v{version}/Balance/{currency}`
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceDepositCurrencyAddressGet: async (currency: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('apiVversionBalanceDepositCurrencyAddressGet', 'currency', currency)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBalanceDepositCurrencyAddressGet', 'version', version)
            const localVarPath = `/api/v{version}/Balance/deposit/{currency}/address`
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {number} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceDepositCurrencyAddressPost: async (currency: string, version: string, networkId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('apiVversionBalanceDepositCurrencyAddressPost', 'currency', currency)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBalanceDepositCurrencyAddressPost', 'version', version)
            const localVarPath = `/api/v{version}/Balance/deposit/{currency}/address`
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (networkId !== undefined) {
                localVarQueryParameter['networkId'] = networkId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBalanceGet', 'version', version)
            const localVarPath = `/api/v{version}/Balance`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalancePrewithdrawPost: async (version: string, postWithdrawRequest?: PostWithdrawRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBalancePrewithdrawPost', 'version', version)
            const localVarPath = `/api/v{version}/Balance/prewithdraw`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postWithdrawRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceWithdrawCurrencyPageGet: async (currency: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('apiVversionBalanceWithdrawCurrencyPageGet', 'currency', currency)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBalanceWithdrawCurrencyPageGet', 'version', version)
            const localVarPath = `/api/v{version}/Balance/withdraw/{currency}/page`
                .replace(`{${"currency"}}`, encodeURIComponent(String(currency)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceWithdrawPost: async (version: string, postWithdrawRequest?: PostWithdrawRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBalanceWithdrawPost', 'version', version)
            const localVarPath = `/api/v{version}/Balance/withdraw`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postWithdrawRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [guardRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceWithdrawguardPost: async (version: string, guardRequestId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBalanceWithdrawguardPost', 'version', version)
            const localVarPath = `/api/v{version}/Balance/withdrawguard`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (guardRequestId !== undefined) {
                localVarQueryParameter['guardRequestId'] = guardRequestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {boolean} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceWithdrawstatusPut: async (version: string, result?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionBalanceWithdrawstatusPut', 'version', version)
            const localVarPath = `/api/v{version}/Balance/withdrawstatus`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (result !== undefined) {
                localVarQueryParameter['result'] = result;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceApi - functional programming interface
 * @export
 */
export const BalanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BalanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBalanceCurrencyGet(currency: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBalanceRequestModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBalanceCurrencyGet(currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiBalanceCurrencyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBalanceDepositCurrencyAddressGet(currency: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBalanceDepositCurrencyAddressGet(currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiBalanceDepositCurrencyAddressGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} currency 
         * @param {number} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBalanceDepositCurrencyAddressPost(currency: string, networkId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateAddressResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBalanceDepositCurrencyAddressPost(currency, networkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiBalanceDepositCurrencyAddressPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBalanceGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBalancesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBalanceGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiBalanceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBalancePrewithdrawPost(postWithdrawRequest?: PostWithdrawRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostWithdrawGuardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBalancePrewithdrawPost(postWithdrawRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiBalancePrewithdrawPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBalanceWithdrawCurrencyPageGet(currency: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWithdrawResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBalanceWithdrawCurrencyPageGet(currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiBalanceWithdrawCurrencyPageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBalanceWithdrawPost(postWithdrawRequest?: PostWithdrawRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostWithdrawRequestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBalanceWithdrawPost(postWithdrawRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiBalanceWithdrawPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [guardRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBalanceWithdrawguardPost(guardRequestId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostWithdrawGuardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBalanceWithdrawguardPost(guardRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiBalanceWithdrawguardPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBalanceWithdrawstatusPut(result?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBalanceWithdrawstatusPut(result, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiBalanceWithdrawstatusPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBalanceCurrencyGet(currency: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBalanceRequestModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBalanceCurrencyGet(currency, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiVversionBalanceCurrencyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBalanceDepositCurrencyAddressGet(currency: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAddressResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBalanceDepositCurrencyAddressGet(currency, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiVversionBalanceDepositCurrencyAddressGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {number} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBalanceDepositCurrencyAddressPost(currency: string, version: string, networkId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateAddressResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBalanceDepositCurrencyAddressPost(currency, version, networkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiVversionBalanceDepositCurrencyAddressPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBalanceGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBalancesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBalanceGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiVversionBalanceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBalancePrewithdrawPost(version: string, postWithdrawRequest?: PostWithdrawRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostWithdrawGuardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBalancePrewithdrawPost(version, postWithdrawRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiVversionBalancePrewithdrawPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBalanceWithdrawCurrencyPageGet(currency: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWithdrawResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBalanceWithdrawCurrencyPageGet(currency, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiVversionBalanceWithdrawCurrencyPageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBalanceWithdrawPost(version: string, postWithdrawRequest?: PostWithdrawRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostWithdrawRequestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBalanceWithdrawPost(version, postWithdrawRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiVversionBalanceWithdrawPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [guardRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBalanceWithdrawguardPost(version: string, guardRequestId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostWithdrawGuardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBalanceWithdrawguardPost(version, guardRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiVversionBalanceWithdrawguardPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {boolean} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionBalanceWithdrawstatusPut(version: string, result?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionBalanceWithdrawstatusPut(version, result, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.apiVversionBalanceWithdrawstatusPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BalanceApi - factory interface
 * @export
 */
export const BalanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BalanceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceCurrencyGet(currency: string, options?: any): AxiosPromise<GetBalanceRequestModel> {
            return localVarFp.apiBalanceCurrencyGet(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceDepositCurrencyAddressGet(currency: string, options?: any): AxiosPromise<GetAddressResponse> {
            return localVarFp.apiBalanceDepositCurrencyAddressGet(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currency 
         * @param {number} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceDepositCurrencyAddressPost(currency: string, networkId?: number, options?: any): AxiosPromise<GenerateAddressResult> {
            return localVarFp.apiBalanceDepositCurrencyAddressPost(currency, networkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceGet(options?: any): AxiosPromise<GetBalancesListResponse> {
            return localVarFp.apiBalanceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalancePrewithdrawPost(postWithdrawRequest?: PostWithdrawRequest, options?: any): AxiosPromise<PostWithdrawGuardResponse> {
            return localVarFp.apiBalancePrewithdrawPost(postWithdrawRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currency 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceWithdrawCurrencyPageGet(currency: string, options?: any): AxiosPromise<GetWithdrawResponse> {
            return localVarFp.apiBalanceWithdrawCurrencyPageGet(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceWithdrawPost(postWithdrawRequest?: PostWithdrawRequest, options?: any): AxiosPromise<PostWithdrawRequestResult> {
            return localVarFp.apiBalanceWithdrawPost(postWithdrawRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [guardRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceWithdrawguardPost(guardRequestId?: string, options?: any): AxiosPromise<PostWithdrawGuardResponse> {
            return localVarFp.apiBalanceWithdrawguardPost(guardRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBalanceWithdrawstatusPut(result?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiBalanceWithdrawstatusPut(result, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceCurrencyGet(currency: string, version: string, options?: any): AxiosPromise<GetBalanceRequestModel> {
            return localVarFp.apiVversionBalanceCurrencyGet(currency, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceDepositCurrencyAddressGet(currency: string, version: string, options?: any): AxiosPromise<GetAddressResponse> {
            return localVarFp.apiVversionBalanceDepositCurrencyAddressGet(currency, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {number} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceDepositCurrencyAddressPost(currency: string, version: string, networkId?: number, options?: any): AxiosPromise<GenerateAddressResult> {
            return localVarFp.apiVversionBalanceDepositCurrencyAddressPost(currency, version, networkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceGet(version: string, options?: any): AxiosPromise<GetBalancesListResponse> {
            return localVarFp.apiVversionBalanceGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalancePrewithdrawPost(version: string, postWithdrawRequest?: PostWithdrawRequest, options?: any): AxiosPromise<PostWithdrawGuardResponse> {
            return localVarFp.apiVversionBalancePrewithdrawPost(version, postWithdrawRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} currency 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceWithdrawCurrencyPageGet(currency: string, version: string, options?: any): AxiosPromise<GetWithdrawResponse> {
            return localVarFp.apiVversionBalanceWithdrawCurrencyPageGet(currency, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PostWithdrawRequest} [postWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceWithdrawPost(version: string, postWithdrawRequest?: PostWithdrawRequest, options?: any): AxiosPromise<PostWithdrawRequestResult> {
            return localVarFp.apiVversionBalanceWithdrawPost(version, postWithdrawRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [guardRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceWithdrawguardPost(version: string, guardRequestId?: string, options?: any): AxiosPromise<PostWithdrawGuardResponse> {
            return localVarFp.apiVversionBalanceWithdrawguardPost(version, guardRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {boolean} [result] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionBalanceWithdrawstatusPut(version: string, result?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionBalanceWithdrawstatusPut(version, result, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BalanceApi - object-oriented interface
 * @export
 * @class BalanceApi
 * @extends {BaseAPI}
 */
export class BalanceApi extends BaseAPI {
    /**
     * 
     * @param {string} currency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiBalanceCurrencyGet(currency: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiBalanceCurrencyGet(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiBalanceDepositCurrencyAddressGet(currency: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiBalanceDepositCurrencyAddressGet(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currency 
     * @param {number} [networkId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiBalanceDepositCurrencyAddressPost(currency: string, networkId?: number, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiBalanceDepositCurrencyAddressPost(currency, networkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiBalanceGet(options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiBalanceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostWithdrawRequest} [postWithdrawRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiBalancePrewithdrawPost(postWithdrawRequest?: PostWithdrawRequest, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiBalancePrewithdrawPost(postWithdrawRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currency 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiBalanceWithdrawCurrencyPageGet(currency: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiBalanceWithdrawCurrencyPageGet(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostWithdrawRequest} [postWithdrawRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiBalanceWithdrawPost(postWithdrawRequest?: PostWithdrawRequest, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiBalanceWithdrawPost(postWithdrawRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [guardRequestId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiBalanceWithdrawguardPost(guardRequestId?: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiBalanceWithdrawguardPost(guardRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [result] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiBalanceWithdrawstatusPut(result?: boolean, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiBalanceWithdrawstatusPut(result, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currency 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiVversionBalanceCurrencyGet(currency: string, version: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiVversionBalanceCurrencyGet(currency, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currency 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiVversionBalanceDepositCurrencyAddressGet(currency: string, version: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiVversionBalanceDepositCurrencyAddressGet(currency, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currency 
     * @param {string} version 
     * @param {number} [networkId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiVversionBalanceDepositCurrencyAddressPost(currency: string, version: string, networkId?: number, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiVversionBalanceDepositCurrencyAddressPost(currency, version, networkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiVversionBalanceGet(version: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiVversionBalanceGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PostWithdrawRequest} [postWithdrawRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiVversionBalancePrewithdrawPost(version: string, postWithdrawRequest?: PostWithdrawRequest, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiVversionBalancePrewithdrawPost(version, postWithdrawRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} currency 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiVversionBalanceWithdrawCurrencyPageGet(currency: string, version: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiVversionBalanceWithdrawCurrencyPageGet(currency, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PostWithdrawRequest} [postWithdrawRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiVversionBalanceWithdrawPost(version: string, postWithdrawRequest?: PostWithdrawRequest, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiVversionBalanceWithdrawPost(version, postWithdrawRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [guardRequestId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiVversionBalanceWithdrawguardPost(version: string, guardRequestId?: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiVversionBalanceWithdrawguardPost(version, guardRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {boolean} [result] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public apiVversionBalanceWithdrawstatusPut(version: string, result?: boolean, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).apiVversionBalanceWithdrawstatusPut(version, result, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GatewayApi - axios parameter creator
 * @export
 */
export const GatewayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayCurrenciesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Gateway/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayMerchantGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Gateway/merchant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateMerchantRequest} [createMerchantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayMerchantPost: async (createMerchantRequest?: CreateMerchantRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Gateway/merchant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMerchantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayMerchantinformationGet: async (merchantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Gateway/merchantinformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayStatusGet: async (transactionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Gateway/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayTransactionGet: async (transactionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Gateway/transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTransactionRequest} [createTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayTransactionPost: async (createTransactionRequest?: CreateTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Gateway/transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [withdrawRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayWithdrawGet: async (withdrawRequestId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Gateway/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (withdrawRequestId !== undefined) {
                localVarQueryParameter['withdrawRequestId'] = withdrawRequestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateWithdrawRequest} [createWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayWithdrawPost: async (createWithdrawRequest?: CreateWithdrawRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Gateway/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWithdrawRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayCurrenciesGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGatewayCurrenciesGet', 'version', version)
            const localVarPath = `/api/v{version}/Gateway/currencies`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayMerchantGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGatewayMerchantGet', 'version', version)
            const localVarPath = `/api/v{version}/Gateway/merchant`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateMerchantRequest} [createMerchantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayMerchantPost: async (version: string, createMerchantRequest?: CreateMerchantRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGatewayMerchantPost', 'version', version)
            const localVarPath = `/api/v{version}/Gateway/merchant`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMerchantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayMerchantinformationGet: async (version: string, merchantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGatewayMerchantinformationGet', 'version', version)
            const localVarPath = `/api/v{version}/Gateway/merchantinformation`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (merchantId !== undefined) {
                localVarQueryParameter['merchantId'] = merchantId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayStatusGet: async (version: string, transactionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGatewayStatusGet', 'version', version)
            const localVarPath = `/api/v{version}/Gateway/status`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayTransactionGet: async (version: string, transactionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGatewayTransactionGet', 'version', version)
            const localVarPath = `/api/v{version}/Gateway/transaction`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateTransactionRequest} [createTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayTransactionPost: async (version: string, createTransactionRequest?: CreateTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGatewayTransactionPost', 'version', version)
            const localVarPath = `/api/v{version}/Gateway/transaction`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTransactionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [withdrawRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayWithdrawGet: async (version: string, withdrawRequestId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGatewayWithdrawGet', 'version', version)
            const localVarPath = `/api/v{version}/Gateway/withdraw`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (withdrawRequestId !== undefined) {
                localVarQueryParameter['withdrawRequestId'] = withdrawRequestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateWithdrawRequest} [createWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayWithdrawPost: async (version: string, createWithdrawRequest?: CreateWithdrawRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGatewayWithdrawPost', 'version', version)
            const localVarPath = `/api/v{version}/Gateway/withdraw`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWithdrawRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayApi - functional programming interface
 * @export
 */
export const GatewayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GatewayApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGatewayCurrenciesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrencyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGatewayCurrenciesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiGatewayCurrenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGatewayMerchantGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Merchant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGatewayMerchantGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiGatewayMerchantGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateMerchantRequest} [createMerchantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGatewayMerchantPost(createMerchantRequest?: CreateMerchantRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Merchant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGatewayMerchantPost(createMerchantRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiGatewayMerchantPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGatewayMerchantinformationGet(merchantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGatewayMerchantinformationGet(merchantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiGatewayMerchantinformationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGatewayStatusGet(transactionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTransactionStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGatewayStatusGet(transactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiGatewayStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGatewayTransactionGet(transactionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaymentPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGatewayTransactionGet(transactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiGatewayTransactionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateTransactionRequest} [createTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGatewayTransactionPost(createTransactionRequest?: CreateTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTransactionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGatewayTransactionPost(createTransactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiGatewayTransactionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [withdrawRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGatewayWithdrawGet(withdrawRequestId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantWithdrawStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGatewayWithdrawGet(withdrawRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiGatewayWithdrawGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateWithdrawRequest} [createWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGatewayWithdrawPost(createWithdrawRequest?: CreateWithdrawRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawRequestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGatewayWithdrawPost(createWithdrawRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiGatewayWithdrawPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGatewayCurrenciesGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrencyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGatewayCurrenciesGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiVversionGatewayCurrenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGatewayMerchantGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Merchant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGatewayMerchantGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiVversionGatewayMerchantGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateMerchantRequest} [createMerchantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGatewayMerchantPost(version: string, createMerchantRequest?: CreateMerchantRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Merchant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGatewayMerchantPost(version, createMerchantRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiVversionGatewayMerchantPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGatewayMerchantinformationGet(version: string, merchantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGatewayMerchantinformationGet(version, merchantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiVversionGatewayMerchantinformationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGatewayStatusGet(version: string, transactionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiTransactionStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGatewayStatusGet(version, transactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiVversionGatewayStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGatewayTransactionGet(version: string, transactionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPaymentPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGatewayTransactionGet(version, transactionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiVversionGatewayTransactionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateTransactionRequest} [createTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGatewayTransactionPost(version: string, createTransactionRequest?: CreateTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateTransactionResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGatewayTransactionPost(version, createTransactionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiVversionGatewayTransactionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [withdrawRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGatewayWithdrawGet(version: string, withdrawRequestId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MerchantWithdrawStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGatewayWithdrawGet(version, withdrawRequestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiVversionGatewayWithdrawGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateWithdrawRequest} [createWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGatewayWithdrawPost(version: string, createWithdrawRequest?: CreateWithdrawRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawRequestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGatewayWithdrawPost(version, createWithdrawRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GatewayApi.apiVversionGatewayWithdrawPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GatewayApi - factory interface
 * @export
 */
export const GatewayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GatewayApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayCurrenciesGet(options?: any): AxiosPromise<Array<CurrencyResponse>> {
            return localVarFp.apiGatewayCurrenciesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayMerchantGet(options?: any): AxiosPromise<Array<Merchant>> {
            return localVarFp.apiGatewayMerchantGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateMerchantRequest} [createMerchantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayMerchantPost(createMerchantRequest?: CreateMerchantRequest, options?: any): AxiosPromise<Merchant> {
            return localVarFp.apiGatewayMerchantPost(createMerchantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayMerchantinformationGet(merchantId?: string, options?: any): AxiosPromise<MerchantInformation> {
            return localVarFp.apiGatewayMerchantinformationGet(merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayStatusGet(transactionId?: string, options?: any): AxiosPromise<ApiTransactionStatusResponse> {
            return localVarFp.apiGatewayStatusGet(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayTransactionGet(transactionId?: string, options?: any): AxiosPromise<ApiPaymentPage> {
            return localVarFp.apiGatewayTransactionGet(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateTransactionRequest} [createTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayTransactionPost(createTransactionRequest?: CreateTransactionRequest, options?: any): AxiosPromise<CreateTransactionResult> {
            return localVarFp.apiGatewayTransactionPost(createTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [withdrawRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayWithdrawGet(withdrawRequestId?: string, options?: any): AxiosPromise<MerchantWithdrawStatusResponse> {
            return localVarFp.apiGatewayWithdrawGet(withdrawRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateWithdrawRequest} [createWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGatewayWithdrawPost(createWithdrawRequest?: CreateWithdrawRequest, options?: any): AxiosPromise<WithdrawRequestResult> {
            return localVarFp.apiGatewayWithdrawPost(createWithdrawRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayCurrenciesGet(version: string, options?: any): AxiosPromise<Array<CurrencyResponse>> {
            return localVarFp.apiVversionGatewayCurrenciesGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayMerchantGet(version: string, options?: any): AxiosPromise<Array<Merchant>> {
            return localVarFp.apiVversionGatewayMerchantGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateMerchantRequest} [createMerchantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayMerchantPost(version: string, createMerchantRequest?: CreateMerchantRequest, options?: any): AxiosPromise<Merchant> {
            return localVarFp.apiVversionGatewayMerchantPost(version, createMerchantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [merchantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayMerchantinformationGet(version: string, merchantId?: string, options?: any): AxiosPromise<MerchantInformation> {
            return localVarFp.apiVversionGatewayMerchantinformationGet(version, merchantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayStatusGet(version: string, transactionId?: string, options?: any): AxiosPromise<ApiTransactionStatusResponse> {
            return localVarFp.apiVversionGatewayStatusGet(version, transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [transactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayTransactionGet(version: string, transactionId?: string, options?: any): AxiosPromise<ApiPaymentPage> {
            return localVarFp.apiVversionGatewayTransactionGet(version, transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateTransactionRequest} [createTransactionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayTransactionPost(version: string, createTransactionRequest?: CreateTransactionRequest, options?: any): AxiosPromise<CreateTransactionResult> {
            return localVarFp.apiVversionGatewayTransactionPost(version, createTransactionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [withdrawRequestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayWithdrawGet(version: string, withdrawRequestId?: string, options?: any): AxiosPromise<MerchantWithdrawStatusResponse> {
            return localVarFp.apiVversionGatewayWithdrawGet(version, withdrawRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateWithdrawRequest} [createWithdrawRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGatewayWithdrawPost(version: string, createWithdrawRequest?: CreateWithdrawRequest, options?: any): AxiosPromise<WithdrawRequestResult> {
            return localVarFp.apiVversionGatewayWithdrawPost(version, createWithdrawRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GatewayApi - object-oriented interface
 * @export
 * @class GatewayApi
 * @extends {BaseAPI}
 */
export class GatewayApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiGatewayCurrenciesGet(options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiGatewayCurrenciesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiGatewayMerchantGet(options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiGatewayMerchantGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateMerchantRequest} [createMerchantRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiGatewayMerchantPost(createMerchantRequest?: CreateMerchantRequest, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiGatewayMerchantPost(createMerchantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [merchantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiGatewayMerchantinformationGet(merchantId?: string, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiGatewayMerchantinformationGet(merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [transactionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiGatewayStatusGet(transactionId?: string, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiGatewayStatusGet(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [transactionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiGatewayTransactionGet(transactionId?: string, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiGatewayTransactionGet(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateTransactionRequest} [createTransactionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiGatewayTransactionPost(createTransactionRequest?: CreateTransactionRequest, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiGatewayTransactionPost(createTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [withdrawRequestId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiGatewayWithdrawGet(withdrawRequestId?: string, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiGatewayWithdrawGet(withdrawRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateWithdrawRequest} [createWithdrawRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiGatewayWithdrawPost(createWithdrawRequest?: CreateWithdrawRequest, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiGatewayWithdrawPost(createWithdrawRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiVversionGatewayCurrenciesGet(version: string, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiVversionGatewayCurrenciesGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiVversionGatewayMerchantGet(version: string, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiVversionGatewayMerchantGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {CreateMerchantRequest} [createMerchantRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiVversionGatewayMerchantPost(version: string, createMerchantRequest?: CreateMerchantRequest, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiVversionGatewayMerchantPost(version, createMerchantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [merchantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiVversionGatewayMerchantinformationGet(version: string, merchantId?: string, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiVversionGatewayMerchantinformationGet(version, merchantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [transactionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiVversionGatewayStatusGet(version: string, transactionId?: string, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiVversionGatewayStatusGet(version, transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [transactionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiVversionGatewayTransactionGet(version: string, transactionId?: string, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiVversionGatewayTransactionGet(version, transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {CreateTransactionRequest} [createTransactionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiVversionGatewayTransactionPost(version: string, createTransactionRequest?: CreateTransactionRequest, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiVversionGatewayTransactionPost(version, createTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [withdrawRequestId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiVversionGatewayWithdrawGet(version: string, withdrawRequestId?: string, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiVversionGatewayWithdrawGet(version, withdrawRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {CreateWithdrawRequest} [createWithdrawRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayApi
     */
    public apiVversionGatewayWithdrawPost(version: string, createWithdrawRequest?: CreateWithdrawRequest, options?: RawAxiosRequestConfig) {
        return GatewayApiFp(this.configuration).apiVversionGatewayWithdrawPost(version, createWithdrawRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GuardApi - axios parameter creator
 * @export
 */
export const GuardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardGetAccessTokensGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Guard/GetAccessTokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardGetlastGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Guard/getlast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardPrecheckLogoutPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Guard/PrecheckLogout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [encryptedId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardRequestGet: async (encryptedId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Guard/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (encryptedId !== undefined) {
                localVarQueryParameter['encryptedId'] = encryptedId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GuardActionType} [guardActionType] 
         * @param {CreateRequestPayload} [createRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardRequestPost: async (guardActionType?: GuardActionType, createRequestPayload?: CreateRequestPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Guard/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (guardActionType !== undefined) {
                localVarQueryParameter['guardActionType'] = guardActionType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestSettingsTokenResponseRequestModel} [requestSettingsTokenResponseRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardRequestSettingsTokenPost: async (requestSettingsTokenResponseRequestModel?: RequestSettingsTokenResponseRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Guard/RequestSettingsToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestSettingsTokenResponseRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RevokeTokenByIdRequestModel} [revokeTokenByIdRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardRevokeTokenByIdPost: async (revokeTokenByIdRequestModel?: RevokeTokenByIdRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Guard/RevokeTokenById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeTokenByIdRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardRevokeTokenPost: async (body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Guard/RevokeToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardSubmitPost: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Guard/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {GuardRequestStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardUpdatePost: async (id?: string, status?: GuardRequestStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Guard/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardGetAccessTokensGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGuardGetAccessTokensGet', 'version', version)
            const localVarPath = `/api/v{version}/Guard/GetAccessTokens`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardGetlastGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGuardGetlastGet', 'version', version)
            const localVarPath = `/api/v{version}/Guard/getlast`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardPrecheckLogoutPost: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGuardPrecheckLogoutPost', 'version', version)
            const localVarPath = `/api/v{version}/Guard/PrecheckLogout`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [encryptedId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardRequestGet: async (version: string, encryptedId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGuardRequestGet', 'version', version)
            const localVarPath = `/api/v{version}/Guard/request`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (encryptedId !== undefined) {
                localVarQueryParameter['encryptedId'] = encryptedId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {GuardActionType} [guardActionType] 
         * @param {CreateRequestPayload} [createRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardRequestPost: async (version: string, guardActionType?: GuardActionType, createRequestPayload?: CreateRequestPayload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGuardRequestPost', 'version', version)
            const localVarPath = `/api/v{version}/Guard/request`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (guardActionType !== undefined) {
                localVarQueryParameter['guardActionType'] = guardActionType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRequestPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {RequestSettingsTokenResponseRequestModel} [requestSettingsTokenResponseRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardRequestSettingsTokenPost: async (version: string, requestSettingsTokenResponseRequestModel?: RequestSettingsTokenResponseRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGuardRequestSettingsTokenPost', 'version', version)
            const localVarPath = `/api/v{version}/Guard/RequestSettingsToken`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestSettingsTokenResponseRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {RevokeTokenByIdRequestModel} [revokeTokenByIdRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardRevokeTokenByIdPost: async (version: string, revokeTokenByIdRequestModel?: RevokeTokenByIdRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGuardRevokeTokenByIdPost', 'version', version)
            const localVarPath = `/api/v{version}/Guard/RevokeTokenById`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(revokeTokenByIdRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardRevokeTokenPost: async (version: string, body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGuardRevokeTokenPost', 'version', version)
            const localVarPath = `/api/v{version}/Guard/RevokeToken`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardSubmitPost: async (version: string, id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGuardSubmitPost', 'version', version)
            const localVarPath = `/api/v{version}/Guard/submit`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [id] 
         * @param {GuardRequestStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardUpdatePost: async (version: string, id?: string, status?: GuardRequestStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionGuardUpdatePost', 'version', version)
            const localVarPath = `/api/v{version}/Guard/update`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuardApi - functional programming interface
 * @export
 */
export const GuardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGuardGetAccessTokensGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAccessTokensResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuardGetAccessTokensGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiGuardGetAccessTokensGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGuardGetlastGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuardRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuardGetlastGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiGuardGetlastGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGuardPrecheckLogoutPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrecheckLogoutResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuardPrecheckLogoutPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiGuardPrecheckLogoutPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [encryptedId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGuardRequestGet(encryptedId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuardRequestDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuardRequestGet(encryptedId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiGuardRequestGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GuardActionType} [guardActionType] 
         * @param {CreateRequestPayload} [createRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGuardRequestPost(guardActionType?: GuardActionType, createRequestPayload?: CreateRequestPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuardRequestPost(guardActionType, createRequestPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiGuardRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RequestSettingsTokenResponseRequestModel} [requestSettingsTokenResponseRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGuardRequestSettingsTokenPost(requestSettingsTokenResponseRequestModel?: RequestSettingsTokenResponseRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestSettingsTokenResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuardRequestSettingsTokenPost(requestSettingsTokenResponseRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiGuardRequestSettingsTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RevokeTokenByIdRequestModel} [revokeTokenByIdRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGuardRevokeTokenByIdPost(revokeTokenByIdRequestModel?: RevokeTokenByIdRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuardRevokeTokenByIdPost(revokeTokenByIdRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiGuardRevokeTokenByIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGuardRevokeTokenPost(body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuardRevokeTokenPost(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiGuardRevokeTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGuardSubmitPost(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuardSubmitResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuardSubmitPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiGuardSubmitPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {GuardRequestStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGuardUpdatePost(id?: string, status?: GuardRequestStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGuardUpdatePost(id, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiGuardUpdatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGuardGetAccessTokensGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetAccessTokensResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGuardGetAccessTokensGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiVversionGuardGetAccessTokensGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGuardGetlastGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuardRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGuardGetlastGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiVversionGuardGetlastGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGuardPrecheckLogoutPost(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrecheckLogoutResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGuardPrecheckLogoutPost(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiVversionGuardPrecheckLogoutPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [encryptedId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGuardRequestGet(version: string, encryptedId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGuardRequestDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGuardRequestGet(version, encryptedId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiVversionGuardRequestGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {GuardActionType} [guardActionType] 
         * @param {CreateRequestPayload} [createRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGuardRequestPost(version: string, guardActionType?: GuardActionType, createRequestPayload?: CreateRequestPayload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGuardRequestPost(version, guardActionType, createRequestPayload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiVversionGuardRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {RequestSettingsTokenResponseRequestModel} [requestSettingsTokenResponseRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGuardRequestSettingsTokenPost(version: string, requestSettingsTokenResponseRequestModel?: RequestSettingsTokenResponseRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestSettingsTokenResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGuardRequestSettingsTokenPost(version, requestSettingsTokenResponseRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiVversionGuardRequestSettingsTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {RevokeTokenByIdRequestModel} [revokeTokenByIdRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGuardRevokeTokenByIdPost(version: string, revokeTokenByIdRequestModel?: RevokeTokenByIdRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGuardRevokeTokenByIdPost(version, revokeTokenByIdRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiVversionGuardRevokeTokenByIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGuardRevokeTokenPost(version: string, body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGuardRevokeTokenPost(version, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiVversionGuardRevokeTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGuardSubmitPost(version: string, id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuardSubmitResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGuardSubmitPost(version, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiVversionGuardSubmitPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [id] 
         * @param {GuardRequestStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionGuardUpdatePost(version: string, id?: string, status?: GuardRequestStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionGuardUpdatePost(version, id, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GuardApi.apiVversionGuardUpdatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GuardApi - factory interface
 * @export
 */
export const GuardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuardApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardGetAccessTokensGet(options?: any): AxiosPromise<Array<GetAccessTokensResponse>> {
            return localVarFp.apiGuardGetAccessTokensGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardGetlastGet(options?: any): AxiosPromise<GuardRequest> {
            return localVarFp.apiGuardGetlastGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardPrecheckLogoutPost(options?: any): AxiosPromise<PrecheckLogoutResult> {
            return localVarFp.apiGuardPrecheckLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [encryptedId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardRequestGet(encryptedId?: string, options?: any): AxiosPromise<GetGuardRequestDataResponse> {
            return localVarFp.apiGuardRequestGet(encryptedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GuardActionType} [guardActionType] 
         * @param {CreateRequestPayload} [createRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardRequestPost(guardActionType?: GuardActionType, createRequestPayload?: CreateRequestPayload, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiGuardRequestPost(guardActionType, createRequestPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RequestSettingsTokenResponseRequestModel} [requestSettingsTokenResponseRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardRequestSettingsTokenPost(requestSettingsTokenResponseRequestModel?: RequestSettingsTokenResponseRequestModel, options?: any): AxiosPromise<RequestSettingsTokenResponseModel> {
            return localVarFp.apiGuardRequestSettingsTokenPost(requestSettingsTokenResponseRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RevokeTokenByIdRequestModel} [revokeTokenByIdRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardRevokeTokenByIdPost(revokeTokenByIdRequestModel?: RevokeTokenByIdRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiGuardRevokeTokenByIdPost(revokeTokenByIdRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardRevokeTokenPost(body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiGuardRevokeTokenPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardSubmitPost(id?: string, options?: any): AxiosPromise<GuardSubmitResult> {
            return localVarFp.apiGuardSubmitPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {GuardRequestStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGuardUpdatePost(id?: string, status?: GuardRequestStatus, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiGuardUpdatePost(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardGetAccessTokensGet(version: string, options?: any): AxiosPromise<Array<GetAccessTokensResponse>> {
            return localVarFp.apiVversionGuardGetAccessTokensGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardGetlastGet(version: string, options?: any): AxiosPromise<GuardRequest> {
            return localVarFp.apiVversionGuardGetlastGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardPrecheckLogoutPost(version: string, options?: any): AxiosPromise<PrecheckLogoutResult> {
            return localVarFp.apiVversionGuardPrecheckLogoutPost(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [encryptedId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardRequestGet(version: string, encryptedId?: string, options?: any): AxiosPromise<GetGuardRequestDataResponse> {
            return localVarFp.apiVversionGuardRequestGet(version, encryptedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {GuardActionType} [guardActionType] 
         * @param {CreateRequestPayload} [createRequestPayload] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardRequestPost(version: string, guardActionType?: GuardActionType, createRequestPayload?: CreateRequestPayload, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionGuardRequestPost(version, guardActionType, createRequestPayload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {RequestSettingsTokenResponseRequestModel} [requestSettingsTokenResponseRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardRequestSettingsTokenPost(version: string, requestSettingsTokenResponseRequestModel?: RequestSettingsTokenResponseRequestModel, options?: any): AxiosPromise<RequestSettingsTokenResponseModel> {
            return localVarFp.apiVversionGuardRequestSettingsTokenPost(version, requestSettingsTokenResponseRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {RevokeTokenByIdRequestModel} [revokeTokenByIdRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardRevokeTokenByIdPost(version: string, revokeTokenByIdRequestModel?: RevokeTokenByIdRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionGuardRevokeTokenByIdPost(version, revokeTokenByIdRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardRevokeTokenPost(version: string, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionGuardRevokeTokenPost(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardSubmitPost(version: string, id?: string, options?: any): AxiosPromise<GuardSubmitResult> {
            return localVarFp.apiVversionGuardSubmitPost(version, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [id] 
         * @param {GuardRequestStatus} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionGuardUpdatePost(version: string, id?: string, status?: GuardRequestStatus, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionGuardUpdatePost(version, id, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuardApi - object-oriented interface
 * @export
 * @class GuardApi
 * @extends {BaseAPI}
 */
export class GuardApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiGuardGetAccessTokensGet(options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiGuardGetAccessTokensGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiGuardGetlastGet(options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiGuardGetlastGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiGuardPrecheckLogoutPost(options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiGuardPrecheckLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [encryptedId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiGuardRequestGet(encryptedId?: string, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiGuardRequestGet(encryptedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GuardActionType} [guardActionType] 
     * @param {CreateRequestPayload} [createRequestPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiGuardRequestPost(guardActionType?: GuardActionType, createRequestPayload?: CreateRequestPayload, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiGuardRequestPost(guardActionType, createRequestPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RequestSettingsTokenResponseRequestModel} [requestSettingsTokenResponseRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiGuardRequestSettingsTokenPost(requestSettingsTokenResponseRequestModel?: RequestSettingsTokenResponseRequestModel, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiGuardRequestSettingsTokenPost(requestSettingsTokenResponseRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RevokeTokenByIdRequestModel} [revokeTokenByIdRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiGuardRevokeTokenByIdPost(revokeTokenByIdRequestModel?: RevokeTokenByIdRequestModel, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiGuardRevokeTokenByIdPost(revokeTokenByIdRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiGuardRevokeTokenPost(body?: string, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiGuardRevokeTokenPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiGuardSubmitPost(id?: string, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiGuardSubmitPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {GuardRequestStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiGuardUpdatePost(id?: string, status?: GuardRequestStatus, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiGuardUpdatePost(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiVversionGuardGetAccessTokensGet(version: string, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiVversionGuardGetAccessTokensGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiVversionGuardGetlastGet(version: string, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiVversionGuardGetlastGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiVversionGuardPrecheckLogoutPost(version: string, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiVversionGuardPrecheckLogoutPost(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [encryptedId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiVversionGuardRequestGet(version: string, encryptedId?: string, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiVversionGuardRequestGet(version, encryptedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {GuardActionType} [guardActionType] 
     * @param {CreateRequestPayload} [createRequestPayload] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiVversionGuardRequestPost(version: string, guardActionType?: GuardActionType, createRequestPayload?: CreateRequestPayload, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiVversionGuardRequestPost(version, guardActionType, createRequestPayload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {RequestSettingsTokenResponseRequestModel} [requestSettingsTokenResponseRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiVversionGuardRequestSettingsTokenPost(version: string, requestSettingsTokenResponseRequestModel?: RequestSettingsTokenResponseRequestModel, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiVversionGuardRequestSettingsTokenPost(version, requestSettingsTokenResponseRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {RevokeTokenByIdRequestModel} [revokeTokenByIdRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiVversionGuardRevokeTokenByIdPost(version: string, revokeTokenByIdRequestModel?: RevokeTokenByIdRequestModel, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiVversionGuardRevokeTokenByIdPost(version, revokeTokenByIdRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiVversionGuardRevokeTokenPost(version: string, body?: string, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiVversionGuardRevokeTokenPost(version, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiVversionGuardSubmitPost(version: string, id?: string, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiVversionGuardSubmitPost(version, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [id] 
     * @param {GuardRequestStatus} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardApi
     */
    public apiVversionGuardUpdatePost(version: string, id?: string, status?: GuardRequestStatus, options?: RawAxiosRequestConfig) {
        return GuardApiFp(this.configuration).apiVversionGuardUpdatePost(version, id, status, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthTickGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Health/tick`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServerLocation} [location] 
         * @param {number} [elapsed] 
         * @param {number} [timeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthTockGet: async (location?: ServerLocation, elapsed?: number, timeStamp?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Health/tock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (elapsed !== undefined) {
                localVarQueryParameter['elapsed'] = elapsed;
            }

            if (timeStamp !== undefined) {
                localVarQueryParameter['timeStamp'] = timeStamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionHealthTickGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionHealthTickGet', 'version', version)
            const localVarPath = `/api/v{version}/Health/tick`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {ServerLocation} [location] 
         * @param {number} [elapsed] 
         * @param {number} [timeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionHealthTockGet: async (version: string, location?: ServerLocation, elapsed?: number, timeStamp?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionHealthTockGet', 'version', version)
            const localVarPath = `/api/v{version}/Health/tock`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (elapsed !== undefined) {
                localVarQueryParameter['elapsed'] = elapsed;
            }

            if (timeStamp !== undefined) {
                localVarQueryParameter['timeStamp'] = timeStamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHealthTickGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTickeAsyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHealthTickGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthApi.apiHealthTickGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ServerLocation} [location] 
         * @param {number} [elapsed] 
         * @param {number} [timeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHealthTockGet(location?: ServerLocation, elapsed?: number, timeStamp?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHealthTockGet(location, elapsed, timeStamp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthApi.apiHealthTockGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionHealthTickGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTickeAsyncResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionHealthTickGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthApi.apiVversionHealthTickGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {ServerLocation} [location] 
         * @param {number} [elapsed] 
         * @param {number} [timeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionHealthTockGet(version: string, location?: ServerLocation, elapsed?: number, timeStamp?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionHealthTockGet(version, location, elapsed, timeStamp, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthApi.apiVversionHealthTockGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthTickGet(options?: any): AxiosPromise<GetTickeAsyncResponse> {
            return localVarFp.apiHealthTickGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ServerLocation} [location] 
         * @param {number} [elapsed] 
         * @param {number} [timeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthTockGet(location?: ServerLocation, elapsed?: number, timeStamp?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiHealthTockGet(location, elapsed, timeStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionHealthTickGet(version: string, options?: any): AxiosPromise<GetTickeAsyncResponse> {
            return localVarFp.apiVversionHealthTickGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {ServerLocation} [location] 
         * @param {number} [elapsed] 
         * @param {number} [timeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionHealthTockGet(version: string, location?: ServerLocation, elapsed?: number, timeStamp?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionHealthTockGet(version, location, elapsed, timeStamp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public apiHealthTickGet(options?: RawAxiosRequestConfig) {
        return HealthApiFp(this.configuration).apiHealthTickGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ServerLocation} [location] 
     * @param {number} [elapsed] 
     * @param {number} [timeStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public apiHealthTockGet(location?: ServerLocation, elapsed?: number, timeStamp?: number, options?: RawAxiosRequestConfig) {
        return HealthApiFp(this.configuration).apiHealthTockGet(location, elapsed, timeStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public apiVversionHealthTickGet(version: string, options?: RawAxiosRequestConfig) {
        return HealthApiFp(this.configuration).apiVversionHealthTickGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {ServerLocation} [location] 
     * @param {number} [elapsed] 
     * @param {number} [timeStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public apiVversionHealthTockGet(version: string, location?: ServerLocation, elapsed?: number, timeStamp?: number, options?: RawAxiosRequestConfig) {
        return HealthApiFp(this.configuration).apiVversionHealthTockGet(version, location, elapsed, timeStamp, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MarketsApi - axios parameter creator
 * @export
 */
export const MarketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsAssetsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsCurrenciesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets/currencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsCurrencyInfoGet: async (currency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets/currencyInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsGet: async (fingerprint?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fingerprint !== undefined) {
                localVarQueryParameter['fingerprint'] = fingerprint;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsHistoryGet: async (pair?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pair !== undefined) {
                localVarQueryParameter['pair'] = pair;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [reCaptchaToken] 
         * @param {ListingRequest} [listingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsListingRequestPost: async (reCaptchaToken?: string, listingRequest?: ListingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets/ListingRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reCaptchaToken !== undefined) {
                localVarQueryParameter['reCaptchaToken'] = reCaptchaToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsOrderbookGet: async (pair?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets/orderbook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pair !== undefined) {
                localVarQueryParameter['pair'] = pair;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pair 
         * @param {number} [depth] 
         * @param {number} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsOrdersPairGet: async (pair: string, depth?: number, level?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pair' is not null or undefined
            assertParamExists('apiMarketsOrdersPairGet', 'pair', pair)
            const localVarPath = `/api/Markets/orders/{pair}`
                .replace(`{${"pair"}}`, encodeURIComponent(String(pair)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (depth !== undefined) {
                localVarQueryParameter['Depth'] = depth;
            }

            if (level !== undefined) {
                localVarQueryParameter['Level'] = level;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsSummaryGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsTestGet: async (fingerprint?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fingerprint !== undefined) {
                localVarQueryParameter['fingerprint'] = fingerprint;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsTickerGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets/ticker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsTickersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Markets/tickers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsTradesPairGet: async (pair: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pair' is not null or undefined
            assertParamExists('apiMarketsTradesPairGet', 'pair', pair)
            const localVarPath = `/api/Markets/trades/{pair}`
                .replace(`{${"pair"}}`, encodeURIComponent(String(pair)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsAssetsGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsAssetsGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/assets`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsCurrenciesGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsCurrenciesGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/currencies`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsCurrencyInfoGet: async (version: string, currency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsCurrencyInfoGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/currencyInfo`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsGet: async (version: string, fingerprint?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fingerprint !== undefined) {
                localVarQueryParameter['fingerprint'] = fingerprint;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsHistoryGet: async (version: string, pair?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsHistoryGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/history`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pair !== undefined) {
                localVarQueryParameter['pair'] = pair;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [reCaptchaToken] 
         * @param {ListingRequest} [listingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsListingRequestPost: async (version: string, reCaptchaToken?: string, listingRequest?: ListingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsListingRequestPost', 'version', version)
            const localVarPath = `/api/v{version}/Markets/ListingRequest`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reCaptchaToken !== undefined) {
                localVarQueryParameter['reCaptchaToken'] = reCaptchaToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsOrderbookGet: async (version: string, pair?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsOrderbookGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/orderbook`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pair !== undefined) {
                localVarQueryParameter['pair'] = pair;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pair 
         * @param {string} version 
         * @param {number} [depth] 
         * @param {number} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsOrdersPairGet: async (pair: string, version: string, depth?: number, level?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pair' is not null or undefined
            assertParamExists('apiVversionMarketsOrdersPairGet', 'pair', pair)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsOrdersPairGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/orders/{pair}`
                .replace(`{${"pair"}}`, encodeURIComponent(String(pair)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (depth !== undefined) {
                localVarQueryParameter['Depth'] = depth;
            }

            if (level !== undefined) {
                localVarQueryParameter['Level'] = level;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsSummaryGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsSummaryGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/summary`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsTestGet: async (version: string, fingerprint?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsTestGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/test`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fingerprint !== undefined) {
                localVarQueryParameter['fingerprint'] = fingerprint;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsTickerGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsTickerGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/ticker`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsTickersGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsTickersGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/tickers`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pair 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsTradesPairGet: async (pair: string, version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pair' is not null or undefined
            assertParamExists('apiVversionMarketsTradesPairGet', 'pair', pair)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionMarketsTradesPairGet', 'version', version)
            const localVarPath = `/api/v{version}/Markets/trades/{pair}`
                .replace(`{${"pair"}}`, encodeURIComponent(String(pair)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketsApi - functional programming interface
 * @export
 */
export const MarketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsAssetsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: AssetsResponseModel; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsAssetsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsAssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsCurrenciesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetApiMarketsCurrenciesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsCurrenciesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsCurrenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsCurrencyInfoGet(currency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CryptoCurrency>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsCurrencyInfoGet(currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsCurrencyInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsGet(fingerprint?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsGet(fingerprint, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsHistoryGet(pair?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetTradeHistoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsHistoryGet(pair, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsHistoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [reCaptchaToken] 
         * @param {ListingRequest} [listingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsListingRequestPost(reCaptchaToken?: string, listingRequest?: ListingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateListingRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsListingRequestPost(reCaptchaToken, listingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsListingRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsOrderbookGet(pair?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersGetViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsOrderbookGet(pair, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsOrderbookGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} pair 
         * @param {number} [depth] 
         * @param {number} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsOrdersPairGet(pair: string, depth?: number, level?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderbookResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsOrdersPairGet(pair, depth, level, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsOrdersPairGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsSummaryGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SummaryResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsSummaryGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsSummaryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsTestGet(fingerprint?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsTestGet(fingerprint, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsTestGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsTickerGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: TickerResponseModel; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsTickerGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsTickerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsTickersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTickersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsTickersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsTickersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} pair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMarketsTradesPairGet(pair: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradesResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarketsTradesPairGet(pair, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiMarketsTradesPairGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsAssetsGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: AssetsResponseModel; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsAssetsGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsAssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsCurrenciesGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetApiMarketsCurrenciesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsCurrenciesGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsCurrenciesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsCurrencyInfoGet(version: string, currency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CryptoCurrency>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsCurrencyInfoGet(version, currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsCurrencyInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsGet(version: string, fingerprint?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMarketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsGet(version, fingerprint, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsHistoryGet(version: string, pair?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetTradeHistoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsHistoryGet(version, pair, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsHistoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [reCaptchaToken] 
         * @param {ListingRequest} [listingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsListingRequestPost(version: string, reCaptchaToken?: string, listingRequest?: ListingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateListingRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsListingRequestPost(version, reCaptchaToken, listingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsListingRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsOrderbookGet(version: string, pair?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrdersGetViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsOrderbookGet(version, pair, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsOrderbookGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} pair 
         * @param {string} version 
         * @param {number} [depth] 
         * @param {number} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsOrdersPairGet(pair: string, version: string, depth?: number, level?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderbookResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsOrdersPairGet(pair, version, depth, level, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsOrdersPairGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsSummaryGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SummaryResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsSummaryGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsSummaryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsTestGet(version: string, fingerprint?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsTestGet(version, fingerprint, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsTestGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsTickerGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: TickerResponseModel; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsTickerGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsTickerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsTickersGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTickersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsTickersGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsTickersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} pair 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionMarketsTradesPairGet(pair: string, version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradesResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionMarketsTradesPairGet(pair, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MarketsApi.apiVversionMarketsTradesPairGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MarketsApi - factory interface
 * @export
 */
export const MarketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsAssetsGet(options?: any): AxiosPromise<{ [key: string]: AssetsResponseModel; }> {
            return localVarFp.apiMarketsAssetsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsCurrenciesGet(options?: any): AxiosPromise<Array<GetApiMarketsCurrenciesResponse>> {
            return localVarFp.apiMarketsCurrenciesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsCurrencyInfoGet(currency?: string, options?: any): AxiosPromise<CryptoCurrency> {
            return localVarFp.apiMarketsCurrencyInfoGet(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsGet(fingerprint?: string, options?: any): AxiosPromise<GetMarketsResponse> {
            return localVarFp.apiMarketsGet(fingerprint, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsHistoryGet(pair?: string, options?: any): AxiosPromise<Array<GetTradeHistoryResponse>> {
            return localVarFp.apiMarketsHistoryGet(pair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [reCaptchaToken] 
         * @param {ListingRequest} [listingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsListingRequestPost(reCaptchaToken?: string, listingRequest?: ListingRequest, options?: any): AxiosPromise<CreateListingRequestResponse> {
            return localVarFp.apiMarketsListingRequestPost(reCaptchaToken, listingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsOrderbookGet(pair?: string, options?: any): AxiosPromise<OrdersGetViewModel> {
            return localVarFp.apiMarketsOrderbookGet(pair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pair 
         * @param {number} [depth] 
         * @param {number} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsOrdersPairGet(pair: string, depth?: number, level?: number, options?: any): AxiosPromise<OrderbookResponseModel> {
            return localVarFp.apiMarketsOrdersPairGet(pair, depth, level, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsSummaryGet(options?: any): AxiosPromise<Array<SummaryResponseModel>> {
            return localVarFp.apiMarketsSummaryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsTestGet(fingerprint?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMarketsTestGet(fingerprint, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsTickerGet(options?: any): AxiosPromise<{ [key: string]: TickerResponseModel; }> {
            return localVarFp.apiMarketsTickerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsTickersGet(options?: any): AxiosPromise<GetTickersResponse> {
            return localVarFp.apiMarketsTickersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMarketsTradesPairGet(pair: string, options?: any): AxiosPromise<Array<TradesResponseModel>> {
            return localVarFp.apiMarketsTradesPairGet(pair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsAssetsGet(version: string, options?: any): AxiosPromise<{ [key: string]: AssetsResponseModel; }> {
            return localVarFp.apiVversionMarketsAssetsGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsCurrenciesGet(version: string, options?: any): AxiosPromise<Array<GetApiMarketsCurrenciesResponse>> {
            return localVarFp.apiVversionMarketsCurrenciesGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsCurrencyInfoGet(version: string, currency?: string, options?: any): AxiosPromise<CryptoCurrency> {
            return localVarFp.apiVversionMarketsCurrencyInfoGet(version, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsGet(version: string, fingerprint?: string, options?: any): AxiosPromise<GetMarketsResponse> {
            return localVarFp.apiVversionMarketsGet(version, fingerprint, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsHistoryGet(version: string, pair?: string, options?: any): AxiosPromise<Array<GetTradeHistoryResponse>> {
            return localVarFp.apiVversionMarketsHistoryGet(version, pair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [reCaptchaToken] 
         * @param {ListingRequest} [listingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsListingRequestPost(version: string, reCaptchaToken?: string, listingRequest?: ListingRequest, options?: any): AxiosPromise<CreateListingRequestResponse> {
            return localVarFp.apiVversionMarketsListingRequestPost(version, reCaptchaToken, listingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsOrderbookGet(version: string, pair?: string, options?: any): AxiosPromise<OrdersGetViewModel> {
            return localVarFp.apiVversionMarketsOrderbookGet(version, pair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pair 
         * @param {string} version 
         * @param {number} [depth] 
         * @param {number} [level] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsOrdersPairGet(pair: string, version: string, depth?: number, level?: number, options?: any): AxiosPromise<OrderbookResponseModel> {
            return localVarFp.apiVversionMarketsOrdersPairGet(pair, version, depth, level, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsSummaryGet(version: string, options?: any): AxiosPromise<Array<SummaryResponseModel>> {
            return localVarFp.apiVversionMarketsSummaryGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [fingerprint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsTestGet(version: string, fingerprint?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionMarketsTestGet(version, fingerprint, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsTickerGet(version: string, options?: any): AxiosPromise<{ [key: string]: TickerResponseModel; }> {
            return localVarFp.apiVversionMarketsTickerGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsTickersGet(version: string, options?: any): AxiosPromise<GetTickersResponse> {
            return localVarFp.apiVversionMarketsTickersGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pair 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMarketsTradesPairGet(pair: string, version: string, options?: any): AxiosPromise<Array<TradesResponseModel>> {
            return localVarFp.apiVversionMarketsTradesPairGet(pair, version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MarketsApi - object-oriented interface
 * @export
 * @class MarketsApi
 * @extends {BaseAPI}
 */
export class MarketsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsAssetsGet(options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsAssetsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsCurrenciesGet(options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsCurrenciesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsCurrencyInfoGet(currency?: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsCurrencyInfoGet(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fingerprint] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsGet(fingerprint?: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsGet(fingerprint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [pair] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsHistoryGet(pair?: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsHistoryGet(pair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [reCaptchaToken] 
     * @param {ListingRequest} [listingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsListingRequestPost(reCaptchaToken?: string, listingRequest?: ListingRequest, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsListingRequestPost(reCaptchaToken, listingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [pair] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsOrderbookGet(pair?: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsOrderbookGet(pair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pair 
     * @param {number} [depth] 
     * @param {number} [level] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsOrdersPairGet(pair: string, depth?: number, level?: number, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsOrdersPairGet(pair, depth, level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsSummaryGet(options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsSummaryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fingerprint] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsTestGet(fingerprint?: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsTestGet(fingerprint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsTickerGet(options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsTickerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsTickersGet(options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsTickersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pair 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiMarketsTradesPairGet(pair: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiMarketsTradesPairGet(pair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsAssetsGet(version: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsAssetsGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsCurrenciesGet(version: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsCurrenciesGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsCurrencyInfoGet(version: string, currency?: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsCurrencyInfoGet(version, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [fingerprint] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsGet(version: string, fingerprint?: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsGet(version, fingerprint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [pair] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsHistoryGet(version: string, pair?: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsHistoryGet(version, pair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [reCaptchaToken] 
     * @param {ListingRequest} [listingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsListingRequestPost(version: string, reCaptchaToken?: string, listingRequest?: ListingRequest, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsListingRequestPost(version, reCaptchaToken, listingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [pair] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsOrderbookGet(version: string, pair?: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsOrderbookGet(version, pair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pair 
     * @param {string} version 
     * @param {number} [depth] 
     * @param {number} [level] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsOrdersPairGet(pair: string, version: string, depth?: number, level?: number, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsOrdersPairGet(pair, version, depth, level, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsSummaryGet(version: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsSummaryGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [fingerprint] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsTestGet(version: string, fingerprint?: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsTestGet(version, fingerprint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsTickerGet(version: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsTickerGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsTickersGet(version: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsTickersGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pair 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public apiVversionMarketsTradesPairGet(pair: string, version: string, options?: RawAxiosRequestConfig) {
        return MarketsApiFp(this.configuration).apiVversionMarketsTradesPairGet(pair, version, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [alertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsPricealertDelete: async (alertId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notifications/pricealert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (alertId !== undefined) {
                localVarQueryParameter['alertId'] = alertId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [pairName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsPricealertGet: async (pairName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notifications/pricealert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pairName !== undefined) {
                localVarQueryParameter['pairName'] = pairName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPriceAlertRequestModel} [setPriceAlertRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsPricealertPost: async (setPriceAlertRequestModel?: SetPriceAlertRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Notifications/pricealert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPriceAlertRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [alertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNotificationsPricealertDelete: async (version: string, alertId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionNotificationsPricealertDelete', 'version', version)
            const localVarPath = `/api/v{version}/Notifications/pricealert`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (alertId !== undefined) {
                localVarQueryParameter['alertId'] = alertId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pairName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNotificationsPricealertGet: async (version: string, pairName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionNotificationsPricealertGet', 'version', version)
            const localVarPath = `/api/v{version}/Notifications/pricealert`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pairName !== undefined) {
                localVarQueryParameter['pairName'] = pairName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {SetPriceAlertRequestModel} [setPriceAlertRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNotificationsPricealertPost: async (version: string, setPriceAlertRequestModel?: SetPriceAlertRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionNotificationsPricealertPost', 'version', version)
            const localVarPath = `/api/v{version}/Notifications/pricealert`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPriceAlertRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [alertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsPricealertDelete(alertId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsPricealertDelete(alertId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiNotificationsPricealertDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [pairName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsPricealertGet(pairName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceAlert>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsPricealertGet(pairName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiNotificationsPricealertGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SetPriceAlertRequestModel} [setPriceAlertRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNotificationsPricealertPost(setPriceAlertRequestModel?: SetPriceAlertRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetPriceAlertResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNotificationsPricealertPost(setPriceAlertRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiNotificationsPricealertPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [alertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionNotificationsPricealertDelete(version: string, alertId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionNotificationsPricealertDelete(version, alertId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiVversionNotificationsPricealertDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pairName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionNotificationsPricealertGet(version: string, pairName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceAlert>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionNotificationsPricealertGet(version, pairName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiVversionNotificationsPricealertGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {SetPriceAlertRequestModel} [setPriceAlertRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionNotificationsPricealertPost(version: string, setPriceAlertRequestModel?: SetPriceAlertRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetPriceAlertResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionNotificationsPricealertPost(version, setPriceAlertRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.apiVversionNotificationsPricealertPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [alertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsPricealertDelete(alertId?: string, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiNotificationsPricealertDelete(alertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [pairName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsPricealertGet(pairName?: string, options?: any): AxiosPromise<Array<PriceAlert>> {
            return localVarFp.apiNotificationsPricealertGet(pairName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetPriceAlertRequestModel} [setPriceAlertRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsPricealertPost(setPriceAlertRequestModel?: SetPriceAlertRequestModel, options?: any): AxiosPromise<SetPriceAlertResponseModel> {
            return localVarFp.apiNotificationsPricealertPost(setPriceAlertRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [alertId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNotificationsPricealertDelete(version: string, alertId?: string, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionNotificationsPricealertDelete(version, alertId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pairName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNotificationsPricealertGet(version: string, pairName?: string, options?: any): AxiosPromise<Array<PriceAlert>> {
            return localVarFp.apiVversionNotificationsPricealertGet(version, pairName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {SetPriceAlertRequestModel} [setPriceAlertRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionNotificationsPricealertPost(version: string, setPriceAlertRequestModel?: SetPriceAlertRequestModel, options?: any): AxiosPromise<SetPriceAlertResponseModel> {
            return localVarFp.apiVversionNotificationsPricealertPost(version, setPriceAlertRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {string} [alertId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiNotificationsPricealertDelete(alertId?: string, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiNotificationsPricealertDelete(alertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [pairName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiNotificationsPricealertGet(pairName?: string, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiNotificationsPricealertGet(pairName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetPriceAlertRequestModel} [setPriceAlertRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiNotificationsPricealertPost(setPriceAlertRequestModel?: SetPriceAlertRequestModel, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiNotificationsPricealertPost(setPriceAlertRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [alertId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiVversionNotificationsPricealertDelete(version: string, alertId?: string, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiVversionNotificationsPricealertDelete(version, alertId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [pairName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiVversionNotificationsPricealertGet(version: string, pairName?: string, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiVversionNotificationsPricealertGet(version, pairName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {SetPriceAlertRequestModel} [setPriceAlertRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiVversionNotificationsPricealertPost(version: string, setPriceAlertRequestModel?: SetPriceAlertRequestModel, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).apiVversionNotificationsPricealertPost(version, setPriceAlertRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersCancelPost: async (orderId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Orders/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [pairName] 
         * @param {boolean} [isMargin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersCancelallPost: async (pairName?: string, isMargin?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Orders/cancelall`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pairName !== undefined) {
                localVarQueryParameter['pairName'] = pairName;
            }

            if (isMargin !== undefined) {
                localVarQueryParameter['isMargin'] = isMargin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostOrdersRequest} [postOrdersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersPost: async (postOrdersRequest?: PostOrdersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postOrdersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionOrdersCancelPost: async (version: string, orderId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionOrdersCancelPost', 'version', version)
            const localVarPath = `/api/v{version}/Orders/cancel`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pairName] 
         * @param {boolean} [isMargin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionOrdersCancelallPost: async (version: string, pairName?: string, isMargin?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionOrdersCancelallPost', 'version', version)
            const localVarPath = `/api/v{version}/Orders/cancelall`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pairName !== undefined) {
                localVarQueryParameter['pairName'] = pairName;
            }

            if (isMargin !== undefined) {
                localVarQueryParameter['isMargin'] = isMargin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PostOrdersRequest} [postOrdersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionOrdersPost: async (version: string, postOrdersRequest?: PostOrdersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionOrdersPost', 'version', version)
            const localVarPath = `/api/v{version}/Orders`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postOrdersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersCancelPost(orderId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCancelResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersCancelPost(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.apiOrdersCancelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [pairName] 
         * @param {boolean} [isMargin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersCancelallPost(pairName?: string, isMargin?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderCancelResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersCancelallPost(pairName, isMargin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.apiOrdersCancelallPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostOrdersRequest} [postOrdersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersPost(postOrdersRequest?: PostOrdersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCreateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersPost(postOrdersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.apiOrdersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionOrdersCancelPost(version: string, orderId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCancelResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionOrdersCancelPost(version, orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.apiVversionOrdersCancelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pairName] 
         * @param {boolean} [isMargin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionOrdersCancelallPost(version: string, pairName?: string, isMargin?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderCancelResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionOrdersCancelallPost(version, pairName, isMargin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.apiVversionOrdersCancelallPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PostOrdersRequest} [postOrdersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionOrdersPost(version: string, postOrdersRequest?: PostOrdersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCreateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionOrdersPost(version, postOrdersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrdersApi.apiVversionOrdersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersCancelPost(orderId?: number, options?: any): AxiosPromise<OrderCancelResult> {
            return localVarFp.apiOrdersCancelPost(orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [pairName] 
         * @param {boolean} [isMargin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersCancelallPost(pairName?: string, isMargin?: boolean, options?: any): AxiosPromise<Array<OrderCancelResult>> {
            return localVarFp.apiOrdersCancelallPost(pairName, isMargin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostOrdersRequest} [postOrdersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersPost(postOrdersRequest?: PostOrdersRequest, options?: any): AxiosPromise<OrderCreateResult> {
            return localVarFp.apiOrdersPost(postOrdersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionOrdersCancelPost(version: string, orderId?: number, options?: any): AxiosPromise<OrderCancelResult> {
            return localVarFp.apiVversionOrdersCancelPost(version, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pairName] 
         * @param {boolean} [isMargin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionOrdersCancelallPost(version: string, pairName?: string, isMargin?: boolean, options?: any): AxiosPromise<Array<OrderCancelResult>> {
            return localVarFp.apiVversionOrdersCancelallPost(version, pairName, isMargin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PostOrdersRequest} [postOrdersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionOrdersPost(version: string, postOrdersRequest?: PostOrdersRequest, options?: any): AxiosPromise<OrderCreateResult> {
            return localVarFp.apiVversionOrdersPost(version, postOrdersRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @param {number} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersCancelPost(orderId?: number, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersCancelPost(orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [pairName] 
     * @param {boolean} [isMargin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersCancelallPost(pairName?: string, isMargin?: boolean, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersCancelallPost(pairName, isMargin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostOrdersRequest} [postOrdersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiOrdersPost(postOrdersRequest?: PostOrdersRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiOrdersPost(postOrdersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {number} [orderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiVversionOrdersCancelPost(version: string, orderId?: number, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiVversionOrdersCancelPost(version, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [pairName] 
     * @param {boolean} [isMargin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiVversionOrdersCancelallPost(version: string, pairName?: string, isMargin?: boolean, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiVversionOrdersCancelallPost(version, pairName, isMargin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PostOrdersRequest} [postOrdersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public apiVversionOrdersPost(version: string, postOrdersRequest?: PostOrdersRequest, options?: RawAxiosRequestConfig) {
        return OrdersApiFp(this.configuration).apiVversionOrdersPost(version, postOrdersRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * P2PApi - axios parameter creator
 * @export
 */
export const P2PApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiP2PInfoGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/P2P/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [username] 
         * @param {string} [secondTelegramCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiP2PInfoPost: async (username?: string, secondTelegramCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/P2P/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (secondTelegramCode !== undefined) {
                localVarQueryParameter['secondTelegramCode'] = secondTelegramCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiP2POffersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/P2P/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionP2PInfoGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionP2PInfoGet', 'version', version)
            const localVarPath = `/api/v{version}/P2P/info`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [username] 
         * @param {string} [secondTelegramCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionP2PInfoPost: async (version: string, username?: string, secondTelegramCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionP2PInfoPost', 'version', version)
            const localVarPath = `/api/v{version}/P2P/info`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (secondTelegramCode !== undefined) {
                localVarQueryParameter['secondTelegramCode'] = secondTelegramCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionP2POffersGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionP2POffersGet', 'version', version)
            const localVarPath = `/api/v{version}/P2P/offers`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * P2PApi - functional programming interface
 * @export
 */
export const P2PApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = P2PApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiP2PInfoGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiP2PInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiP2PInfoGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['P2PApi.apiP2PInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [username] 
         * @param {string} [secondTelegramCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiP2PInfoPost(username?: string, secondTelegramCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiP2PInfoPost(username, secondTelegramCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['P2PApi.apiP2PInfoPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiP2POffersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiOpenOffer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiP2POffersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['P2PApi.apiP2POffersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionP2PInfoGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiP2PInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionP2PInfoGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['P2PApi.apiVversionP2PInfoGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [username] 
         * @param {string} [secondTelegramCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionP2PInfoPost(version: string, username?: string, secondTelegramCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionP2PInfoPost(version, username, secondTelegramCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['P2PApi.apiVversionP2PInfoPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionP2POffersGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiOpenOffer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionP2POffersGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['P2PApi.apiVversionP2POffersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * P2PApi - factory interface
 * @export
 */
export const P2PApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = P2PApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiP2PInfoGet(options?: any): AxiosPromise<ApiP2PInfo> {
            return localVarFp.apiP2PInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [username] 
         * @param {string} [secondTelegramCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiP2PInfoPost(username?: string, secondTelegramCode?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiP2PInfoPost(username, secondTelegramCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiP2POffersGet(options?: any): AxiosPromise<Array<ApiOpenOffer>> {
            return localVarFp.apiP2POffersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionP2PInfoGet(version: string, options?: any): AxiosPromise<ApiP2PInfo> {
            return localVarFp.apiVversionP2PInfoGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [username] 
         * @param {string} [secondTelegramCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionP2PInfoPost(version: string, username?: string, secondTelegramCode?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiVversionP2PInfoPost(version, username, secondTelegramCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionP2POffersGet(version: string, options?: any): AxiosPromise<Array<ApiOpenOffer>> {
            return localVarFp.apiVversionP2POffersGet(version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * P2PApi - object-oriented interface
 * @export
 * @class P2PApi
 * @extends {BaseAPI}
 */
export class P2PApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2PApi
     */
    public apiP2PInfoGet(options?: RawAxiosRequestConfig) {
        return P2PApiFp(this.configuration).apiP2PInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [username] 
     * @param {string} [secondTelegramCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2PApi
     */
    public apiP2PInfoPost(username?: string, secondTelegramCode?: string, options?: RawAxiosRequestConfig) {
        return P2PApiFp(this.configuration).apiP2PInfoPost(username, secondTelegramCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2PApi
     */
    public apiP2POffersGet(options?: RawAxiosRequestConfig) {
        return P2PApiFp(this.configuration).apiP2POffersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2PApi
     */
    public apiVversionP2PInfoGet(version: string, options?: RawAxiosRequestConfig) {
        return P2PApiFp(this.configuration).apiVversionP2PInfoGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [username] 
     * @param {string} [secondTelegramCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2PApi
     */
    public apiVversionP2PInfoPost(version: string, username?: string, secondTelegramCode?: string, options?: RawAxiosRequestConfig) {
        return P2PApiFp(this.configuration).apiVversionP2PInfoPost(version, username, secondTelegramCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof P2PApi
     */
    public apiVversionP2POffersGet(version: string, options?: RawAxiosRequestConfig) {
        return P2PApiFp(this.configuration).apiVversionP2POffersGet(version, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SharkApi - axios parameter creator
 * @export
 */
export const SharkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [telegramId] 
         * @param {string} [email] 
         * @param {boolean} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharkRegisterPost: async (telegramId?: string, email?: string, register?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Shark/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (telegramId !== undefined) {
                localVarQueryParameter['telegramId'] = telegramId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (register !== undefined) {
                localVarQueryParameter['register'] = register;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [telegramId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharkTapPost: async (telegramId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Shark/tap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (telegramId !== undefined) {
                localVarQueryParameter['telegramId'] = telegramId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [telegramId] 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharkUserdataGet: async (telegramId?: string, referrer?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Shark/userdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (telegramId !== undefined) {
                localVarQueryParameter['telegramId'] = telegramId;
            }

            if (referrer !== undefined) {
                localVarQueryParameter['referrer'] = referrer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [telegramId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharkVerifyRegisterPost: async (telegramId?: string, code?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Shark/verifyRegister`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (telegramId !== undefined) {
                localVarQueryParameter['telegramId'] = telegramId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {string} [email] 
         * @param {boolean} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSharkRegisterPost: async (version: string, telegramId?: string, email?: string, register?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSharkRegisterPost', 'version', version)
            const localVarPath = `/api/v{version}/Shark/register`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (telegramId !== undefined) {
                localVarQueryParameter['telegramId'] = telegramId;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (register !== undefined) {
                localVarQueryParameter['register'] = register;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSharkTapPost: async (version: string, telegramId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSharkTapPost', 'version', version)
            const localVarPath = `/api/v{version}/Shark/tap`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (telegramId !== undefined) {
                localVarQueryParameter['telegramId'] = telegramId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSharkUserdataGet: async (version: string, telegramId?: string, referrer?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSharkUserdataGet', 'version', version)
            const localVarPath = `/api/v{version}/Shark/userdata`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (telegramId !== undefined) {
                localVarQueryParameter['telegramId'] = telegramId;
            }

            if (referrer !== undefined) {
                localVarQueryParameter['referrer'] = referrer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSharkVerifyRegisterPost: async (version: string, telegramId?: string, code?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSharkVerifyRegisterPost', 'version', version)
            const localVarPath = `/api/v{version}/Shark/verifyRegister`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (telegramId !== undefined) {
                localVarQueryParameter['telegramId'] = telegramId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharkApi - functional programming interface
 * @export
 */
export const SharkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SharkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [telegramId] 
         * @param {string} [email] 
         * @param {boolean} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSharkRegisterPost(telegramId?: string, email?: string, register?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSharkRegisterResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSharkRegisterPost(telegramId, email, register, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharkApi.apiSharkRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [telegramId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSharkTapPost(telegramId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TapResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSharkTapPost(telegramId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharkApi.apiSharkTapPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [telegramId] 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSharkUserdataGet(telegramId?: string, referrer?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharkUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSharkUserdataGet(telegramId, referrer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharkApi.apiSharkUserdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [telegramId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSharkVerifyRegisterPost(telegramId?: string, code?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyRegisterResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSharkVerifyRegisterPost(telegramId, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharkApi.apiSharkVerifyRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {string} [email] 
         * @param {boolean} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSharkRegisterPost(version: string, telegramId?: string, email?: string, register?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSharkRegisterResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSharkRegisterPost(version, telegramId, email, register, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharkApi.apiVversionSharkRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSharkTapPost(version: string, telegramId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TapResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSharkTapPost(version, telegramId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharkApi.apiVversionSharkTapPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSharkUserdataGet(version: string, telegramId?: string, referrer?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharkUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSharkUserdataGet(version, telegramId, referrer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharkApi.apiVversionSharkUserdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSharkVerifyRegisterPost(version: string, telegramId?: string, code?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyRegisterResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSharkVerifyRegisterPost(version, telegramId, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SharkApi.apiVversionSharkVerifyRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SharkApi - factory interface
 * @export
 */
export const SharkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SharkApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [telegramId] 
         * @param {string} [email] 
         * @param {boolean} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharkRegisterPost(telegramId?: string, email?: string, register?: boolean, options?: any): AxiosPromise<PostSharkRegisterResult> {
            return localVarFp.apiSharkRegisterPost(telegramId, email, register, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [telegramId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharkTapPost(telegramId?: string, options?: any): AxiosPromise<TapResult> {
            return localVarFp.apiSharkTapPost(telegramId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [telegramId] 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharkUserdataGet(telegramId?: string, referrer?: string, options?: any): AxiosPromise<SharkUser> {
            return localVarFp.apiSharkUserdataGet(telegramId, referrer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [telegramId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharkVerifyRegisterPost(telegramId?: string, code?: string, options?: any): AxiosPromise<VerifyRegisterResult> {
            return localVarFp.apiSharkVerifyRegisterPost(telegramId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {string} [email] 
         * @param {boolean} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSharkRegisterPost(version: string, telegramId?: string, email?: string, register?: boolean, options?: any): AxiosPromise<PostSharkRegisterResult> {
            return localVarFp.apiVversionSharkRegisterPost(version, telegramId, email, register, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSharkTapPost(version: string, telegramId?: string, options?: any): AxiosPromise<TapResult> {
            return localVarFp.apiVversionSharkTapPost(version, telegramId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {string} [referrer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSharkUserdataGet(version: string, telegramId?: string, referrer?: string, options?: any): AxiosPromise<SharkUser> {
            return localVarFp.apiVversionSharkUserdataGet(version, telegramId, referrer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [telegramId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSharkVerifyRegisterPost(version: string, telegramId?: string, code?: string, options?: any): AxiosPromise<VerifyRegisterResult> {
            return localVarFp.apiVversionSharkVerifyRegisterPost(version, telegramId, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SharkApi - object-oriented interface
 * @export
 * @class SharkApi
 * @extends {BaseAPI}
 */
export class SharkApi extends BaseAPI {
    /**
     * 
     * @param {string} [telegramId] 
     * @param {string} [email] 
     * @param {boolean} [register] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharkApi
     */
    public apiSharkRegisterPost(telegramId?: string, email?: string, register?: boolean, options?: RawAxiosRequestConfig) {
        return SharkApiFp(this.configuration).apiSharkRegisterPost(telegramId, email, register, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [telegramId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharkApi
     */
    public apiSharkTapPost(telegramId?: string, options?: RawAxiosRequestConfig) {
        return SharkApiFp(this.configuration).apiSharkTapPost(telegramId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [telegramId] 
     * @param {string} [referrer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharkApi
     */
    public apiSharkUserdataGet(telegramId?: string, referrer?: string, options?: RawAxiosRequestConfig) {
        return SharkApiFp(this.configuration).apiSharkUserdataGet(telegramId, referrer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [telegramId] 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharkApi
     */
    public apiSharkVerifyRegisterPost(telegramId?: string, code?: string, options?: RawAxiosRequestConfig) {
        return SharkApiFp(this.configuration).apiSharkVerifyRegisterPost(telegramId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [telegramId] 
     * @param {string} [email] 
     * @param {boolean} [register] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharkApi
     */
    public apiVversionSharkRegisterPost(version: string, telegramId?: string, email?: string, register?: boolean, options?: RawAxiosRequestConfig) {
        return SharkApiFp(this.configuration).apiVversionSharkRegisterPost(version, telegramId, email, register, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [telegramId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharkApi
     */
    public apiVversionSharkTapPost(version: string, telegramId?: string, options?: RawAxiosRequestConfig) {
        return SharkApiFp(this.configuration).apiVversionSharkTapPost(version, telegramId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [telegramId] 
     * @param {string} [referrer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharkApi
     */
    public apiVversionSharkUserdataGet(version: string, telegramId?: string, referrer?: string, options?: RawAxiosRequestConfig) {
        return SharkApiFp(this.configuration).apiVversionSharkUserdataGet(version, telegramId, referrer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [telegramId] 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharkApi
     */
    public apiVversionSharkVerifyRegisterPost(version: string, telegramId?: string, code?: string, options?: RawAxiosRequestConfig) {
        return SharkApiFp(this.configuration).apiVversionSharkVerifyRegisterPost(version, telegramId, code, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SignApi - axios parameter creator
 * @export
 */
export const SignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignAskForPermissionPost: async (askForPermissionRequestModel?: AskForPermissionRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/AskForPermission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(askForPermissionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignAskPermissionSignInPost: async (askForPermissionRequestModel?: AskForPermissionRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/AskPermissionSignIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(askForPermissionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CashbackSignUpRequest} [cashbackSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignCashbacksignupPost: async (cashbackSignUpRequest?: CashbackSignUpRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/cashbacksignup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cashbackSignUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bitflexDeviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignCheck2StepVerificationStatusGet: async (bitflexDeviceId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/Check2StepVerificationStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bitflexDeviceId !== undefined) {
                localVarQueryParameter['bitflexDeviceId'] = bitflexDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostConfirmRequest} [postConfirmRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignConfirmemailPost: async (postConfirmRequest?: PostConfirmRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/confirmemail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignGetPermissionDetailsGet: async (id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/GetPermissionDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [refId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignGetreferreremailGet: async (refId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/getreferreremail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (refId !== undefined) {
                localVarQueryParameter['refId'] = refId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignIsAnyDeviceOnlinePost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/IsAnyDeviceOnline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostMobileConfirmationRequestModel} [postMobileConfirmationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignMobileConfirmationRequestPost: async (postMobileConfirmationRequestModel?: PostMobileConfirmationRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/MobileConfirmationRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMobileConfirmationRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostResetPasswordRequest} [postResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignResetpasswordPost: async (postResetPasswordRequest?: PostResetPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/resetpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostForgotPasswordRequest} [postForgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignRestorepasswordPost: async (postForgotPasswordRequest?: PostForgotPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/restorepassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postForgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSetPasswordPost: async (userId?: string, code?: string, password?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/SetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [confirmationCode] 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSetnewpasswordPost: async (confirmationCode?: string, changePasswordRequest?: ChangePasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/setnewpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (confirmationCode !== undefined) {
                localVarQueryParameter['confirmationCode'] = confirmationCode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSigninGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostSignInRequest} [postSignInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSigninPost: async (postSignInRequest?: PostSignInRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSignInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSignoutDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/signout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostSignUpRequest} [postSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSignupPost: async (postSignUpRequest?: PostSignUpRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Sign/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSignUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignAskForPermissionPost: async (version: string, askForPermissionRequestModel?: AskForPermissionRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignAskForPermissionPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/AskForPermission`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(askForPermissionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignAskPermissionSignInPost: async (version: string, askForPermissionRequestModel?: AskForPermissionRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignAskPermissionSignInPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/AskPermissionSignIn`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(askForPermissionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {CashbackSignUpRequest} [cashbackSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignCashbacksignupPost: async (version: string, cashbackSignUpRequest?: CashbackSignUpRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignCashbacksignupPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/cashbacksignup`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cashbackSignUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [bitflexDeviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignCheck2StepVerificationStatusGet: async (version: string, bitflexDeviceId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignCheck2StepVerificationStatusGet', 'version', version)
            const localVarPath = `/api/v{version}/Sign/Check2StepVerificationStatus`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bitflexDeviceId !== undefined) {
                localVarQueryParameter['bitflexDeviceId'] = bitflexDeviceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PostConfirmRequest} [postConfirmRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignConfirmemailPost: async (version: string, postConfirmRequest?: PostConfirmRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignConfirmemailPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/confirmemail`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postConfirmRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignGetPermissionDetailsGet: async (version: string, id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignGetPermissionDetailsGet', 'version', version)
            const localVarPath = `/api/v{version}/Sign/GetPermissionDetails`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [refId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignGetreferreremailGet: async (version: string, refId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignGetreferreremailGet', 'version', version)
            const localVarPath = `/api/v{version}/Sign/getreferreremail`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (refId !== undefined) {
                localVarQueryParameter['refId'] = refId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignIsAnyDeviceOnlinePost: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignIsAnyDeviceOnlinePost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/IsAnyDeviceOnline`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PostMobileConfirmationRequestModel} [postMobileConfirmationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignMobileConfirmationRequestPost: async (version: string, postMobileConfirmationRequestModel?: PostMobileConfirmationRequestModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignMobileConfirmationRequestPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/MobileConfirmationRequest`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMobileConfirmationRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PostResetPasswordRequest} [postResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignResetpasswordPost: async (version: string, postResetPasswordRequest?: PostResetPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignResetpasswordPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/resetpassword`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postResetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PostForgotPasswordRequest} [postForgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignRestorepasswordPost: async (version: string, postForgotPasswordRequest?: PostForgotPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignRestorepasswordPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/restorepassword`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postForgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSetPasswordPost: async (version: string, userId?: string, code?: string, password?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignSetPasswordPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/SetPassword`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [confirmationCode] 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSetnewpasswordPost: async (version: string, confirmationCode?: string, changePasswordRequest?: ChangePasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignSetnewpasswordPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/setnewpassword`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (confirmationCode !== undefined) {
                localVarQueryParameter['confirmationCode'] = confirmationCode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSigninGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignSigninGet', 'version', version)
            const localVarPath = `/api/v{version}/Sign/signin`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PostSignInRequest} [postSignInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSigninPost: async (version: string, postSignInRequest?: PostSignInRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignSigninPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/signin`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSignInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSignoutDelete: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignSignoutDelete', 'version', version)
            const localVarPath = `/api/v{version}/Sign/signout`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PostSignUpRequest} [postSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSignupPost: async (version: string, postSignUpRequest?: PostSignUpRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionSignSignupPost', 'version', version)
            const localVarPath = `/api/v{version}/Sign/signup`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSignUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignApi - functional programming interface
 * @export
 */
export const SignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignAskForPermissionPost(askForPermissionRequestModel?: AskForPermissionRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignAskForPermissionPost(askForPermissionRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignAskForPermissionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignAskPermissionSignInPost(askForPermissionRequestModel?: AskForPermissionRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AskPermissionSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignAskPermissionSignInPost(askForPermissionRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignAskPermissionSignInPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CashbackSignUpRequest} [cashbackSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignCashbacksignupPost(cashbackSignUpRequest?: CashbackSignUpRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSignUpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignCashbacksignupPost(cashbackSignUpRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignCashbacksignupPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [bitflexDeviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignCheck2StepVerificationStatusGet(bitflexDeviceId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Check2StepVerificationStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignCheck2StepVerificationStatusGet(bitflexDeviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignCheck2StepVerificationStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostConfirmRequest} [postConfirmRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignConfirmemailPost(postConfirmRequest?: PostConfirmRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailConfirmationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignConfirmemailPost(postConfirmRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignConfirmemailPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignGetPermissionDetailsGet(id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInPermissionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignGetPermissionDetailsGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignGetPermissionDetailsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [refId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignGetreferreremailGet(refId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignGetreferreremailGet(refId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignGetreferreremailGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignIsAnyDeviceOnlinePost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignIsAnyDeviceOnlinePost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignIsAnyDeviceOnlinePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostMobileConfirmationRequestModel} [postMobileConfirmationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignMobileConfirmationRequestPost(postMobileConfirmationRequestModel?: PostMobileConfirmationRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignMobileConfirmationRequestPost(postMobileConfirmationRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignMobileConfirmationRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostResetPasswordRequest} [postResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignResetpasswordPost(postResetPasswordRequest?: PostResetPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignResetpasswordPost(postResetPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignResetpasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostForgotPasswordRequest} [postForgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignRestorepasswordPost(postForgotPasswordRequest?: PostForgotPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignRestorepasswordPost(postForgotPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignRestorepasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignSetPasswordPost(userId?: string, code?: string, password?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignSetPasswordPost(userId, code, password, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignSetPasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [confirmationCode] 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignSetnewpasswordPost(confirmationCode?: string, changePasswordRequest?: ChangePasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignSetnewpasswordPost(confirmationCode, changePasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignSetnewpasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignSigninGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignSigninGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignSigninGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostSignInRequest} [postSignInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignSigninPost(postSignInRequest?: PostSignInRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignSigninPost(postSignInRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignSigninPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignSignoutDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignSignoutDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignSignoutDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostSignUpRequest} [postSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSignSignupPost(postSignUpRequest?: PostSignUpRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSignUpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSignSignupPost(postSignUpRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiSignSignupPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignAskForPermissionPost(version: string, askForPermissionRequestModel?: AskForPermissionRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignAskForPermissionPost(version, askForPermissionRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignAskForPermissionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignAskPermissionSignInPost(version: string, askForPermissionRequestModel?: AskForPermissionRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AskPermissionSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignAskPermissionSignInPost(version, askForPermissionRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignAskPermissionSignInPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {CashbackSignUpRequest} [cashbackSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignCashbacksignupPost(version: string, cashbackSignUpRequest?: CashbackSignUpRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSignUpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignCashbacksignupPost(version, cashbackSignUpRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignCashbacksignupPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [bitflexDeviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignCheck2StepVerificationStatusGet(version: string, bitflexDeviceId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Check2StepVerificationStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignCheck2StepVerificationStatusGet(version, bitflexDeviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignCheck2StepVerificationStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PostConfirmRequest} [postConfirmRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignConfirmemailPost(version: string, postConfirmRequest?: PostConfirmRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailConfirmationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignConfirmemailPost(version, postConfirmRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignConfirmemailPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignGetPermissionDetailsGet(version: string, id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignInPermissionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignGetPermissionDetailsGet(version, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignGetPermissionDetailsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [refId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignGetreferreremailGet(version: string, refId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignGetreferreremailGet(version, refId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignGetreferreremailGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignIsAnyDeviceOnlinePost(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignIsAnyDeviceOnlinePost(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignIsAnyDeviceOnlinePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PostMobileConfirmationRequestModel} [postMobileConfirmationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignMobileConfirmationRequestPost(version: string, postMobileConfirmationRequestModel?: PostMobileConfirmationRequestModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignMobileConfirmationRequestPost(version, postMobileConfirmationRequestModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignMobileConfirmationRequestPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PostResetPasswordRequest} [postResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignResetpasswordPost(version: string, postResetPasswordRequest?: PostResetPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignResetpasswordPost(version, postResetPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignResetpasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PostForgotPasswordRequest} [postForgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignRestorepasswordPost(version: string, postForgotPasswordRequest?: PostForgotPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignRestorepasswordPost(version, postForgotPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignRestorepasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignSetPasswordPost(version: string, userId?: string, code?: string, password?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdentityResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignSetPasswordPost(version, userId, code, password, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignSetPasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [confirmationCode] 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignSetnewpasswordPost(version: string, confirmationCode?: string, changePasswordRequest?: ChangePasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignSetnewpasswordPost(version, confirmationCode, changePasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignSetnewpasswordPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignSigninGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignSigninGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignSigninGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PostSignInRequest} [postSignInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignSigninPost(version: string, postSignInRequest?: PostSignInRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignSigninPost(version, postSignInRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignSigninPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignSignoutDelete(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignSignoutDelete(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignSignoutDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PostSignUpRequest} [postSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionSignSignupPost(version: string, postSignUpRequest?: PostSignUpRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSignUpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionSignSignupPost(version, postSignUpRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SignApi.apiVversionSignSignupPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SignApi - factory interface
 * @export
 */
export const SignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SignApiFp(configuration)
    return {
        /**
         * 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignAskForPermissionPost(askForPermissionRequestModel?: AskForPermissionRequestModel, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiSignAskForPermissionPost(askForPermissionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignAskPermissionSignInPost(askForPermissionRequestModel?: AskForPermissionRequestModel, options?: any): AxiosPromise<AskPermissionSignInResponse> {
            return localVarFp.apiSignAskPermissionSignInPost(askForPermissionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CashbackSignUpRequest} [cashbackSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignCashbacksignupPost(cashbackSignUpRequest?: CashbackSignUpRequest, options?: any): AxiosPromise<PostSignUpResponse> {
            return localVarFp.apiSignCashbacksignupPost(cashbackSignUpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bitflexDeviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignCheck2StepVerificationStatusGet(bitflexDeviceId?: string, options?: any): AxiosPromise<Check2StepVerificationStatusResponse> {
            return localVarFp.apiSignCheck2StepVerificationStatusGet(bitflexDeviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostConfirmRequest} [postConfirmRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignConfirmemailPost(postConfirmRequest?: PostConfirmRequest, options?: any): AxiosPromise<EmailConfirmationResult> {
            return localVarFp.apiSignConfirmemailPost(postConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignGetPermissionDetailsGet(id?: string, options?: any): AxiosPromise<SignInPermissionModel> {
            return localVarFp.apiSignGetPermissionDetailsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [refId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignGetreferreremailGet(refId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiSignGetreferreremailGet(refId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignIsAnyDeviceOnlinePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiSignIsAnyDeviceOnlinePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostMobileConfirmationRequestModel} [postMobileConfirmationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignMobileConfirmationRequestPost(postMobileConfirmationRequestModel?: PostMobileConfirmationRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiSignMobileConfirmationRequestPost(postMobileConfirmationRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostResetPasswordRequest} [postResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignResetpasswordPost(postResetPasswordRequest?: PostResetPasswordRequest, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiSignResetpasswordPost(postResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostForgotPasswordRequest} [postForgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignRestorepasswordPost(postForgotPasswordRequest?: PostForgotPasswordRequest, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiSignRestorepasswordPost(postForgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSetPasswordPost(userId?: string, code?: string, password?: string, options?: any): AxiosPromise<IdentityResult> {
            return localVarFp.apiSignSetPasswordPost(userId, code, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [confirmationCode] 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSetnewpasswordPost(confirmationCode?: string, changePasswordRequest?: ChangePasswordRequest, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiSignSetnewpasswordPost(confirmationCode, changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSigninGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiSignSigninGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostSignInRequest} [postSignInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSigninPost(postSignInRequest?: PostSignInRequest, options?: any): AxiosPromise<PostSignInResponse> {
            return localVarFp.apiSignSigninPost(postSignInRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSignoutDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiSignSignoutDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostSignUpRequest} [postSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSignSignupPost(postSignUpRequest?: PostSignUpRequest, options?: any): AxiosPromise<PostSignUpResponse> {
            return localVarFp.apiSignSignupPost(postSignUpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignAskForPermissionPost(version: string, askForPermissionRequestModel?: AskForPermissionRequestModel, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionSignAskForPermissionPost(version, askForPermissionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignAskPermissionSignInPost(version: string, askForPermissionRequestModel?: AskForPermissionRequestModel, options?: any): AxiosPromise<AskPermissionSignInResponse> {
            return localVarFp.apiVversionSignAskPermissionSignInPost(version, askForPermissionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {CashbackSignUpRequest} [cashbackSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignCashbacksignupPost(version: string, cashbackSignUpRequest?: CashbackSignUpRequest, options?: any): AxiosPromise<PostSignUpResponse> {
            return localVarFp.apiVversionSignCashbacksignupPost(version, cashbackSignUpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [bitflexDeviceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignCheck2StepVerificationStatusGet(version: string, bitflexDeviceId?: string, options?: any): AxiosPromise<Check2StepVerificationStatusResponse> {
            return localVarFp.apiVversionSignCheck2StepVerificationStatusGet(version, bitflexDeviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PostConfirmRequest} [postConfirmRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignConfirmemailPost(version: string, postConfirmRequest?: PostConfirmRequest, options?: any): AxiosPromise<EmailConfirmationResult> {
            return localVarFp.apiVversionSignConfirmemailPost(version, postConfirmRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignGetPermissionDetailsGet(version: string, id?: string, options?: any): AxiosPromise<SignInPermissionModel> {
            return localVarFp.apiVversionSignGetPermissionDetailsGet(version, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [refId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignGetreferreremailGet(version: string, refId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiVversionSignGetreferreremailGet(version, refId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignIsAnyDeviceOnlinePost(version: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionSignIsAnyDeviceOnlinePost(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PostMobileConfirmationRequestModel} [postMobileConfirmationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignMobileConfirmationRequestPost(version: string, postMobileConfirmationRequestModel?: PostMobileConfirmationRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionSignMobileConfirmationRequestPost(version, postMobileConfirmationRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PostResetPasswordRequest} [postResetPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignResetpasswordPost(version: string, postResetPasswordRequest?: PostResetPasswordRequest, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionSignResetpasswordPost(version, postResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PostForgotPasswordRequest} [postForgotPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignRestorepasswordPost(version: string, postForgotPasswordRequest?: PostForgotPasswordRequest, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionSignRestorepasswordPost(version, postForgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [userId] 
         * @param {string} [code] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSetPasswordPost(version: string, userId?: string, code?: string, password?: string, options?: any): AxiosPromise<IdentityResult> {
            return localVarFp.apiVversionSignSetPasswordPost(version, userId, code, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [confirmationCode] 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSetnewpasswordPost(version: string, confirmationCode?: string, changePasswordRequest?: ChangePasswordRequest, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionSignSetnewpasswordPost(version, confirmationCode, changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSigninGet(version: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionSignSigninGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PostSignInRequest} [postSignInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSigninPost(version: string, postSignInRequest?: PostSignInRequest, options?: any): AxiosPromise<PostSignInResponse> {
            return localVarFp.apiVversionSignSigninPost(version, postSignInRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSignoutDelete(version: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionSignSignoutDelete(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PostSignUpRequest} [postSignUpRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionSignSignupPost(version: string, postSignUpRequest?: PostSignUpRequest, options?: any): AxiosPromise<PostSignUpResponse> {
            return localVarFp.apiVversionSignSignupPost(version, postSignUpRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SignApi - object-oriented interface
 * @export
 * @class SignApi
 * @extends {BaseAPI}
 */
export class SignApi extends BaseAPI {
    /**
     * 
     * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignAskForPermissionPost(askForPermissionRequestModel?: AskForPermissionRequestModel, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignAskForPermissionPost(askForPermissionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignAskPermissionSignInPost(askForPermissionRequestModel?: AskForPermissionRequestModel, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignAskPermissionSignInPost(askForPermissionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashbackSignUpRequest} [cashbackSignUpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignCashbacksignupPost(cashbackSignUpRequest?: CashbackSignUpRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignCashbacksignupPost(cashbackSignUpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bitflexDeviceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignCheck2StepVerificationStatusGet(bitflexDeviceId?: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignCheck2StepVerificationStatusGet(bitflexDeviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostConfirmRequest} [postConfirmRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignConfirmemailPost(postConfirmRequest?: PostConfirmRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignConfirmemailPost(postConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignGetPermissionDetailsGet(id?: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignGetPermissionDetailsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [refId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignGetreferreremailGet(refId?: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignGetreferreremailGet(refId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignIsAnyDeviceOnlinePost(options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignIsAnyDeviceOnlinePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostMobileConfirmationRequestModel} [postMobileConfirmationRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignMobileConfirmationRequestPost(postMobileConfirmationRequestModel?: PostMobileConfirmationRequestModel, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignMobileConfirmationRequestPost(postMobileConfirmationRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostResetPasswordRequest} [postResetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignResetpasswordPost(postResetPasswordRequest?: PostResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignResetpasswordPost(postResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostForgotPasswordRequest} [postForgotPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignRestorepasswordPost(postForgotPasswordRequest?: PostForgotPasswordRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignRestorepasswordPost(postForgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userId] 
     * @param {string} [code] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignSetPasswordPost(userId?: string, code?: string, password?: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignSetPasswordPost(userId, code, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [confirmationCode] 
     * @param {ChangePasswordRequest} [changePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignSetnewpasswordPost(confirmationCode?: string, changePasswordRequest?: ChangePasswordRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignSetnewpasswordPost(confirmationCode, changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignSigninGet(options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignSigninGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostSignInRequest} [postSignInRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignSigninPost(postSignInRequest?: PostSignInRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignSigninPost(postSignInRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignSignoutDelete(options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignSignoutDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostSignUpRequest} [postSignUpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSignSignupPost(postSignUpRequest?: PostSignUpRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSignSignupPost(postSignUpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignAskForPermissionPost(version: string, askForPermissionRequestModel?: AskForPermissionRequestModel, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignAskForPermissionPost(version, askForPermissionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {AskForPermissionRequestModel} [askForPermissionRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignAskPermissionSignInPost(version: string, askForPermissionRequestModel?: AskForPermissionRequestModel, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignAskPermissionSignInPost(version, askForPermissionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {CashbackSignUpRequest} [cashbackSignUpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignCashbacksignupPost(version: string, cashbackSignUpRequest?: CashbackSignUpRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignCashbacksignupPost(version, cashbackSignUpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [bitflexDeviceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignCheck2StepVerificationStatusGet(version: string, bitflexDeviceId?: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignCheck2StepVerificationStatusGet(version, bitflexDeviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PostConfirmRequest} [postConfirmRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignConfirmemailPost(version: string, postConfirmRequest?: PostConfirmRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignConfirmemailPost(version, postConfirmRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignGetPermissionDetailsGet(version: string, id?: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignGetPermissionDetailsGet(version, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [refId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignGetreferreremailGet(version: string, refId?: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignGetreferreremailGet(version, refId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignIsAnyDeviceOnlinePost(version: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignIsAnyDeviceOnlinePost(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PostMobileConfirmationRequestModel} [postMobileConfirmationRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignMobileConfirmationRequestPost(version: string, postMobileConfirmationRequestModel?: PostMobileConfirmationRequestModel, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignMobileConfirmationRequestPost(version, postMobileConfirmationRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PostResetPasswordRequest} [postResetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignResetpasswordPost(version: string, postResetPasswordRequest?: PostResetPasswordRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignResetpasswordPost(version, postResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PostForgotPasswordRequest} [postForgotPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignRestorepasswordPost(version: string, postForgotPasswordRequest?: PostForgotPasswordRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignRestorepasswordPost(version, postForgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [userId] 
     * @param {string} [code] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignSetPasswordPost(version: string, userId?: string, code?: string, password?: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignSetPasswordPost(version, userId, code, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [confirmationCode] 
     * @param {ChangePasswordRequest} [changePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignSetnewpasswordPost(version: string, confirmationCode?: string, changePasswordRequest?: ChangePasswordRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignSetnewpasswordPost(version, confirmationCode, changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignSigninGet(version: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignSigninGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PostSignInRequest} [postSignInRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignSigninPost(version: string, postSignInRequest?: PostSignInRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignSigninPost(version, postSignInRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignSignoutDelete(version: string, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignSignoutDelete(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PostSignUpRequest} [postSignUpRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiVversionSignSignupPost(version: string, postSignUpRequest?: PostSignUpRequest, options?: RawAxiosRequestConfig) {
        return SignApiFp(this.configuration).apiVversionSignSignupPost(version, postSignUpRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StaticPagesApi - axios parameter creator
 * @export
 */
export const StaticPagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaticPagesStatusGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/StaticPages/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionStaticPagesStatusGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionStaticPagesStatusGet', 'version', version)
            const localVarPath = `/api/v{version}/StaticPages/status`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticPagesApi - functional programming interface
 * @export
 */
export const StaticPagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StaticPagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaticPagesStatusGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrencyStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStaticPagesStatusGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StaticPagesApi.apiStaticPagesStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionStaticPagesStatusGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CurrencyStatus>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionStaticPagesStatusGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StaticPagesApi.apiVversionStaticPagesStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StaticPagesApi - factory interface
 * @export
 */
export const StaticPagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StaticPagesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaticPagesStatusGet(options?: any): AxiosPromise<Array<CurrencyStatus>> {
            return localVarFp.apiStaticPagesStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionStaticPagesStatusGet(version: string, options?: any): AxiosPromise<Array<CurrencyStatus>> {
            return localVarFp.apiVversionStaticPagesStatusGet(version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaticPagesApi - object-oriented interface
 * @export
 * @class StaticPagesApi
 * @extends {BaseAPI}
 */
export class StaticPagesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticPagesApi
     */
    public apiStaticPagesStatusGet(options?: RawAxiosRequestConfig) {
        return StaticPagesApiFp(this.configuration).apiStaticPagesStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticPagesApi
     */
    public apiVversionStaticPagesStatusGet(version: string, options?: RawAxiosRequestConfig) {
        return StaticPagesApiFp(this.configuration).apiVversionStaticPagesStatusGet(version, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAffiliateGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/affiliate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bfApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserApikeysDelete: async (bfApiKey?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/apikeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bfApiKey !== undefined) {
                localVarQueryParameter['bfApiKey'] = bfApiKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserApikeysGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/apikeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateApiKeyRequest} [generateApiKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserApikeysPost: async (generateApiKeyRequest?: GenerateApiKeyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/apikeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateApiKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [country] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {Array<File>} [documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserApplykycPost: async (country?: string, firstName?: string, lastName?: string, city?: string, address?: string, documents?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/applykyc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (country !== undefined) { 
                localVarFormParams.append('Country', country as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.append('FirstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('LastName', lastName as any);
            }
    
            if (city !== undefined) { 
                localVarFormParams.append('City', city as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
                if (documents) {
                documents.forEach((element) => {
                  localVarFormParams.append('Documents', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserBalancesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/balances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserBalanceslistGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/balanceslist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDepositsGet: async (currency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/deposits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDisableGoogleAuthenticatorDelete: async (gcode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/DisableGoogleAuthenticator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gcode !== undefined) {
                localVarQueryParameter['gcode'] = gcode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [backupCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDisableGoogleAuthenticatorWithBackupCodeDelete: async (backupCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/DisableGoogleAuthenticatorWithBackupCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (backupCode !== undefined) {
                localVarQueryParameter['backupCode'] = backupCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserFeesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/Fees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinishTier1VerificationModel} [finishTier1VerificationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserFinishTier1VerificationPost: async (finishTier1VerificationModel?: FinishTier1VerificationModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/FinishTier1Verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finishTier1VerificationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGenerateGoogleAuthenticatorSetupCodePost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GenerateGoogleAuthenticatorSetupCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetVerificationLevelGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetVerificationLevel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetverifiedcontactsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/getverifiedcontacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserKycstateGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/kycstate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserOrdersGet: async (pair?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pair !== undefined) {
                localVarQueryParameter['pair'] = pair;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSetPhoneNumberPost: async (phoneNumber?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/SetPhoneNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ParameterType} [type] 
         * @param {string} [settingsToken] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSetparametersPost: async (type?: ParameterType, settingsToken?: string, body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/setparameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (settingsToken != null) {
                localVarHeaderParameter['settingsToken'] = String(settingsToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSupportTicketModel} [createSupportTicketModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSupportPost: async (createSupportTicketModel?: CreateSupportTicketModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/support`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSupportTicketModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pairId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserTradehistoryGet: async (pairId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/tradehistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pairId !== undefined) {
                localVarQueryParameter['pairId'] = pairId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerificationsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/verifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerificationtypeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/verificationtype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerifyGoogleAuthenticatorSetupCodePost: async (gcode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/VerifyGoogleAuthenticatorSetupCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gcode !== undefined) {
                localVarQueryParameter['gcode'] = gcode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostVefirySms} [postVefirySms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerifyPhoneNumberPost: async (postVefirySms?: PostVefirySms, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/VerifyPhoneNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postVefirySms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserWithdrawalsGet: async (currency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/withdrawals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserAffiliateGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserAffiliateGet', 'version', version)
            const localVarPath = `/api/v{version}/User/affiliate`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [bfApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserApikeysDelete: async (version: string, bfApiKey?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserApikeysDelete', 'version', version)
            const localVarPath = `/api/v{version}/User/apikeys`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (bfApiKey !== undefined) {
                localVarQueryParameter['bfApiKey'] = bfApiKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserApikeysGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserApikeysGet', 'version', version)
            const localVarPath = `/api/v{version}/User/apikeys`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {GenerateApiKeyRequest} [generateApiKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserApikeysPost: async (version: string, generateApiKeyRequest?: GenerateApiKeyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserApikeysPost', 'version', version)
            const localVarPath = `/api/v{version}/User/apikeys`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateApiKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [country] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {Array<File>} [documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserApplykycPost: async (version: string, country?: string, firstName?: string, lastName?: string, city?: string, address?: string, documents?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserApplykycPost', 'version', version)
            const localVarPath = `/api/v{version}/User/applykyc`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (country !== undefined) { 
                localVarFormParams.append('Country', country as any);
            }
    
            if (firstName !== undefined) { 
                localVarFormParams.append('FirstName', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('LastName', lastName as any);
            }
    
            if (city !== undefined) { 
                localVarFormParams.append('City', city as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
                if (documents) {
                documents.forEach((element) => {
                  localVarFormParams.append('Documents', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserBalancesGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserBalancesGet', 'version', version)
            const localVarPath = `/api/v{version}/User/balances`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserBalanceslistGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserBalanceslistGet', 'version', version)
            const localVarPath = `/api/v{version}/User/balanceslist`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserDepositsGet: async (version: string, currency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserDepositsGet', 'version', version)
            const localVarPath = `/api/v{version}/User/deposits`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserDisableGoogleAuthenticatorDelete: async (version: string, gcode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserDisableGoogleAuthenticatorDelete', 'version', version)
            const localVarPath = `/api/v{version}/User/DisableGoogleAuthenticator`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gcode !== undefined) {
                localVarQueryParameter['gcode'] = gcode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [backupCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserDisableGoogleAuthenticatorWithBackupCodeDelete: async (version: string, backupCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserDisableGoogleAuthenticatorWithBackupCodeDelete', 'version', version)
            const localVarPath = `/api/v{version}/User/DisableGoogleAuthenticatorWithBackupCode`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (backupCode !== undefined) {
                localVarQueryParameter['backupCode'] = backupCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserFeesGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserFeesGet', 'version', version)
            const localVarPath = `/api/v{version}/User/Fees`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {FinishTier1VerificationModel} [finishTier1VerificationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserFinishTier1VerificationPost: async (version: string, finishTier1VerificationModel?: FinishTier1VerificationModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserFinishTier1VerificationPost', 'version', version)
            const localVarPath = `/api/v{version}/User/FinishTier1Verification`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(finishTier1VerificationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserGenerateGoogleAuthenticatorSetupCodePost: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserGenerateGoogleAuthenticatorSetupCodePost', 'version', version)
            const localVarPath = `/api/v{version}/User/GenerateGoogleAuthenticatorSetupCode`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserGetVerificationLevelGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserGetVerificationLevelGet', 'version', version)
            const localVarPath = `/api/v{version}/User/GetVerificationLevel`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserGetverifiedcontactsGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserGetverifiedcontactsGet', 'version', version)
            const localVarPath = `/api/v{version}/User/getverifiedcontacts`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserKycstateGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserKycstateGet', 'version', version)
            const localVarPath = `/api/v{version}/User/kycstate`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserOrdersGet: async (version: string, pair?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserOrdersGet', 'version', version)
            const localVarPath = `/api/v{version}/User/orders`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pair !== undefined) {
                localVarQueryParameter['pair'] = pair;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserSetPhoneNumberPost: async (version: string, phoneNumber?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserSetPhoneNumberPost', 'version', version)
            const localVarPath = `/api/v{version}/User/SetPhoneNumber`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {ParameterType} [type] 
         * @param {string} [settingsToken] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserSetparametersPost: async (version: string, type?: ParameterType, settingsToken?: string, body?: any, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserSetparametersPost', 'version', version)
            const localVarPath = `/api/v{version}/User/setparameters`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (settingsToken != null) {
                localVarHeaderParameter['settingsToken'] = String(settingsToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateSupportTicketModel} [createSupportTicketModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserSupportPost: async (version: string, createSupportTicketModel?: CreateSupportTicketModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserSupportPost', 'version', version)
            const localVarPath = `/api/v{version}/User/support`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSupportTicketModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {number} [pairId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserTradehistoryGet: async (version: string, pairId?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserTradehistoryGet', 'version', version)
            const localVarPath = `/api/v{version}/User/tradehistory`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pairId !== undefined) {
                localVarQueryParameter['pairId'] = pairId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserVerificationsGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserVerificationsGet', 'version', version)
            const localVarPath = `/api/v{version}/User/verifications`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserVerificationtypeGet: async (version: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserVerificationtypeGet', 'version', version)
            const localVarPath = `/api/v{version}/User/verificationtype`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserVerifyGoogleAuthenticatorSetupCodePost: async (version: string, gcode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserVerifyGoogleAuthenticatorSetupCodePost', 'version', version)
            const localVarPath = `/api/v{version}/User/VerifyGoogleAuthenticatorSetupCode`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (gcode !== undefined) {
                localVarQueryParameter['gcode'] = gcode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {PostVefirySms} [postVefirySms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserVerifyPhoneNumberPost: async (version: string, postVefirySms?: PostVefirySms, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserVerifyPhoneNumberPost', 'version', version)
            const localVarPath = `/api/v{version}/User/VerifyPhoneNumber`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postVefirySms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserWithdrawalsGet: async (version: string, currency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiVversionUserWithdrawalsGet', 'version', version)
            const localVarPath = `/api/v{version}/User/withdrawals`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication X-BF-API-KEY required
            await setApiKeyToObject(localVarHeaderParameter, "X-BF-API-KEY", configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAffiliateGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliateDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAffiliateGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserAffiliateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [bfApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserApikeysDelete(bfApiKey?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserApikeysDelete(bfApiKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserApikeysDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserApikeysGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserApikeysGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserApikeysGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GenerateApiKeyRequest} [generateApiKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserApikeysPost(generateApiKeyRequest?: GenerateApiKeyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserApikeysPost(generateApiKeyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserApikeysPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [country] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {Array<File>} [documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserApplykycPost(country?: string, firstName?: string, lastName?: string, city?: string, address?: string, documents?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserApplykycPost(country, firstName, lastName, city, address, documents, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserApplykycPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserBalancesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserBalancesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserBalancesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserBalanceslistGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBalancesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserBalanceslistGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserBalanceslistGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDepositsGet(currency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGetDepositsWitdrawals>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDepositsGet(currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserDepositsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDisableGoogleAuthenticatorDelete(gcode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDisableGoogleAuthenticatorDelete(gcode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserDisableGoogleAuthenticatorDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [backupCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDisableGoogleAuthenticatorWithBackupCodeDelete(backupCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDisableGoogleAuthenticatorWithBackupCodeDelete(backupCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserDisableGoogleAuthenticatorWithBackupCodeDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserFeesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnFeeStructure>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserFeesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserFeesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FinishTier1VerificationModel} [finishTier1VerificationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserFinishTier1VerificationPost(finishTier1VerificationModel?: FinishTier1VerificationModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserFinishTier1VerificationPost(finishTier1VerificationModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserFinishTier1VerificationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGenerateGoogleAuthenticatorSetupCodePost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetupCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGenerateGoogleAuthenticatorSetupCodePost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserGenerateGoogleAuthenticatorSetupCodePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetVerificationLevelGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVerificationLevelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetVerificationLevelGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserGetVerificationLevelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetverifiedcontactsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifiedContactsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetverifiedcontactsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserGetverifiedcontactsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserKycstateGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KYCRequestState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserKycstateGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserKycstateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserOrdersGet(pair?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserOrdersGet(pair, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserOrdersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSetPhoneNumberPost(phoneNumber?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSetPhoneNumberPost(phoneNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserSetPhoneNumberPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ParameterType} [type] 
         * @param {string} [settingsToken] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSetparametersPost(type?: ParameterType, settingsToken?: string, body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestSettingsTokenResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSetparametersPost(type, settingsToken, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserSetparametersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateSupportTicketModel} [createSupportTicketModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSupportPost(createSupportTicketModel?: CreateSupportTicketModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSupportPost(createSupportTicketModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserSupportPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [pairId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserTradehistoryGet(pairId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserTradehistoryGet(pairId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserTradehistoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserVerificationsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserVerificationsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserVerificationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserVerificationtypeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoStepVerificationTypes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserVerificationtypeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserVerificationtypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserVerifyGoogleAuthenticatorSetupCodePost(gcode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserVerifyGoogleAuthenticatorSetupCodePost(gcode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserVerifyGoogleAuthenticatorSetupCodePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostVefirySms} [postVefirySms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserVerifyPhoneNumberPost(postVefirySms?: PostVefirySms, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsVefiryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserVerifyPhoneNumberPost(postVefirySms, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserVerifyPhoneNumberPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserWithdrawalsGet(currency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGetDepositsWitdrawals>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserWithdrawalsGet(currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiUserWithdrawalsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserAffiliateGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliateDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserAffiliateGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserAffiliateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [bfApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserApikeysDelete(version: string, bfApiKey?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserApikeysDelete(version, bfApiKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserApikeysDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserApikeysGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiKey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserApikeysGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserApikeysGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {GenerateApiKeyRequest} [generateApiKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserApikeysPost(version: string, generateApiKeyRequest?: GenerateApiKeyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserApikeysPost(version, generateApiKeyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserApikeysPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [country] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {Array<File>} [documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserApplykycPost(version: string, country?: string, firstName?: string, lastName?: string, city?: string, address?: string, documents?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserApplykycPost(version, country, firstName, lastName, city, address, documents, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserApplykycPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserBalancesGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserBalancesGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserBalancesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserBalanceslistGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBalancesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserBalanceslistGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserBalanceslistGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserDepositsGet(version: string, currency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGetDepositsWitdrawals>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserDepositsGet(version, currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserDepositsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserDisableGoogleAuthenticatorDelete(version: string, gcode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserDisableGoogleAuthenticatorDelete(version, gcode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserDisableGoogleAuthenticatorDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [backupCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserDisableGoogleAuthenticatorWithBackupCodeDelete(version: string, backupCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserDisableGoogleAuthenticatorWithBackupCodeDelete(version, backupCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserDisableGoogleAuthenticatorWithBackupCodeDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserFeesGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnFeeStructure>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserFeesGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserFeesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {FinishTier1VerificationModel} [finishTier1VerificationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserFinishTier1VerificationPost(version: string, finishTier1VerificationModel?: FinishTier1VerificationModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserFinishTier1VerificationPost(version, finishTier1VerificationModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserFinishTier1VerificationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserGenerateGoogleAuthenticatorSetupCodePost(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetupCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserGenerateGoogleAuthenticatorSetupCodePost(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserGenerateGoogleAuthenticatorSetupCodePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserGetVerificationLevelGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVerificationLevelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserGetVerificationLevelGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserGetVerificationLevelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserGetverifiedcontactsGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifiedContactsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserGetverifiedcontactsGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserGetverifiedcontactsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserKycstateGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KYCRequestState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserKycstateGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserKycstateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserOrdersGet(version: string, pair?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserOrdersGet(version, pair, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserOrdersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserSetPhoneNumberPost(version: string, phoneNumber?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerificationResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserSetPhoneNumberPost(version, phoneNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserSetPhoneNumberPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {ParameterType} [type] 
         * @param {string} [settingsToken] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserSetparametersPost(version: string, type?: ParameterType, settingsToken?: string, body?: any, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestSettingsTokenResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserSetparametersPost(version, type, settingsToken, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserSetparametersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateSupportTicketModel} [createSupportTicketModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserSupportPost(version: string, createSupportTicketModel?: CreateSupportTicketModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserSupportPost(version, createSupportTicketModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserSupportPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {number} [pairId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserTradehistoryGet(version: string, pairId?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserTradehistoryGet(version, pairId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserTradehistoryGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserVerificationsGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserVerificationsGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserVerificationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserVerificationtypeGet(version: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoStepVerificationTypes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserVerificationtypeGet(version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserVerificationtypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserVerifyGoogleAuthenticatorSetupCodePost(version: string, gcode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BitflexApiResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserVerifyGoogleAuthenticatorSetupCodePost(version, gcode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserVerifyGoogleAuthenticatorSetupCodePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {PostVefirySms} [postVefirySms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserVerifyPhoneNumberPost(version: string, postVefirySms?: PostVefirySms, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsVefiryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserVerifyPhoneNumberPost(version, postVefirySms, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserVerifyPhoneNumberPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVversionUserWithdrawalsGet(version: string, currency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiGetDepositsWitdrawals>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVversionUserWithdrawalsGet(version, currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.apiVversionUserWithdrawalsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAffiliateGet(options?: any): AxiosPromise<AffiliateDataResponse> {
            return localVarFp.apiUserAffiliateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bfApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserApikeysDelete(bfApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserApikeysDelete(bfApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserApikeysGet(options?: any): AxiosPromise<Array<ApiKey>> {
            return localVarFp.apiUserApikeysGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateApiKeyRequest} [generateApiKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserApikeysPost(generateApiKeyRequest?: GenerateApiKeyRequest, options?: any): AxiosPromise<ApiKey> {
            return localVarFp.apiUserApikeysPost(generateApiKeyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [country] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {Array<File>} [documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserApplykycPost(country?: string, firstName?: string, lastName?: string, city?: string, address?: string, documents?: Array<File>, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserApplykycPost(country, firstName, lastName, city, address, documents, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserBalancesGet(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.apiUserBalancesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserBalanceslistGet(options?: any): AxiosPromise<GetBalancesListResponse> {
            return localVarFp.apiUserBalanceslistGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDepositsGet(currency?: string, options?: any): AxiosPromise<Array<ApiGetDepositsWitdrawals>> {
            return localVarFp.apiUserDepositsGet(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDisableGoogleAuthenticatorDelete(gcode?: string, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiUserDisableGoogleAuthenticatorDelete(gcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [backupCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDisableGoogleAuthenticatorWithBackupCodeDelete(backupCode?: string, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiUserDisableGoogleAuthenticatorWithBackupCodeDelete(backupCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserFeesGet(options?: any): AxiosPromise<ReturnFeeStructure> {
            return localVarFp.apiUserFeesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinishTier1VerificationModel} [finishTier1VerificationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserFinishTier1VerificationPost(finishTier1VerificationModel?: FinishTier1VerificationModel, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiUserFinishTier1VerificationPost(finishTier1VerificationModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGenerateGoogleAuthenticatorSetupCodePost(options?: any): AxiosPromise<SetupCode> {
            return localVarFp.apiUserGenerateGoogleAuthenticatorSetupCodePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetVerificationLevelGet(options?: any): AxiosPromise<GetVerificationLevelResponse> {
            return localVarFp.apiUserGetVerificationLevelGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetverifiedcontactsGet(options?: any): AxiosPromise<VerifiedContactsResponse> {
            return localVarFp.apiUserGetverifiedcontactsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserKycstateGet(options?: any): AxiosPromise<KYCRequestState> {
            return localVarFp.apiUserKycstateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserOrdersGet(pair?: string, options?: any): AxiosPromise<GetOrdersResponse> {
            return localVarFp.apiUserOrdersGet(pair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSetPhoneNumberPost(phoneNumber?: string, options?: any): AxiosPromise<VerificationResource> {
            return localVarFp.apiUserSetPhoneNumberPost(phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ParameterType} [type] 
         * @param {string} [settingsToken] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSetparametersPost(type?: ParameterType, settingsToken?: string, body?: any, options?: any): AxiosPromise<RequestSettingsTokenResponseModel> {
            return localVarFp.apiUserSetparametersPost(type, settingsToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSupportTicketModel} [createSupportTicketModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSupportPost(createSupportTicketModel?: CreateSupportTicketModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserSupportPost(createSupportTicketModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pairId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserTradehistoryGet(pairId?: number, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.apiUserTradehistoryGet(pairId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerificationsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserVerificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerificationtypeGet(options?: any): AxiosPromise<TwoStepVerificationTypes> {
            return localVarFp.apiUserVerificationtypeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerifyGoogleAuthenticatorSetupCodePost(gcode?: string, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiUserVerifyGoogleAuthenticatorSetupCodePost(gcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostVefirySms} [postVefirySms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserVerifyPhoneNumberPost(postVefirySms?: PostVefirySms, options?: any): AxiosPromise<SmsVefiryResult> {
            return localVarFp.apiUserVerifyPhoneNumberPost(postVefirySms, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserWithdrawalsGet(currency?: string, options?: any): AxiosPromise<Array<ApiGetDepositsWitdrawals>> {
            return localVarFp.apiUserWithdrawalsGet(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserAffiliateGet(version: string, options?: any): AxiosPromise<AffiliateDataResponse> {
            return localVarFp.apiVversionUserAffiliateGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [bfApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserApikeysDelete(version: string, bfApiKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionUserApikeysDelete(version, bfApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserApikeysGet(version: string, options?: any): AxiosPromise<Array<ApiKey>> {
            return localVarFp.apiVversionUserApikeysGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {GenerateApiKeyRequest} [generateApiKeyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserApikeysPost(version: string, generateApiKeyRequest?: GenerateApiKeyRequest, options?: any): AxiosPromise<ApiKey> {
            return localVarFp.apiVversionUserApikeysPost(version, generateApiKeyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [country] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [city] 
         * @param {string} [address] 
         * @param {Array<File>} [documents] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserApplykycPost(version: string, country?: string, firstName?: string, lastName?: string, city?: string, address?: string, documents?: Array<File>, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiVversionUserApplykycPost(version, country, firstName, lastName, city, address, documents, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserBalancesGet(version: string, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.apiVversionUserBalancesGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserBalanceslistGet(version: string, options?: any): AxiosPromise<GetBalancesListResponse> {
            return localVarFp.apiVversionUserBalanceslistGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserDepositsGet(version: string, currency?: string, options?: any): AxiosPromise<Array<ApiGetDepositsWitdrawals>> {
            return localVarFp.apiVversionUserDepositsGet(version, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserDisableGoogleAuthenticatorDelete(version: string, gcode?: string, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionUserDisableGoogleAuthenticatorDelete(version, gcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [backupCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserDisableGoogleAuthenticatorWithBackupCodeDelete(version: string, backupCode?: string, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionUserDisableGoogleAuthenticatorWithBackupCodeDelete(version, backupCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserFeesGet(version: string, options?: any): AxiosPromise<ReturnFeeStructure> {
            return localVarFp.apiVversionUserFeesGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {FinishTier1VerificationModel} [finishTier1VerificationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserFinishTier1VerificationPost(version: string, finishTier1VerificationModel?: FinishTier1VerificationModel, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionUserFinishTier1VerificationPost(version, finishTier1VerificationModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserGenerateGoogleAuthenticatorSetupCodePost(version: string, options?: any): AxiosPromise<SetupCode> {
            return localVarFp.apiVversionUserGenerateGoogleAuthenticatorSetupCodePost(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserGetVerificationLevelGet(version: string, options?: any): AxiosPromise<GetVerificationLevelResponse> {
            return localVarFp.apiVversionUserGetVerificationLevelGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserGetverifiedcontactsGet(version: string, options?: any): AxiosPromise<VerifiedContactsResponse> {
            return localVarFp.apiVversionUserGetverifiedcontactsGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserKycstateGet(version: string, options?: any): AxiosPromise<KYCRequestState> {
            return localVarFp.apiVversionUserKycstateGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [pair] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserOrdersGet(version: string, pair?: string, options?: any): AxiosPromise<GetOrdersResponse> {
            return localVarFp.apiVversionUserOrdersGet(version, pair, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserSetPhoneNumberPost(version: string, phoneNumber?: string, options?: any): AxiosPromise<VerificationResource> {
            return localVarFp.apiVversionUserSetPhoneNumberPost(version, phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {ParameterType} [type] 
         * @param {string} [settingsToken] 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserSetparametersPost(version: string, type?: ParameterType, settingsToken?: string, body?: any, options?: any): AxiosPromise<RequestSettingsTokenResponseModel> {
            return localVarFp.apiVversionUserSetparametersPost(version, type, settingsToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {CreateSupportTicketModel} [createSupportTicketModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserSupportPost(version: string, createSupportTicketModel?: CreateSupportTicketModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionUserSupportPost(version, createSupportTicketModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {number} [pairId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserTradehistoryGet(version: string, pairId?: number, options?: any): AxiosPromise<Array<Order>> {
            return localVarFp.apiVversionUserTradehistoryGet(version, pairId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserVerificationsGet(version: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVversionUserVerificationsGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserVerificationtypeGet(version: string, options?: any): AxiosPromise<TwoStepVerificationTypes> {
            return localVarFp.apiVversionUserVerificationtypeGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [gcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserVerifyGoogleAuthenticatorSetupCodePost(version: string, gcode?: string, options?: any): AxiosPromise<BitflexApiResponseBase> {
            return localVarFp.apiVversionUserVerifyGoogleAuthenticatorSetupCodePost(version, gcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {PostVefirySms} [postVefirySms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserVerifyPhoneNumberPost(version: string, postVefirySms?: PostVefirySms, options?: any): AxiosPromise<SmsVefiryResult> {
            return localVarFp.apiVversionUserVerifyPhoneNumberPost(version, postVefirySms, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} version 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionUserWithdrawalsGet(version: string, currency?: string, options?: any): AxiosPromise<Array<ApiGetDepositsWitdrawals>> {
            return localVarFp.apiVversionUserWithdrawalsGet(version, currency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAffiliateGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserAffiliateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bfApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserApikeysDelete(bfApiKey?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserApikeysDelete(bfApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserApikeysGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserApikeysGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenerateApiKeyRequest} [generateApiKeyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserApikeysPost(generateApiKeyRequest?: GenerateApiKeyRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserApikeysPost(generateApiKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [country] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [city] 
     * @param {string} [address] 
     * @param {Array<File>} [documents] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserApplykycPost(country?: string, firstName?: string, lastName?: string, city?: string, address?: string, documents?: Array<File>, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserApplykycPost(country, firstName, lastName, city, address, documents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserBalancesGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserBalancesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserBalanceslistGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserBalanceslistGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserDepositsGet(currency?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserDepositsGet(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [gcode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserDisableGoogleAuthenticatorDelete(gcode?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserDisableGoogleAuthenticatorDelete(gcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [backupCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserDisableGoogleAuthenticatorWithBackupCodeDelete(backupCode?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserDisableGoogleAuthenticatorWithBackupCodeDelete(backupCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserFeesGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserFeesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinishTier1VerificationModel} [finishTier1VerificationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserFinishTier1VerificationPost(finishTier1VerificationModel?: FinishTier1VerificationModel, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserFinishTier1VerificationPost(finishTier1VerificationModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGenerateGoogleAuthenticatorSetupCodePost(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGenerateGoogleAuthenticatorSetupCodePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetVerificationLevelGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetVerificationLevelGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetverifiedcontactsGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetverifiedcontactsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserKycstateGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserKycstateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [pair] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserOrdersGet(pair?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserOrdersGet(pair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [phoneNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSetPhoneNumberPost(phoneNumber?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSetPhoneNumberPost(phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ParameterType} [type] 
     * @param {string} [settingsToken] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSetparametersPost(type?: ParameterType, settingsToken?: string, body?: any, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSetparametersPost(type, settingsToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSupportTicketModel} [createSupportTicketModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSupportPost(createSupportTicketModel?: CreateSupportTicketModel, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSupportPost(createSupportTicketModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pairId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserTradehistoryGet(pairId?: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserTradehistoryGet(pairId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserVerificationsGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserVerificationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserVerificationtypeGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserVerificationtypeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [gcode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserVerifyGoogleAuthenticatorSetupCodePost(gcode?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserVerifyGoogleAuthenticatorSetupCodePost(gcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostVefirySms} [postVefirySms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserVerifyPhoneNumberPost(postVefirySms?: PostVefirySms, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserVerifyPhoneNumberPost(postVefirySms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserWithdrawalsGet(currency?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserWithdrawalsGet(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserAffiliateGet(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserAffiliateGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [bfApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserApikeysDelete(version: string, bfApiKey?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserApikeysDelete(version, bfApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserApikeysGet(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserApikeysGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {GenerateApiKeyRequest} [generateApiKeyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserApikeysPost(version: string, generateApiKeyRequest?: GenerateApiKeyRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserApikeysPost(version, generateApiKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [country] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [city] 
     * @param {string} [address] 
     * @param {Array<File>} [documents] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserApplykycPost(version: string, country?: string, firstName?: string, lastName?: string, city?: string, address?: string, documents?: Array<File>, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserApplykycPost(version, country, firstName, lastName, city, address, documents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserBalancesGet(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserBalancesGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserBalanceslistGet(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserBalanceslistGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserDepositsGet(version: string, currency?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserDepositsGet(version, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [gcode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserDisableGoogleAuthenticatorDelete(version: string, gcode?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserDisableGoogleAuthenticatorDelete(version, gcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [backupCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserDisableGoogleAuthenticatorWithBackupCodeDelete(version: string, backupCode?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserDisableGoogleAuthenticatorWithBackupCodeDelete(version, backupCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserFeesGet(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserFeesGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {FinishTier1VerificationModel} [finishTier1VerificationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserFinishTier1VerificationPost(version: string, finishTier1VerificationModel?: FinishTier1VerificationModel, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserFinishTier1VerificationPost(version, finishTier1VerificationModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserGenerateGoogleAuthenticatorSetupCodePost(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserGenerateGoogleAuthenticatorSetupCodePost(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserGetVerificationLevelGet(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserGetVerificationLevelGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserGetverifiedcontactsGet(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserGetverifiedcontactsGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserKycstateGet(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserKycstateGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [pair] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserOrdersGet(version: string, pair?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserOrdersGet(version, pair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [phoneNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserSetPhoneNumberPost(version: string, phoneNumber?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserSetPhoneNumberPost(version, phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {ParameterType} [type] 
     * @param {string} [settingsToken] 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserSetparametersPost(version: string, type?: ParameterType, settingsToken?: string, body?: any, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserSetparametersPost(version, type, settingsToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {CreateSupportTicketModel} [createSupportTicketModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserSupportPost(version: string, createSupportTicketModel?: CreateSupportTicketModel, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserSupportPost(version, createSupportTicketModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {number} [pairId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserTradehistoryGet(version: string, pairId?: number, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserTradehistoryGet(version, pairId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserVerificationsGet(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserVerificationsGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserVerificationtypeGet(version: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserVerificationtypeGet(version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [gcode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserVerifyGoogleAuthenticatorSetupCodePost(version: string, gcode?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserVerifyGoogleAuthenticatorSetupCodePost(version, gcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {PostVefirySms} [postVefirySms] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserVerifyPhoneNumberPost(version: string, postVefirySms?: PostVefirySms, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserVerifyPhoneNumberPost(version, postVefirySms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} version 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiVversionUserWithdrawalsGet(version: string, currency?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).apiVversionUserWithdrawalsGet(version, currency, options).then((request) => request(this.axios, this.basePath));
    }
}



